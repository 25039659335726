import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Row,
  Col,
  ListGroup,
  Image,
  Form,
  Button,
  Card,
} from 'react-bootstrap';
import { savePaymentMethod } from '../actions/cartActions';
// import FormContainer from '../components/FormContainer';
import ReactGA from 'react-ga';

import { formatPrice } from '../shared/functions';
import Message from '../components/Message';
import {
  addToCart,
  removeFromCart,
  saveShippingAddress,
} from '../actions/cartActions';

const BuyPage = ({ match, history, location }) => {
  ReactGA.initialize(process.env.REACT_APP_GUA);
  const productId = match.params.id;

  const cart = useSelector((state) => state.cart);
  const { shippingAddress } = cart;

  const [customerName, setCustomerName] = useState(
    shippingAddress.customerName
  );
  const [customerPhone, setCustomerPhone] = useState(
    shippingAddress.customerPhone
  );
  const [address, setAddress] = useState(shippingAddress.address);
  const [city, setCity] = useState(shippingAddress.city);
  const [postalCode, setPostalCode] = useState(shippingAddress.postalCode);
  const [country, setCountry] = useState('Indonesia'); //shippingAddress.country
  const [errorOccurred, setErrorOccurred] = useState(false); // form error

  const [paymentMethod, setPaymentMethod] = useState('Xendit');

  // const userLogin = useSelector((state) => state.userLogin);
  // const { userInfo } = userLogin;
  // console.log('userInfo:', userLogin);
  // // const history = useHistory();

  // if (!userInfo) {
  //   history.push('/login?redirect=/placeorder');
  // }
  /*else if (!shippingAddress) {
    history.push('/buy?redirect=/payment');
  }*/

  // const history = useHistory();

  const dispatch = useDispatch();

  const submitHandler = (e) => {
    e.preventDefault();
    if (!customerName || !customerPhone) {
      //
      setErrorOccurred(true);
      return;
    }
    // DISPATCH SHIPPINGADDRESS
    dispatch(
      saveShippingAddress({
        customerName,
        customerPhone,
        address,
        city,
        postalCode,
        country,
      })
    );

    dispatch(savePaymentMethod(paymentMethod));
    // history.push('/payment');
    history.push('/placeorder');
  };

  //   const history = useHistory();

  // const qty = location.search ? Number(location.search.split('=')[1]) : 1;
  // Get params from query atring
  const params = location.search
    .slice(1)
    .split('&')
    .reduce((acc, s) => {
      const [k, v] = s.split('=');
      return Object.assign(acc, { [k]: v });
    }, {});
  const qty = params.q;
  const toBeServedDate = params.d;
  const toBeServedTime = params.t;
  const notes = params.n;
  const seller = params.s;
  // console.log('qty: ', qty, toBeServedDate, toBeServedTime, notes, seller);

  //   const dispatch = useDispatch();
  //   const cart = useSelector((state) => state.cart);

  const { cartItems } = cart;
  // console.log('cartItems:', cartItems);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (!customerName || !customerPhone || !address) {
      setErrorOccurred(true);
    } else {
      setErrorOccurred(false);
    }
  }, [customerName, customerPhone, address]);

  useEffect(() => {
    if (productId) {
      dispatch(
        addToCart(productId, seller, qty, toBeServedDate, toBeServedTime, notes)
      );
    }
  }, [dispatch, seller, productId, qty, toBeServedDate, toBeServedTime, notes]);

  const removeFromCartHandler = (id) => {
    dispatch(removeFromCart(id));
  };

  const checkoutHandler = () => {
    // history.push('/payment');
  };

  return (
    <Row>
      <Col md={8}>
        <h1 className='product__title-page'>Konfirmasi Pesanan Anda</h1>
        {/* {JSON.stringify(cartItems)} */}
        {cartItems.length === 0 ? (
          <Message>
            Still kind of empty here. Shop and add a service perhaps? <br />
            <Link to='/'>Go Back</Link>
          </Message>
        ) : (
          <>
            <ListGroup variant='flush'>
              {cartItems.map((item) => (
                <ListGroup.Item key={item.product}>
                  <Row>
                    <Col md={2}>
                      <Link to={`/products/${item.product}`}>
                        <Image src={item.image} alt={item.name} fluid rounded />
                      </Link>
                    </Col>
                    <Col md={3}>
                      <h2 className='product__title-cart'>
                        <Link
                          className='no-underline'
                          to={`/products/${item.product}`}
                        >
                          {item.name}
                        </Link>
                      </h2>
                      Item Notes:
                      <br />
                      {item.notes}
                      {/* <br />
                      Seller: {item.seller} */}
                    </Col>
                    <Col md={2}>
                      Rp{formatPrice(item.price)}/{item.priceUnit}
                    </Col>
                    <Col md={2} className='boxshadow'>
                      <label>
                        <Form.Control
                          as='select'
                          value={item.qty}
                          onChange={(e) => {
                            dispatch(
                              addToCart(
                                item.product,
                                item.seller,
                                Number(e.target.value),
                                item.toBeServedDate,
                                item.toBeServedTime,
                                item.notes
                              )
                            );
                          }}
                        >
                          {[
                            ...Array(
                              item.maxOrderQty ? item.maxOrderQty : 24
                            ).keys(),
                          ].map((x) => (
                            <option key={x + 1} value={x + 1}>
                              {x + 1}
                              {x === 0 ? ' x' : ' x'}
                            </option>
                          ))}
                        </Form.Control>
                      </label>{' '}
                      {/* --
                      <label>
                        <Form.Control
                          as='select'
                          value={item.qty}
                          onChange={(e) => {
                            dispatch(
                              addToCart(
                                item.product,
                                item.seller,
                                Number(e.target.value),
                                item.toBeServedDate,
                                item.toBeServedTime,
                                item.notes
                              )
                            );
                          }}
                        >
                          {[...Array(24).keys()].map((x) => (
                            <option key={x + 1} val={x + 1}>
                              {x + 1}
                            </option>
                          ))}
                        </Form.Control>
                          </label>*/}
                      {parseInt(item.qty) > 1
                        ? item.priceUnit + 's'
                        : item.priceUnit}
                    </Col>
                    <Col md={2}>
                      Served On:
                      <br />
                      {item.toBeServedDate}
                      <br />@ {item.toBeServedTime}
                    </Col>
                    <Col md={1}>
                      <Button
                        style={{ padding: '0.5rem 0' }}
                        type='button'
                        variant='light'
                        onClick={() => {
                          removeFromCartHandler(item.product);
                        }}
                      >
                        <i className='fas fa-times'></i>
                      </Button>
                    </Col>
                  </Row>
                </ListGroup.Item>
              ))}
            </ListGroup>
          </>
        )}
      </Col>
      <Col md={4}>
        <Card>
          <ListGroup variant='flush'>
            <Form onSubmit={submitHandler}>
              <ListGroup.Item>
                <h3>
                  Sub Total
                  {/* {cartItems.reduce((acc, item) => acc + item.qty, 0)}{' '} */}
                  {/* {cartItems[0].priceUnit} */}
                </h3>
                <Col className='price'>
                  <span className='price-cur'>Rp</span>{' '}
                  <span className=''>
                    {formatPrice(
                      cartItems
                        .reduce((acc, item) => acc + item.qty * item.price, 0)
                        .toFixed(0)
                    )}
                  </span>
                </Col>
              </ListGroup.Item>

              <ListGroup.Item>
                {errorOccurred && (
                  <Message variant='warning'>
                    Mohon lengkapi data-data pesanan Anda..
                  </Message>
                )}
                <Form.Group controlId='customerName'>
                  <Form.Label>Nama Pelanggan*</Form.Label>
                  <Form.Control
                    size='lg'
                    type='text'
                    placeholder='Masukkan Nama Pelanggan'
                    value={customerName}
                    required
                    onChange={(e) => setCustomerName(e.target.value)}
                  ></Form.Control>
                </Form.Group>
              </ListGroup.Item>
              <ListGroup.Item>
                <Form.Group controlId='customerPhone'>
                  <Form.Label>Nomer Telepon Pelanggan*</Form.Label>
                  <Form.Control
                    size='lg'
                    type='number'
                    placeholder='Masukkan Nomer Telepon'
                    value={customerPhone}
                    required
                    onChange={(e) => setCustomerPhone(e.target.value)}
                  ></Form.Control>
                </Form.Group>
              </ListGroup.Item>
              <ListGroup.Item>
                <Form.Group controlId='address'>
                  <Form.Label>Alamat Tujuan Layanan</Form.Label>
                  <Form.Control
                    as='textarea'
                    placeholder='Masukkan Alamat'
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                  ></Form.Control>
                </Form.Group>
                <Form.Group controlId='City'>
                  <Form.Label>Kota</Form.Label>
                  <Form.Control
                    type='text'
                    placeholder='Enter City'
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                  ></Form.Control>
                </Form.Group>
                <Form.Group controlId='postalCode'>
                  <Form.Label>Kode Pos</Form.Label>
                  <Form.Control
                    type='text'
                    placeholder='Enter Postal Code'
                    value={postalCode}
                    onChange={(e) => setPostalCode(e.target.value)}
                  ></Form.Control>
                </Form.Group>
                {/* <Form.Group controlId='country'>
                  <Form.Label>Negara</Form.Label>
                  <Form.Control
                    type='text'
                    placeholder='Enter Country'
                    value={country}
                    onChange={(e) => setCountry(e.target.value)}
                  ></Form.Control>
                </Form.Group> */}
                {/* <br />
                <Button type='submit' variant='primary' className='btn py-2'>
                  Continue <i className='fas fa-angle-right px-2'></i>
                </Button> */}
              </ListGroup.Item>
              <ListGroup.Item>
                <Button
                  type='button'
                  className='btn-block'
                  disabled={cartItems.length === 0}
                  onClick={submitHandler}
                >
                  Konfirmasi Pesanan
                </Button>
                {/* <i className='fas fa-angle-right'></i> */}
              </ListGroup.Item>
            </Form>
          </ListGroup>
        </Card>
      </Col>
    </Row>
  );
};

export default BuyPage;
