import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import ReactGA from 'react-ga';

import { removeAllFromCart } from '../actions/cartActions';

function PaymentSuccessPage({ history, match, location, ...props }) {
  ReactGA.initialize(process.env.REACT_APP_GUA);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(removeAllFromCart());
  }, [dispatch]);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <div>
      <h2>Selamat!</h2>
      <p>
        Pembayaran order no <strong>{match.params.id.toUpperCase()}</strong>{' '}
        sukses.
      </p>
      <p>
        Lihat <Link to={`/order/${match.params.id}`}>pesanan Anda</Link>.
      </p>
    </div>
  );
}

export default PaymentSuccessPage;
