export const formatPrice = (int) => {
  int = parseInt(int);
  let dev;
  let denom;
  if (int >= 1000000) {
    dev = 1000000;
    denom = 'jt';
  } else if (int == 0) {
    dev = 1;
    denom = '';
  } else if (int < 1000000) {
    dev = 1000;
    denom = 'k';
  }
  let number = int / dev;
  let price = new Intl.NumberFormat('en-US');
  const fNum = price.format(number) + denom; // "1,234,567,890"
  return fNum;
};

export const formatSeparator = (int) => {
  let number = parseInt(int);
  // let price = new Intl.NumberFormat('en-US'); // "1,234,567,890"
  let price = new Intl.NumberFormat('id-ID');
  const fNum = price.format(number); // "1.234.567.890"
  return fNum;
};

// Calculate total prices
export const addDecimals = (num) => {
  return (Math.round(num * 100) / 100).toFixed(0);
};

// Capitalize 1st char of words
export const capitalizeFirstChar = (sentence) => {
  return sentence
    .split(' ')
    .map((word) => word[0].toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
};
export const capitalizeFirstChar2 = (sentence) => {
  const words = sentence.split(' ');
  console.log('words:', words);
  let word = [];
  let sentence2 = '';
  for (let i = 0; i < words.length; i++) {
    word[i] = words[i]
      .toUpperCase()
      .replace(words[i].substring(1), words[i].substring(1).toLowerCase());
    sentence2 += i < words.length ? `${word[i]} ` : word[i];
  }
  console.log('sentence2:', sentence2);
  return sentence2;
};

export const getVideoId = (url) => {
  let videoId;
  if (url.includes('youtube.com')) {
    if (url.includes('watch')) {
      videoId = new URL(url).searchParams.get('v');
    } else if (url.includes('embed')) {
      videoId = url.split('/').pop();
    }
  } else if (url.includes('youtu.be')) {
    videoId = url.split('/').pop();
  }
  return videoId;
};

const oneWord = function (str) {
  return str.replace(/ /g, '').toLowerCase();
};
const upperFirstWord = function (str) {
  const [first, ...others] = str.split(' ');
  return [first.toUpperCase(), ...others].join(' ');
};

// Higher-order function
const transformer = function (str, fn) {
  console.log(`Originail string: ${str}`);
  console.log(`Transformed string: ${fn(str)}`);

  console.log(`Transformed by: ${fn.name}`);
};

transformer('Javascript is the best!', upperFirstWord);
transformer('Javascript is the best!', oneWord);
