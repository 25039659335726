import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Form, Button, Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import ReactGA from 'react-ga';

// import MapPicker from 'react-google-map-picker';
// import MapsGoogle from '../components/MapsGoogle';

import Message from '../components/Message';
import Loading from '../components/Loading';
import FormContainer from '../components/FormContainer';
import {
  listProductEditDetails,
  updateProduct,
} from '../actions/productActions';
// import Geolocation from '../components/Geolocation';
// import getAddressForCoords from '../shared/getAddressForCoords';
import { PRODUCT_UPDATE_RESET } from '../constants/productConstants';
import GetKecamatan from '../components/GetKecamatan';
import { getVideoId } from '../shared/functions';

const ProductEditPage = ({ match, history }) => {
  const productId = match.params.id;
  ReactGA.initialize(process.env.REACT_APP_GUA);

  // Map Picker
  // const [gKey, setGKey] = useState('');
  // const getGMapKey = useCallback(async () => {
  //   const { data } = await axios.get(
  //     `${process.env.REACT_APP_BACKEND_URL}/api/config/gmaps`
  //   );
  //   setGKey(data);
  // }, []);
  // useEffect(() => {
  //   // getGMapKey();
  // }, [getGMapKey]);

  const [name, setName] = useState('');
  const [isActive, setIsActive] = useState(true);
  const [description, setDescription] = useState('');
  const [image, setImage] = useState('');

  const [videos, setVideos] = useState([]);
  const [videoLinkFields, setVideoLinkFields] = useState([{ value: '' }]);

  const [specialUrlLinks, setSpecialUrlLinks] = useState([{ value: '' }]);
  const [specialUrlLinkFields, setSpecialUrlLinkFields] = useState([
    { value: '' },
  ]);

  const [images, setImages] = useState([]);
  const [price, setPrice] = useState(0);
  const [priceUnit, setPriceUnit] = useState('');
  const [brand, setBrand] = useState('');
  const [user, setUser] = useState('');
  const [category, setCategory] = useState('');

  const [subDistrict, setSubDistrict] = useState('');
  const [district, setDistrict] = useState('');
  const [province, setProvince] = useState('');
  const [village, setVillage] = useState('');
  const [country, setCountry] = useState('Indonesia');

  const [countInStock, setCountInStock] = useState(0);
  const [maxOrderQty, setMaxOrderQty] = useState();

  const [fileUploadingSingle, setFileUploadingSingle] = useState(false);
  const [fileUploading, setFileUploading] = useState(false);
  const [errorUploadSingle, setErrorUploadSingle] = useState('');
  const [errorUpload, setErrorUpload] = useState('');

  // const defLocation = { lat: -6.900298161943437, lng: 107.61856178926345 }; // Gasibu
  // const defLocation = { lat: latitude, lng: longitude };
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');
  const [geoLocation, setGeoLocation] = useState({ lat: '', lng: '' });
  const [zoom, setZoom] = useState(16);
  // const defZoom = 16;

  // const [defaultLocation, setDefaultLocation] = useState(defLocation);

  // const [zoom, setZoom] = useState(defZoom);

  // function handleChangeLocation(lat, lng) {
  //   setLocation({ lat: lat, lng: lng });
  //   setDefaultLocation({ lat: lat, lng: lng });
  // }
  // function handleChangeZoom(newZoom) {
  //   setZoom(newZoom);
  // }
  // function handleResetLocation() {
  //   setDefaultLocation({ ...defaultLocation });
  //   setZoom(defZoom);
  // }

  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const productDetails = useSelector((state) => state.productDetails);
  const { loading, error, product } = productDetails;

  const productUpdate = useSelector((state) => state.productUpdate);
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = productUpdate;

  // const history = useHistory();
  //   const redirect = location.search ? location.search.split('=')[1] : '/';

  useEffect(() => {
    if (
      userInfo &&
      user &&
      !userInfo.isAdmin &&
      user !== userInfo._id.toString()
    ) {
      // console.log('1) userInfo._id.toString():', user, userInfo._id.toString());
      history.push('/');
    } else if (!userInfo && !user) {
      history.push('/');
    }
    console.log('userInfo:', userInfo);
    console.log('user:', user);
  }, [user, userInfo, history]);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    dispatch({ type: PRODUCT_UPDATE_RESET });
  }, []);

  useEffect(() => {
    /*if (successUpdate) {
      // dispatch({ type: PRODUCT_UPDATE_RESET });
      // history.push('/admin/products-list');
    } else {*/
    if (!product.name || product._id !== productId) {
      dispatch(listProductEditDetails(productId, userInfo));
    } else {
      setName(product.name);
      setDescription(product.description);
      setImage(product.image);
      product.productVideos ? setVideos(product.productVideos) : setVideos([]);
      product.productVideos
        ? setVideoLinkFields(formOldArray(product.productVideos))
        : setVideoLinkFields([{ value: '' }]);
      product.productImages ? setImages(product.productImages) : setImages([]);
      setBrand(product.brand);
      setCategory(product.category);
      setPrice(product.price);
      setPriceUnit(product.priceUnit);
      setUser(product.user);
      product.village ? setVillage(product.village) : setVillage('');
      product.subDistrict
        ? setSubDistrict(product.subDistrict)
        : setSubDistrict('');
      product.district ? setDistrict(product.district) : setDistrict('');
      product.province ? setProvince(product.province) : setProvince('');
      if (product.latitude && product.longitude) {
        setLatitude(product.latitude);
        setLongitude(product.longitude);
        // setGeoLocation({
        //   lat: parseFloat(product.latitude),
        //   lng: parseFloat(product.longitude),
        // });
        // setZoom(16);
      }

      console.log(
        'product.latitude:',
        product.latitude,
        'latitude:',
        latitude
        // 'geoLocation:',
        // geoLocation
      );
      console.log(
        'product.longitude:',
        product.longitude,
        'longitude:',
        longitude
      );

      setIsActive(product.isActive);

      product.productSpecialUrlFiles
        ? setSpecialUrlLinks(product.productSpecialUrlFiles)
        : setSpecialUrlLinks([]);
      product.productSpecialUrlFiles
        ? setSpecialUrlLinkFields(formOldArray(product.productSpecialUrlFiles))
        : setSpecialUrlLinkFields([{ value: '' }]);

      product.maxOrderQty
        ? setMaxOrderQty(product.maxOrderQty)
        : setMaxOrderQty(null);

      setCountInStock(product.countInStock);
    }
    // }
  }, [dispatch, history, product, productId]); /*, successUpdate*/

  useEffect(() => {
    // setTimeout(() => {
    setGeoLocation({ lat: parseFloat(latitude), lng: parseFloat(longitude) });
    console.log(
      'UE lat:',
      latitude,
      'UE lng:',
      longitude,
      'UE geoLocation:',
      geoLocation
    );
    // }, 100);
  }, [latitude, longitude]);

  // useEffect(() => {
  //   // setGeoLocation({ lat: parseFloat(latitude), lng: parseFloat(longitude) });
  // }, []);

  useEffect(() => {
    setTimeout(() => {
      if (
        (!latitude || !longitude) &&
        (!product.latitude || !product.longitude)
      ) {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition((position) => {
            // const coords = {
            //   lat: position.coords.latitude,
            //   lng: position.coords.longitude,
            // };
            if (
              !latitude &&
              !longitude &&
              !product.latitude &&
              !product.longitude
            ) {
              console.log(
                'coordinates: ',
                position.coords.latitude,
                position.coords.longitude
              );
              // setGeoLocation(coords);
              setLatitude(position.coords.latitude);
              setLongitude(position.coords.longitude);
            }
          });
        } else {
          console.log('Geo Location not available..');
        }
      }
    }, 5000);
  }, []);

  const uploadFilehandler = async (e) => {
    console.log('e.target:', e.target.id, e.target.files.length);
    const formData = new FormData();

    if (e.target.id === 'images-multiple') {
      console.log('e.target.files:', e.target.files);
      for (let i = 0; i < e.target.files.length; i++) {
        console.log('e.target.files[' + i + ']:', e.target.files[i]);
        formData.append('multi-files', e.target.files[i]);
      }
    } else if (e.target.id === 'image-single') {
      console.log('e.target.files[0]:', e.target.files[0]);
      console.log('e.target.files:', e.target.files);
      const file = e.target.files[0];
      formData.append('image', file);
    }

    console.log('formData:', formData);
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data: boundary=add-random-characters',
        // 'Content-Type': 'multipart/form-data',
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    // try {
    //   setImage(`${process.env.REACT_APP_BACKEND_URL}${data}`);
    if (e.target.id === 'images-multiple') {
      try {
        setFileUploading(true);
        setErrorUpload('');
        // multiple
        const { data } = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/api/upload/multiple`,
          formData,
          config
        );
        let imagesArr = [];
        for (let j = 0; j < data.length; j++) {
          // imagesArr[j] = `${process.env.REACT_APP_BACKEND_URL}/${data[j]}`;
          imagesArr[j] = `${data[j]}`;
        }
        setImages(imagesArr);
        setFileUploading(false);
      } catch (error) {
        setFileUploading(false);
        error.response && error.response.data.message
          ? setErrorUpload(error.response.data.message)
          : setErrorUpload(error.message);
      }
    } else if (e.target.id === 'image-single') {
      try {
        setFileUploadingSingle(true);
        setErrorUploadSingle('');
        // single
        const { data } = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/api/upload`,
          formData,
          config
        );
        // setImage(`${process.env.REACT_APP_BACKEND_URL}${data}`);
        setImage(`${data}`);
        setFileUploadingSingle(false);
      } catch (error) {
        setFileUploadingSingle(false);

        error.response && error.response.data.message
          ? setErrorUploadSingle(error.response.data.message)
          : setErrorUploadSingle(error.message);
      }
    }

    // setFileUploading(false);
    // } catch (error) {
    //   console.log(error);
    //   setFileUploadingSingle(false);
    //   setFileUploading(false);
    // }
  };

  const updateLatLng = (lat, lng) => {
    setLatitude(lat);
    setLongitude(lng);
    setTimeout(() => {
      // setGeoLocation({ lat: parseFloat(lat), lng: parseFloat(lng) });
    }, 3000);

    console.log('updateLatLng Latitude:', lat);
    console.log('updateLatLng Longitude:', lng);
  };

  const handleAddVideoLinkField = (e) => {
    e.preventDefault();
    setVideoLinkFields([...videoLinkFields, { value: '' }]);
  };
  const handleVideoLinkFieldChange = (e, index) => {
    e.preventDefault();
    const strUrl = e.target.value;
    const newVideoLinkFields = [...videoLinkFields];
    newVideoLinkFields[index].value = e.target.value;

    setVideoLinkFields(newVideoLinkFields);
  };
  const handleVideoLinkFieldBlur = (e, index) => {
    e.preventDefault();
    const strUrl = e.target.value;
    const newVideoLinkFields = [...videoLinkFields];
    newVideoLinkFields[index].value = e.target.value;
    if (
      (strUrl.startsWith('https://') || strUrl.startsWith('http://')) &&
      (strUrl.includes('youtube.com') || strUrl.includes('youtu.be'))
    ) {
      setVideoLinkFields(newVideoLinkFields);
      console.log('-> A');
    } else {
      setVideoLinkFields(videoLinkFields);
      console.log('-> B');
      e.target.value = '';
      e.target.placeholder = 'Please add a valid Youtube video URL..';
    }
    console.log('videoLinkFieldsBlur:', videoLinkFields);
  };
  const handleVideoLinkFieldRemove = (e, index) => {
    e.preventDefault();
    const newVideoLinkFields = [...videoLinkFields];
    newVideoLinkFields.splice(index, 1);
    setVideoLinkFields(newVideoLinkFields);
  };
  const showAddMore = videoLinkFields.every(
    (inputField) => inputField.value !== ''
  );

  //
  const formNewArray = (arrayOld) => {
    let newArray = arrayOld.map((item) => item.value);
    return newArray;
  };
  const formOldArray = (arrayNew) => {
    let oldArray = arrayNew.map((item) => ({ value: item }));
    return oldArray;
  };

  // Batas
  const handleAddSpecialUrlLinkField = (e) => {
    e.preventDefault();
    setSpecialUrlLinkFields([...specialUrlLinkFields, { value: '' }]);
  };
  const handleSpecialUrlLinkFieldChange = (e, index) => {
    e.preventDefault();
    const newSpecialUrlLinkFields = [...specialUrlLinkFields];
    newSpecialUrlLinkFields[index].value = e.target.value;
    setSpecialUrlLinkFields(newSpecialUrlLinkFields);
    console.log('specialUrlLinkFields:', specialUrlLinkFields);
  };
  const handleSpecialUrlLinkFieldRemove = (e, index) => {
    e.preventDefault();
    const newSpecialUrlLinkFields = [...specialUrlLinkFields];
    newSpecialUrlLinkFields.splice(index, 1);
    setSpecialUrlLinkFields(newSpecialUrlLinkFields);
  };
  const showAddMoreSpecialUrl = specialUrlLinkFields.every(
    (inputField) => inputField.value !== ''
  );

  useEffect(() => {
    setVideos(formNewArray(videoLinkFields)); // added any number of video array added
  }, [videoLinkFields]);

  useEffect(() => {
    setSpecialUrlLinks(formNewArray(specialUrlLinkFields)); // added any number of video array added
  }, [specialUrlLinkFields]);

  const handleCheckedIsActive = (e) => {
    setIsActive(!isActive);
  };

  const submitHandler = (e) => {
    e.preventDefault();

    window.scrollTo(0, 0);

    console.log('lat, lng:', latitude, longitude);
    dispatch(
      updateProduct({
        _id: productId,
        name,
        description,
        image,
        videos,
        images,
        price,
        priceUnit,
        countInStock,
        brand,
        category,
        village,
        subDistrict,
        district,
        province,
        latitude,
        longitude,
        isActive,
        specialUrlLinks,
        maxOrderQty,
        user,
      })
    );
  };

  return (
    <>
      <div>
        <h1>Sunting Produk / Layanan</h1>
        {loadingUpdate && <Loading />}
        {errorUpdate && <Message variant='danger'>{errorUpdate}</Message>}
        {successUpdate && (
          <Message variant='success'>Sukses! Update produk berhasil..</Message>
        )}
        {loading ? (
          <Loading />
        ) : error ? (
          <Message variant='danger'>{error}</Message>
        ) : (
          <Form
            encType='multipart/form-data'
            className='page-edit-product'
            onSubmit={submitHandler}
          >
            <Row>
              <Col xs={12} md={6}>
                <Form.Group className='row-item'>
                  <Form.Label>Judul Produk / Layanan Ini</Form.Label>
                  <Form.Control
                    type='text'
                    placeholder='Beri Judul Produk / Layanan Agar Mudah Ditemukan Calon Pembeli'
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  ></Form.Control>
                </Form.Group>
                <Form.Group className='row-item'>
                  <Form.Label className='mt-2'>
                    <img src={image} alt={name} />
                    <br />
                    Gambar Utama
                  </Form.Label>
                  <Form.Control
                    type='text'
                    value={image}
                    placeholder='Masukkan alamat web gambar'
                    onChange={(e) => setImage(e.target.value)}
                    style={{ display: 'none' }}
                  ></Form.Control>
                  <Form.Label>
                    Unggah / Ganti Gambar Utama? (max file size: 500 kb)
                  </Form.Label>
                  <Form.Control
                    className='row-item'
                    type='file'
                    id='image-single'
                    accept='image/*'
                    name='image'
                    label='Pilih Gambar'
                    custom
                    onChange={uploadFilehandler}
                  ></Form.Control>
                  {fileUploadingSingle && <Loading />}
                  {errorUploadSingle && (
                    <Message variant='danger'>{errorUploadSingle}</Message>
                  )}
                  {/* <Form.File
                id='image-file'
                label='Choose File'
                custom
                onChange={uploadFilehandler}
              /> 
              /* doesn't work, replacement below
              */}
                </Form.Group>
                <Form.Group className='row-item'>
                  <Form.Label className='mt-2'>
                    Link Video Promo Produk di YouTube? (Opsional)
                  </Form.Label>
                  {videoLinkFields.map((inputField, index) => (
                    <div className='mb-2 pos-relative' key={index}>
                      <input
                        type='text'
                        className='form-control'
                        style={{ paddingRight: '6rem' }}
                        value={inputField.value}
                        placeholder='https://www.youtube.com/watch?v='
                        onChange={(e) => handleVideoLinkFieldChange(e, index)}
                        onBlur={(e) => handleVideoLinkFieldBlur(e, index)}
                      />
                      <button
                        className='pos-absolute right-middle btn btn-transparent'
                        style={{ top: '1rem', color: '#a8a8a8' }}
                        onClick={(e) => handleVideoLinkFieldRemove(e, index)}
                      >
                        -Remove
                      </button>
                    </div>
                  ))}
                  {showAddMore && (
                    <>
                      <br />
                      <button
                        className='btn btn-transparent'
                        onClick={(e) => handleAddVideoLinkField(e)}
                      >
                        +Add
                      </button>
                    </>
                  )}
                </Form.Group>

                <Form.Group className='row-item'>
                  <Form.Label>Gambar-gambar Tambahan</Form.Label>
                  <Form.Control
                    as='textarea'
                    row='10'
                    col='6'
                    value={images}
                    placeholder='List URL gambar-gambar akan muncul di sini...'
                    onChange={(e) => setImages(e.target.value)}
                    style={{ height: '150px' }}
                    disabled={true}
                  ></Form.Control>
                  <Form.Group className='row-item mt-3'>
                    {images
                      ? images.map((imgUrl) => (
                          <img
                            key={imgUrl}
                            src={imgUrl}
                            alt={name}
                            style={{
                              width: '100px',
                              height: '100px',
                              display: 'inline-block',
                              marginRight: '10px',
                            }}
                          />
                        ))
                      : ''}
                  </Form.Group>
                  <Form.Label className='mt-0'>
                    Unggah Gambar-gambar Tambahan? (max 10 pcs, max file size:
                    500 kb)
                  </Form.Label>
                  <Form.Control
                    className='row-item'
                    type='file'
                    id='images-multiple'
                    accept='image/*'
                    name='multi-files'
                    label='Pilih Gambar'
                    custom
                    onChange={uploadFilehandler}
                    multiple
                  ></Form.Control>
                  {fileUploading && <Loading />}
                  {errorUpload && (
                    <Message variant='danger'>{errorUpload}</Message>
                  )}
                </Form.Group>
              </Col>
              <Col xs={12} md={6}>
                <Form.Group className='row-item'>
                  <Row>
                    <Col xs={12} sm={6}>
                      <Form.Label>
                        Nama Depan Kamu (
                        <span style={{ textTransform: 'lowercase' }}>
                          a.k.a.
                        </span>{' '}
                        Brand Selfie)
                      </Form.Label>
                      <Form.Control
                        type='text'
                        value={brand}
                        placeholder='Masukkan nama depan / nama akrab kamu'
                        onChange={(e) => setBrand(e.target.value)}
                      ></Form.Control>
                    </Col>
                    <Col xs={12} sm={6}>
                      <Form.Label>Kategori / Tag</Form.Label>
                      <Form.Control
                        type='text'
                        value={category}
                        placeholder='Masukkan kategori paling tepat untuk produk layanan ini'
                        onChange={(e) => setCategory(e.target.value)}
                      ></Form.Control>
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group className='row-item'>
                  <Row>
                    <Col xs={12} sm={12}>
                      <Form.Label style={{ marginTop: '0' }}>
                        Area Layanan
                      </Form.Label>{' '}
                      {latitude}, {longitude} (opsional)
                    </Col>
                  </Row>

                  <GetKecamatan
                    province={province}
                    district={district}
                    subDistrict={subDistrict}
                    village={village}
                    onProvince={setProvince}
                    onDistrict={setDistrict}
                    onSubDistrict={setSubDistrict}
                    onVillage={setVillage}
                    villageColHide={false}
                  />
                  {/*
              <Row>
                <Col xs={12} sm={12}>
                  <div className='divider mt-4 mb-2'>
                    <span className='text-uppercase'>
                      Pilih dan geser pin hijau
                    </span>
                  </div>
                  <MapsGoogle
                    center={geoLocation}
                    zoom={zoom}
                    style={{ width: '100%', height: '380px', maxWidth: '100%' }}
                    onDragHandler={updateLatLng}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={6}>
                  <Form.Label>Koordinat Latitude latitude:</Form.Label>
                  <Form.Control
                    type='text'
                    id='latbox'
                    value={latitude}
                    name='lat'
                    placeholder='Koordinat Peta Latitude'
                    onChange={(e) => setLatitude(e.target.value)}
                    disabled={true}
                  ></Form.Control>
                </Col>
                <Col xs={12} sm={6}>
                  <Form.Label>Koordinat Longitude longitude:</Form.Label>
                  <Form.Control
                    type='text'
                    id='lngbox'
                    value={longitude}
                    name='lng'
                    placeholder='Koordinat Peta Longitude'
                    onChange={(e) => setLongitude(e.target.value)}
                    disabled={true}
                  ></Form.Control>
                </Col>
              </Row> */}
                  {/*  <Row>
                <Col xs={12} sm={12}>
                  <Form.Label>Pilih Area</Form.Label> (opsional)
                </Col>
              </Row>
              <GetKecamatan
                province={province}
                district={district}
                subDistrict={subDistrict}
                onProvince={setProvince}
                onDistrict={setDistrict}
                onSubDistrict={setSubDistrict}
                onVillage={setVillage}
                villageColHide={true}
              />

              {/*
              <Row>
                <Col xs={12} sm={4}>
                  <Form.Label>Propinsi:</Form.Label>
                  <Form.Control
                    type='text'
                    placeholder='Enter Province'
                    value={province}
                    onChange={(e) => setProvince(e.target.value)}
                  ></Form.Control>
                </Col>
                <Col xs={12} sm={4}>
                  <Form.Label>Kabupaten / Kota:</Form.Label>
                  <Form.Control
                    type='text'
                    placeholder='Enter District'
                    value={district}
                    onChange={(e) => setDistrict(e.target.value)}
                  ></Form.Control>
                </Col>
                <Col xs={12} sm={4}>
                  <Form.Label>Kecamatan:</Form.Label>
                  <Form.Control
                    type='text'
                    placeholder='Enter Sub-District'
                    value={subDistrict}
                    onChange={(e) => setSubDistrict(e.target.value)}
                  ></Form.Control>
                </Col>
              </Row>
              */}
                </Form.Group>
                <Form.Group className='row-item'>
                  <Form.Label>Deskripsi Produk Layanan</Form.Label>
                  <Form.Control
                    as='textarea'
                    row='10'
                    col='6'
                    value={description}
                    placeholder='Perinci detail produk / pelayanan kamu agar calon pembeli jelas'
                    onChange={(e) => setDescription(e.target.value)}
                    className='product-edit'
                  ></Form.Control>
                </Form.Group>

                <Form.Group className='row-item'>
                  <Row>
                    <Col xs={12} sm={6}>
                      <Form.Label>Harga / Rate (Rp)</Form.Label>
                      <Form.Control
                        type='number'
                        step={0.01}
                        placeholder='Beri Harga yang Pantas (Rp)'
                        value={price}
                        onChange={(e) => setPrice(e.target.value)}
                      ></Form.Control>
                    </Col>
                    <Col xs={12} sm={6}>
                      <Form.Label>Unit Harga (Rate)</Form.Label>
                      <Form.Control
                        type='text'
                        placeholder='Unit harga satuan, misal: Jam, Hari, Bulan, Paket, Sesi, dlsb.'
                        value={priceUnit}
                        onChange={(e) => setPriceUnit(e.target.value)}
                      ></Form.Control>
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group className='ms-3'>
                  <Form.Check
                    type='switch'
                    id='switchIsActive'
                    label='Switch On/Off (Aktif/Non-Aktifkan Produk Ini)'
                    value={isActive}
                    checked={isActive}
                    onChange={() => handleCheckedIsActive()}
                  />
                </Form.Group>
                <Row>
                  <Col xs={12} md={6}>
                    <Form.Group className='row-item'>
                      <Form.Label className='mt-5'>
                        Link URL Special? (Opsional)
                      </Form.Label>

                      {specialUrlLinkFields.map((inputField, index) => (
                        <div className='mb-2 pos-relative' key={index}>
                          <input
                            type='text'
                            className='form-control'
                            placeholder='https://'
                            style={{ paddingRight: '6rem' }}
                            value={inputField.value}
                            onChange={(e) =>
                              handleSpecialUrlLinkFieldChange(e, index)
                            }
                          />
                          <button
                            className='pos-absolute right-middle btn btn-transparent'
                            style={{ top: '1rem', color: '#a8a8a8' }}
                            onClick={(e) =>
                              handleSpecialUrlLinkFieldRemove(e, index)
                            }
                          >
                            -Remove
                          </button>
                        </div>
                      ))}
                      <p>
                        <em>
                          Cocok jika jual produk digital yg bisa didownload.
                          Link produk akan otomatis dikirim ke pembeli setelah
                          konfirmasi pembayaran oleh buyer.
                        </em>
                      </p>
                      {showAddMoreSpecialUrl && (
                        <>
                          <button
                            className='btn btn-transparent'
                            onClick={(e) => handleAddSpecialUrlLinkField(e)}
                          >
                            +Add
                          </button>
                        </>
                      )}
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={6}>
                    <Form.Label>Maximum Quantity Order (Opsional)</Form.Label>

                    <Form.Control
                      type='number'
                      step={1}
                      min={1}
                      max={1000}
                      placeholder='Max Qty'
                      value={maxOrderQty}
                      onChange={(e) => setMaxOrderQty(e.target.value)}
                    ></Form.Control>
                    <p>
                      <em>
                        Jumlah kelipatan maximum yang dapat dipesan sekaligus
                        dalam sebuah transaksi
                      </em>
                    </p>
                  </Col>
                </Row>
                <Form.Control
                  id='user'
                  type='hidden'
                  value={user}
                  onChange={(e) => setUser(e.target.value)}
                ></Form.Control>
              </Col>
            </Row>
            <Row>
              <Col xs={12} className='right-horizontal'>
                <Button type='submit' variant='primary' className='my-3'>
                  Simpan / Update Produk
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </div>
      <Link to='/admin/products-list' className='btn btn-transparent my-3 px-4'>
        <i className='fa fa-angle-left'></i> Kembali
      </Link>
    </>
  );
};

export default ProductEditPage;
