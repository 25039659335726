import React, { useEffect } from 'react';
import ReactGA from 'react-ga';

// import MapsGoogle from '../components/MapsGoogle';
import MapsGooglePins from '../components/MapsGooglePins';
import Meta from '../components/Meta';
// import ProductsTopRatedPinCoords from '../components/ProductsTopRatedPinCoords';

const MapsGooglePage = (props) => {
  ReactGA.initialize(process.env.REACT_APP_GUA);

  const coordinates = { lat: -6.8987017512709405, lng: 107.61869506852243 }; //center={props.coordinates}
  // const handleDataFeed = (products) => {
  //   const items = products;
  // };
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Meta
        title={`Maps of Helpful Users Nearby at ${process.env.REACT_APP_SITENAME}`}
        description={`Helpful Users Nearby`}
        // image={``}
      />
      <h2>Helpful Users Nearby</h2>
      {/* <ProductsTopRatedPinCoords onCall={handleDataFeed} /> */}
      <MapsGooglePins
        center={coordinates}
        zoom={13}
        style={{ width: '100%', height: '100%', minHeight: '80vh' }}
      />
    </>
  );
};

export default MapsGooglePage;
