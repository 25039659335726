import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { Table, Form, Button, Row, Col } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Message from '../components/Message';
import Loading from '../components/Loading';
import { getUserProfile, updateUserProfile } from '../actions/userActions';
import { USER_PROFILE_UPDATE_RESET } from '../constants/userConstant';
import { listMyOrders } from '../actions/orderActions';
import { listMyProducts, createProduct } from '../actions/productActions';
import { PRODUCT_CREATE_RESET } from '../constants/productConstants';

const ProfilePage = ({ location, match }) => {
  const [name, setName] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [userName, setUserName] = useState('');
  const [phone, setPhone] = useState('');
  const [birthday, setBirthday] = useState('');
  const [sex, setSex] = useState('');
  const [bio, setBio] = useState('');
  const [image, setImage] = useState('/assets/img/users/user.png');
  const [fileUploading, setFileUploading] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState(null);

  const dispatch = useDispatch();

  const pageNumber = match.params.pageNumber || 1;

  const userProfile = useSelector((state) => state.userProfile);
  const { loading, error, user } = userProfile;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const userProfileUpdate = useSelector((state) => state.userProfileUpdate);
  const { success } = userProfileUpdate;
  //   const success = true;

  const orderMyList = useSelector((state) => state.ordersMyList);
  const { loading: loadingOrders, error: errorOrders, orders } = orderMyList; // rename needed to differentiate

  const productsListMy = useSelector((state) => state.productsListMy);
  const {
    loading: loadingProducts,
    error: errorProducts,
    products,
    page,
    pages,
    keyword,
  } = productsListMy;

  //   console.log('userProfile: ', JSON.stringify(userProfile));
  //   console.log('userLogin: ', JSON.stringify(userLogin));
  // console.log('userProfileUpdate: ', JSON.stringify(userProfileUpdate));

  const productCreate = useSelector((state) => state.productCreate);
  const {
    loading: loadingCreate,
    error: errorCreate,
    success: successCreate,
    product: createdProduct,
  } = productCreate;

  const createProductHandler = (product) => {
    dispatch(createProduct());
  };

  const history = useHistory();
  const redirect = location.search ? location.search.split('=')[1] : '/';

  // console.log('userInfo:', userInfo);
  // console.log('user:', user);
  // console.log('productsListMy:', productsListMy);
  // console.log('orderMyList:', orderMyList);

  useEffect(() => {
    dispatch({ type: PRODUCT_CREATE_RESET });
    dispatch({ type: USER_PROFILE_UPDATE_RESET });

    if (!userInfo) {
      history.push('/login');
      // dispatch(listProducts());
    }
    if (successCreate) {
      history.push(`/admin/product/${createdProduct._id}/edit`);
    }
    // else {
    //   dispatch(listProducts('', pageNumber)); // keyword set to empty ''
    // }
  }, [
    dispatch,
    userInfo,
    history,
    // successDelete,
    successCreate,
    createdProduct,
    pageNumber,
  ]);

  useEffect(() => {
    if (!userInfo) {
      history.push('/login');
    } else {
      if (!user || !user.name) {
        dispatch({ type: USER_PROFILE_UPDATE_RESET });
        dispatch(getUserProfile('profile'));

        // setOrdersList(orders);
      } else {
        setName(user.name);
        setEmail(user.email);
        setImage(user.image);
        setFirstName(user.firstName);
        setLastName(user.lastName);
        setUserName(user.userName);
        setPhone(user.phone);
        setBirthday(user.birthday);
        setSex(user.sex);
        setBio(user.bio);
      }
      // console.log('products:', products);
    }
  }, [dispatch, history, userInfo, user, redirect]);

  useEffect(() => {
    dispatch(listMyOrders());
    dispatch(listMyProducts());
  }, [dispatch, history, userInfo, user, redirect]);

  const today = new Date();
  const adultDate = new Date(today);
  adultDate.setDate(adultDate.getDate() - 6570); // 18 years old
  const minDate = adultDate.toISOString().split('T')[0];

  const uploadFilehandler = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append('image', file);
    setFileUploading(true);

    try {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };

      const { data } = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/upload`,
        formData,
        config
      );

      setImage(`${process.env.REACT_APP_BACKEND_URL}${data}`);
      setFileUploading(false);
    } catch (error) {
      console.log(error);
      setFileUploading(false);
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    // set full name
    if (firstName && lastName) {
      setName(`${firstName} ${lastName}`);
    }

    if (password !== confirmPassword) {
      setMessage('Passwords do not match');
    } else {
      dispatch(
        updateUserProfile({
          id: user._id,
          name,
          firstName,
          lastName,
          email,
          userName,
          phone,
          birthday,
          sex,
          bio,
          image,
          password,
        })
      );
    }
  };

  const deleteHandler = (product) => {
    if (
      window.confirm(
        `Anda yakin akan menghapus produk "${product.name}" dengan id # ${product._id}?`
      )
    ) {
      // dispatch(deleteProduct(product._id));
    }
  };

  return (
    <Row className='user-profile'>
      <Col xs={12}>
        <h1 className='mt-3 mb-5'>Laman Pribadi {name}</h1>
      </Col>
      <Col xs={12} sm={8} md={8}>
        <div className='mx-3 my-3'>
          <Button className='my-3' onClick={createProductHandler}>
            <i className='fas fa-plus' style={{ fontSize: '1.5rem' }}></i>{' '}
            &nbsp; Tambah Produk / Layanan
          </Button>
        </div>
        <div className='mx-3 my-3'>
          <h2 className='mb-3'>Produk / Layanan Saya</h2>
          {loadingProducts ? (
            <Loading />
          ) : errorProducts ? (
            <Message variant='danger'>{errorProducts}</Message>
          ) : (
            <Table striped bordered hover responsive className='table-sm'>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>NAME</th>
                  <th>PRICE</th>
                  <th>UNIT PRICE</th>
                  <th>CATEGORY</th>
                  <th>PERSONAL BRAND</th>
                  <th>EDIT/VIEW</th>
                </tr>
              </thead>
              <tbody>
                {products.length > 0 ? (
                  products.map((product) => (
                    <tr key={product._id}>
                      <td>{product._id}</td>
                      <td>{product.name}</td>
                      <td>Rp{product.price}</td>
                      <td>/{product.priceUnit}</td>
                      <td>{product.category}</td>
                      <td>{product.brand}</td>
                      <td>
                        <LinkContainer
                          to={`/admin/product/${product._id}/edit`}
                        >
                          <Button
                            variant='light'
                            className='btn btn-txt inline-block'
                          >
                            <i className='fa fa-edit'></i>
                          </Button>
                        </LinkContainer>
                        <LinkContainer to={`/products/${product._id}`}>
                          <Button
                            variant='light'
                            className='btn btn-txt inline-block'
                          >
                            <i className='fa fa-eye'></i>
                          </Button>
                        </LinkContainer>
                        {/* <Button
                            variant='danger'
                            className='btn btn-txt inline-block'
                            onClick={() => deleteHandler(product)}
                          >
                            <i className='fa fa-times'></i>
                          </Button> */}
                      </td>
                    </tr>
                  ))
                ) : (
                  <>
                    <tr>
                      <td colSpan={7}>Anda Belum Punya Produk / Layanan</td>
                    </tr>
                  </>
                )}
              </tbody>
            </Table>
          )}
        </div>
        <div className='mx-3 my-3'>
          <h2 className='mb-3'>Orders List</h2>
          {loadingOrders ? (
            <Loading />
          ) : errorOrders ? (
            <Message variant='danger'>{errorOrders}</Message>
          ) : (
            <Table striped bordered hover responsive className='table-sm'>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Date</th>
                  <th>Total</th>
                  <th>Paid</th>
                  <th>Fulfilled</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {orders.length > 0 ? (
                  orders.map((order) => (
                    <tr key={order._id}>
                      <td>{order._id}</td>
                      <td>{order.createdAt.substring(0, 10)}</td>
                      <td>{order.totalPrice}</td>
                      <td>
                        {order.isPaid ? (
                          <>
                            <i
                              className='fas fa-check'
                              style={{ color: 'green' }}
                            ></i>
                            &nbsp;
                            {' ('}
                            {order.paidAt.substring(0, 10)})
                          </>
                        ) : (
                          <i
                            className='fas fa-times'
                            style={{ color: 'red' }}
                          ></i>
                        )}
                      </td>
                      <td>
                        {order.isDelivered ? (
                          order.deliveredAt.substring(0, 10)
                        ) : (
                          <i
                            className='fas fa-hourglass-start'
                            style={{ color: 'grey', fontSize: 'small' }}
                          ></i>
                        )}
                      </td>
                      <td>
                        <LinkContainer to={`/order/${order._id}`}>
                          <Button className='btn' variant='light'>
                            View
                          </Button>
                        </LinkContainer>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={6}>Belum ada</td>
                  </tr>
                )}
              </tbody>
            </Table>
          )}
        </div>
      </Col>
      <Col xs={12} sm={4} md={4} style={{ borderRight: '1px solid #eee' }}>
        <div className='mx-3 my-3'>
          {user.image ? (
            <img
              src={image}
              alt={user.name}
              style={{ maxWidth: '200px', borderRadius: '50%' }}
            />
          ) : (
            <img
              src={image}
              alt={user.name}
              style={{ maxWidth: '200px', borderRadius: '50%' }}
            />
          )}
          <h2 className='mb-3'>Profile</h2>
          {message && <Message variant='danger'>{message}</Message>}
          {error && <Message variant='danger'>{error}</Message>}
          {success && <Message variant='success'>Profile updated...</Message>}
          {loadingCreate && <Loading />}
          {errorCreate && <Message variant='danger'>{errorCreate}</Message>}
          {loading && <Loading />}
          <Form onSubmit={submitHandler}>
            <Form.Group controlId='image-cont'>
              <Form.Label>Foto</Form.Label>
              <Form.Control
                type='text'
                value={image}
                placeholder='Enter image URL'
                onChange={(e) => setImage(e.target.value)}
              ></Form.Control>
              <Form.Control
                type='file'
                id='image-file'
                label='Choose File'
                custom
                onChange={uploadFilehandler}
              ></Form.Control>
              {fileUploading && <Loading />}
            </Form.Group>
            <Form.Group controlId='bio'>
              <Form.Label>Bio</Form.Label>
              <Form.Control
                as='textarea'
                value={bio}
                onChange={(e) => setBio(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <Form.Group controlId='firstName'>
              <Form.Label>Nama Depan</Form.Label>
              <Form.Control
                type='text'
                placeholder='Enter First Name'
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <Form.Group controlId='lastName'>
              <Form.Label>Nama Belakang</Form.Label>
              <Form.Control
                type='text'
                placeholder='Enter Surname'
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <Form.Group controlId='email'>
              <Form.Label>Email</Form.Label>
              <Form.Control
                type='email'
                placeholder='Enter email'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <Form.Group controlId='userName'>
              <Form.Label>User Name</Form.Label>
              <Form.Control
                type='text'
                placeholder='User Name Unique'
                value={userName}
                onChange={(e) => setUserName(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <Form.Group controlId='phone'>
              <Form.Label>Telepon</Form.Label>
              <Form.Control
                type='text'
                placeholder='Enter your phone'
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <Form.Group controlId='phone'>
              <Form.Label>Tanggal Lahir</Form.Label>
              <Form.Control
                as='input'
                type='date'
                placeholder='Enter your birth date'
                max={minDate}
                value={birthday}
                onChange={(e) => setBirthday(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <Form.Group controlId='sex'>
              <div>
                <Form.Label>Jenis Kelamin</Form.Label>
              </div>
              <Form.Check
                inline
                type='radio'
                name='sex'
                id='sexMale'
                label='Pria'
                value='Male'
                checked={sex === 'Male' ? true : false}
                onChange={(e) => setSex(e.target.value)}
              ></Form.Check>
              <Form.Check
                inline
                type='radio'
                name='sex'
                id='sexFemale'
                label='Wanita'
                value='Female'
                checked={sex === 'Female' ? true : false}
                onChange={(e) => setSex(e.target.value)}
              ></Form.Check>
              <Form.Check
                inline
                type='radio'
                name='sex'
                id='sexNotToSay'
                label='Prefer Not to Say'
                value='Not to Say'
                checked={sex === 'Not to Say' ? true : false}
                onChange={(e) => setSex(e.target.value)}
              ></Form.Check>
            </Form.Group>

            <Form.Group controlId='password'>
              <Form.Label>Password</Form.Label>
              <Form.Control
                type='password'
                placeholder='Enter password'
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <Form.Group controlId='confirmPassword'>
              <Form.Label>Konfirmasi Password</Form.Label>
              <Form.Control
                type='password'
                placeholder='Enter Confirm Password'
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <Button type='submit' variant='primary' className='my-3'>
              Perbaharui Profil
            </Button>
          </Form>
          <hr />
        </div>
      </Col>
    </Row>
  );
};

export default ProfilePage;
