import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Carousel, Image } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { formatPrice } from '../shared/functions';
import Loading from './Loading';
import Message from './Message';
import { listTopRatedProducts } from '../actions/productActions';
import Rating from './Rating';

const ProductsTopRatedCarousel = () => {
  const dispatch = useDispatch();

  const productTopRated = useSelector((state) => state.productTopRated);
  const { loading, error, products } = productTopRated;
  console.error('products:', products);

  useEffect(() => {
    dispatch(listTopRatedProducts());
  }, [dispatch]);

  return loading ? (
    <Loading />
  ) : error ? (
    <Message variant='danger'>{error}</Message>
  ) : (
    <Carousel
      pause='hover'
      className='top-rated border rounded'
      style={{ width: '100%' }}
    >
      {products.map((product) => (
        <Carousel.Item key={product._id} interval={5000}>
          <Link to={`/products/${product._id}`}>
            <Image src={product.image} alt={product.name} fluid />
            <Carousel.Caption className='carousel-caption'>
              <h2>
                {product.brand}, {product.name} <br />
                Rp{formatPrice(product.price)} /{product.priceUnit}
              </h2>
              <Rating
                value={product.rating}
                text={`${product.numReviews.toLocaleString()} reviews`}
              />
            </Carousel.Caption>
          </Link>
        </Carousel.Item>
      ))}
    </Carousel>
  );
};

export default ProductsTopRatedCarousel;
