import React, { useState, useEffect } from 'react';
// import axios from 'axios';
import { Row, Col, Carousel, Image } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Message from '../components/Message';
import Loading from '../components/Loading';
import {
  /*getUserProfile, */ getUserNameProfile,
} from '../actions/userActions';
// import { USER_PROFILE_UPDATE_RESET } from '../constants/userConstant';

const UserPage = ({ location, match, history }) => {
  const theUserName = match.params.userName.toLowerCase();

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [name, setName] = useState(`${firstName} ${lastName}`);
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [birthday, setBirthday] = useState('');
  const [sex, setSex] = useState('');
  const [bio, setBio] = useState('');
  const [image, setImage] = useState('');
  const [images, setImages] = useState([]);
  const [products, setProducts] = useState([]);
  // const [fileUploading, setFileUploading] = useState(false);
  const [userName, setUserName] = useState('');
  const [message, setMessage] = useState(null);

  const [subDistrict, setSubDistrict] = useState('');
  const [district, setDistrict] = useState('');
  const [province, setProvince] = useState('');
  const [village, setVillage] = useState('');
  const [country, setCountry] = useState('Indonesia');

  const dispatch = useDispatch();

  const userProfile = useSelector((state) => state.userProfile);
  const { loading, error, user } = userProfile;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  // console.log('userLogin:', userLogin);

  // const history = useHistory();
  //   const redirect = location.search ? location.search.split('=')[1] : '/';

  useEffect(() => {
    // dispatch({ type: USER_PROFILE_UPDATE_RESET });

    if (!user || !user.name) {
      dispatch(getUserNameProfile(theUserName));
    } else {
      setName(user.name);
      setFirstName(user.firstName);
      setLastName(user.lastName);
      setUserName(user.userName);
      setEmail(user.email);
      setImage(user.image);
      user.images ? setImages(user.images) : setImages([]);
      user.products ? setProducts(user.products) : setProducts([]);
      setPhone(user.phone);

      user.village ? setVillage(user.village) : setVillage('');
      user.subDistrict ? setSubDistrict(user.subDistrict) : setSubDistrict('');
      user.district ? setDistrict(user.district) : setDistrict('');
      user.province ? setProvince(user.province) : setProvince('');
      user.country ? setCountry(user.country) : setCountry('');

      setBirthday(user.birthday);
      setSex(user.sex);
      setBio(user.bio);
    }
  }, [dispatch, user, theUserName]);

  return (
    <>
      <Row>
        <Col xs={12}>
          {error && <Message variant='danger'>{error}</Message>}
          {message && <Message variant='danger'>{message}</Message>}
          {loading && <Loading />}
        </Col>
      </Row>
      {!error ? (
        <>
          <Row className='user-profile'>
            <Col
              xs={12}
              style={{
                background: '#f8f8f8',
                border: '1px solid #f2f2f2',
                borderRadius: '20px 20px 20px 20px',
              }}
              className='pos-relative'
            >
              <h1 className='mt-3 mb-3 text-center'>{firstName}</h1>
              <p className='mb-5 text-center'>{bio}</p>

              <div
                className='pos-absolute right-horizontal'
                style={{ right: '1rem', bottom: '-10px' }}
              >
                <img
                  src={
                    image
                      ? image
                      : user.image
                      ? user.image
                      : 'https://selfie.id/assets/img/users/user.png'
                  }
                  alt={`${user.firstName} ${user.lastName}|${user._id}`}
                  style={{ maxWidth: '50px', borderRadius: '50%' }}
                />
              </div>
            </Col>
            <Col xs={12}>
              <div className='my-3'>
                <Col xs={12}>
                  {images ? (
                    <Carousel
                      pause='hover'
                      className='border rounded'
                      style={{
                        height: '320px',
                        width: '100%',
                        overflow: 'hidden',
                      }}
                    >
                      <Carousel.Item key={image}>
                        <Image
                          src={image}
                          alt={name}
                          style={{ width: 'auto', maxHeight: '320px' }}
                        />
                      </Carousel.Item>
                      {images &&
                        images.map((imgUrl) => (
                          <Carousel.Item key={imgUrl}>
                            <Image
                              src={imgUrl}
                              alt={firstName}
                              style={{ width: 'auto', maxHeight: '320px' }}
                            />
                          </Carousel.Item>
                        ))}
                    </Carousel>
                  ) : (
                    <></>
                  )}
                </Col>
                {firstName} {lastName}
                <br />
                {province}, {district}, {subDistrict}, {village}
                <br />
                {bio}
                {/*
                Email: &lt;private&gt;{email}
                <br />
                Nomer Ponsel: &lt;private&gt;{phone}
                <br />
                  jenis kelamin: {sex}
                <br />
                date birth: &lt;private&gt;{birthday}*/}
                <br />
                username: {userName}
                {/* <br />
                id: {user._id} */}
              </div>
            </Col>
          </Row>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default UserPage;
