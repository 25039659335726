import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
// import { Container } from 'react-bootstrap';
import ReactGA from 'react-ga';

import Meta from '../components/Meta';
// import Message from '../components/Message';
// import Loading from '../components/Loading';
import FormContainer from '../components/FormContainer';
import SendMessage from '../components/SendMessage';
import { Col, Container, Row } from 'react-bootstrap';
// import GetKelurahan from '../components/GetKelurahan';

const AboutPage = (props) => {
  ReactGA.initialize(process.env.REACT_APP_GUA);

  // const [subDistrict, setSubDistrict] = useState('');
  // const [district, setDistrict] = useState('');
  // const [province, setProvince] = useState('');
  // const [village, setVillage] = useState('');

  useEffect(() => {
    // Analytics data
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <Row>
      <Meta title={`About Selfie.ID (Selfie Jobs Network)`} />
      <h1
        className='flex justify-between mb-2'
        style={{ color: '#696969', fontWeight: '300' }}
      >
        <span>About</span>
        <span>Selfie Jobs Network</span>

        {/* <svg
        style={{ height: '30px' }}
        fill='#585858'
        viewBox='0 0 220 30'
        className='svg-icon size-med st000'
      >
        <use xlinkHref='#selfie-jobs-txt' />
      </svg> */}
      </h1>
      {/* <svg
        fill='#a9a9a9'
        viewBox='0 0 24 24'
        className='svg-icon size-med st000'
      >
        <use xlinkHref='#icon-svg-eye' />
      </svg>
      <svg
        fill='#a9a9a9'
        viewBox='0 0 24 24'
        className='svg-icon size-med st000'
      >
        <use xlinkHref='#icon-svg-eye-shut' />
      </svg> */}
      {/* <p>
      <img
          src={`/assets/img/selfie-logo-txt.svg`}
          style={{ height: '35px' }}
          alt='Selfie Jobs'
        />
        <Link to='/signup/?utm_source=page-about-top'>
          <svg
            fill='#ffffff'
            viewBox='0 0 219 32'
            className='svg-icon size-med st000'
          >
            <use xlinkHref='#selfie-jobs-logo' />
          </svg>
        </Link>
      </p> */}
      <Col xs={12} md={6}>
        <p
          style={{
            fontSize: '1.1rem',
            color: 'black',
            border: '1px solid #d8d8d8',
            padding: '1rem 2rem',
            borderRadius: '1rem',
            backgroundColor: '#e8e8e8',
          }}
          className='mt-3 mb-4'
        >
          <em>
            &ldquo; Ekonomi Gig (Gig Economy) adalah model ekonomi di mana
            semakin banyak orang memilih bekerja sebagai pekerja lepas,
            freelancer atau kontraktor yang bebas, dari pada sebagai karyawan
            tetap di suatu perusahaan formal dengan jangka waktu kontrak yang
            mengikat.&rdquo;
          </em>
        </p>
        <p>
          <svg
            style={{
              width: '64px',
              float: 'left',
              marginRight: '0.5rem',
              marginBottom: '1rem',
              display: 'inline-block',
              padding: '0.3rem 0.3rem 0.3rem 0.5rem',
              border: '1px solid #d8d8d8',
              borderRadius: '8px',
            }}
            fill='#b8b8b8'
            viewBox='0 0 32 32'
            className='svg-icon'
          >
            <use xlinkHref='#selfie-logo-ls' />
          </svg>
          <strong style={{ fontWeight: '600', fontStyle: 'italic' }}>
            Selfie Jobs Network (Selfie.ID) adalah platform yg membantu pekerja
            lepas <u>membuat income</u> dari keterampilan, skill, hobby &
            kepribadian yang sudah dimiliki masing-masing individu (selfie).
          </strong>
        </p>
        <p style={{ clear: 'both' }}>
          <i
            className='fa fa-street-view'
            style={{
              fontSize: '2rem',
              color: '#a8a8a8',
              paddingRight: '0.5rem',
            }}
          ></i>{' '}
          Selfie.ID adalah jejaring komunitas pekerja lepas dengan karakter
          casual, non-formal, dengan <u>misi utama</u> memberdayakan pekerja
          lepas agar gampang mendapatkan cuan penghasilan atas layanan personal,
          kepribadian, hobby, keahlian & keterampilan unik masing-masing
          individu pekerja lepas.
        </p>
        <p>
          <i
            className='fa fa-coins'
            style={{
              fontSize: '2rem',
              color: '#a8a8a8',
              paddingTop: '0.5rem',
              paddingRight: '0.5rem',
            }}
          ></i>{' '}
          Di Selfie.ID pekerja lepas dapat membuat income dengan cara menjual
          produk berisi layanan unik, spesifik &amp; personal yg siap dibeli
          konsumen yg membutuhkan.
        </p>
        <p>
          <i
            className='fa fa-thumbs-up'
            style={{
              fontSize: '1.6rem',
              color: '#a8a8a8',
              paddingRight: '0.2rem',
            }}
          ></i>{' '}
          Di Selfie.ID ini kamu bisa mudah dapat bayaran atas produk berisi jasa
          layanan unik yang kamu tawarkan bagi mereka yang membutuhkan produk &
          layanan tersebut. <br />
          Jadi tidak ada lagi waktu yang terbuang percuma, jika kamu memang
          ingin produktif.
        </p>
        <p>
          <i
            className='fa fa-trophy'
            style={{
              fontSize: '2rem',
              color: '#a8a8a8',
              paddingRight: '0.2rem',
            }}
          ></i>{' '}
          Selfie.ID adalah platform khusus yang menghubungkan para pekerja lepas
          dengan konsumen yang membutuhkan jasa layanan mereka.
        </p>
        <p>
          <i
            className='fa fa-award'
            style={{
              fontSize: '2rem',
              color: '#a8a8a8',
              paddingRight: '0.5rem',
            }}
          ></i>{' '}
          SelfieID dinaungi oleh PT. Super Technology Indonesia yang merupakan
          badan usaha legal dan terdaftar di KemenKumHam Republik Indonesia
        </p>

        <div className='divider mt-5'>
          <h2 style={{ fontWeight: '300' }}>Inilah Faktanya</h2>
        </div>
        <p>
          <i
            className='fa fa-biking'
            style={{ fontSize: '2rem', color: '#a8a8a8', paddingRight: '1rem' }}
          ></i>{' '}
          Sebagai pejuang cuan, layaknya pekerja kantoran - tapi tanpa terikat
          waktu & tempat, kita juga sangat memerlukan kemudahan-kemudahan
          berikut ini <br />
          (betul, apa ga sih?) :
        </p>
        <ol>
          <li>
            Mudah bayar-bayar, beli-beli, sesuai keperluan kita 😅
            <br />
            Karena kita ada pemasukan, alias income sendiri...🤣
          </li>
          <li>
            Mudah cari uang dari hobby kamu atau pekerjaan lepas yg tidak
            mengikat, casual, non-formal, paruh waktu 🤩 Alias kerjaan yang ga
            nuntut komitmen jangka panjang, dress-code formal, kewajiban nulis
            cv/resume lah, dlsb... 😑
          </li>
          <li>
            <p>
              Mudah bikin cuan alias duit buat segala layanan jasa personal
              kita, sesuai dengan hobby & keterampilan unik kita 🤤.
            </p>
            <p>
              <i
                className='fa fa-people-carry'
                style={{
                  fontSize: '2rem',
                  color: '#a8a8a8',
                  paddingRight: '0.5rem',
                }}
              ></i>{' '}
              <br />
              Misalnya, kita bisa (hobby) nih, misal:
            </p>
            <ul>
              <li>bikin hampers, gift atau kado keren,</li>
              <li>bikin kue & jajanan lezat,</li>
              <li>masak makanan tradisional unik,</li>
              <li>design & sediain kostum pakaian anak,</li>
              <li>setir mobil, </li>
              <li>jago motret fotografi,</li>
              <li>pengalaman ngasuh anak, merawat balita, </li>
              <li>merawat lansia, </li>
              <li>rajin bersih-bersih & berberes, </li>
              <li>pintar main sosmed Tiktok & Instagram.</li>
              <li>
                instalasi listrik rumah, perbaiki pompa air, pasang bracket /
                reparasi tv led
              </li>
              <li>pintar fengshui?</li>
              <li>hobby main musik?</li>
              <li>hobby menggambar & ilustrasi?</li>
              <li>hobby coding?</li>
              <li>dlsb.</li>
            </ul>
            <p>
              <i
                className='fa fa-comment-dollar'
                style={{
                  fontSize: '2rem',
                  color: '#a8a8a8',
                  paddingTop: '1rem',
                  paddingRight: '0.5rem',
                }}
              ></i>{' '}
              Tahu ga, ada banyak calon konsumen yang nyari &amp; siap bayar
              pantas buat bantuan &amp; jasa layananmu tadi 😎
            </p>
            <p>
              <i
                className='fa fa-plus'
                style={{
                  fontSize: '2rem',
                  color: '#a8a8a8',
                  paddingRight: '0.5rem',
                }}
              ></i>{' '}
              Tinggal <u>tambahin produk layanan kamu</u>, kasih judul menarik,
              perinci isi produk layanan & tentukan batas-batas scope cakupan
              layanan, kasih label
              <i
                className='fa fa-tag'
                style={{
                  fontSize: '1.6rem',
                  color: '#a8a8a8',
                  paddingLeft: '0.5rem',
                  paddingRight: '0.1rem',
                }}
              ></i>{' '}
              harga dan bagikan info soal layanan kamu siap dibeli di media
              sosial kamu masing-masing. Tinggal siap-siap pas nanti ada yang
              beli
              <i
                className='fa fa-cart-plus'
                style={{
                  fontSize: '1.6rem',
                  color: '#a8a8a8',
                  paddingLeft: '0.5rem',
                }}
              ></i>
              .
            </p>
          </li>
          <li>
            <i
              className='fa fa-address-card'
              style={{
                fontSize: '2rem',
                color: '#a8a8a8',
                paddingRight: '0.5rem',
              }}
            ></i>{' '}
            Mudah menampilkan profil unik pribadi & personal kamu, alias profil
            SELFIE.ID kamu 😎😋
            <br />
            Yang ini emangnya ga perlu, yakin nih? 😁
          </li>
        </ol>

        <div className='divider mt-5'>
          <i
            className='fa fa-user-cog'
            style={{
              fontSize: '2rem',
              color: '#a8a8a8',
            }}
          ></i>
        </div>

        <p style={{ padding: '1rem 0' }}>
          Sampai jumpa di area <Link to='/signup/'>khusus member</Link>{' '}
          Selfie.ID kamu!
        </p>
        <hr />
      </Col>
      <Col xs={12} md={6}>
        <div className='divider mt-5'>
          <h2 style={{ fontWeight: '300' }}>Mengapa Sekarang?</h2>
        </div>
        <p>
          <i
            className='fa fa-laptop-code'
            style={{ fontSize: '2rem', color: '#a8a8a8', paddingRight: '1rem' }}
          ></i>{' '}
          Bangkitnya Ekonomi Gig (Gig Economy), yaitu model ekonomi di mana
          semakin banyak orang bekerja sebagai pekerja lepas, freelancer atau
          kontraktor yang bebas, alih-alih sebagai karyawan tetap di suatu
          perusahaan formal dengan kontrak yang mengikat.
        </p>
        <p>
          <i
            className='fa fa-handshake'
            style={{
              fontSize: '2rem',
              color: '#a8a8a8',
              paddingRight: '0.5rem',
            }}
          ></i>{' '}
          Selfie.ID adalah platform untuk menghubungkan pekerja lepas ekonomi
          gig dengan konsumen yang membutuhkan jasa layanan mereka{' '}
          {/* <i
          className='fa fa-handshake'
          style={{
            fontSize: '1.6rem',
            color: '#a8a8a8',
            paddingLeft: '0.5rem',
          }}
        ></i>{' '} */}
        </p>

        <div className='divider mt-5'>
          <i
            className='fa fa-cash-register'
            style={{
              fontSize: '2rem',
              color: '#a8a8a8',
            }}
          ></i>
        </div>
        <p style={{ padding: '1rem 0' }}>
          Ok, sekarang daftar aja dulu di <Link to='/signup/'>Tautan Ini</Link>{' '}
          untuk mendapatkan manfaat sebagai pengguna / member.{' '}
          <Link to='/signup/'>Yuk</Link>...
        </p>

        <hr />
        <div className='right-horizontal'>
          <div>
            <a
              href='https://twitter.com/intent/user?screen_name=selfieidjobs&ref_src=twsrc%5Etfw'
              className='twitter-follow-button no-underline'
              rel='noreferrer noopener'
              target='_blank'
              data-show-count='false'
            >
              FOLLOW{' '}
              <svg
                style={{ height: '24px' }}
                fill='#585858'
                viewBox='0 0 24 24'
                className='svg-icon size-med st000'
              >
                <use xlinkHref='#icon-svg-twitter' />
              </svg>{' '}
              @selfieidjobs
            </a>
          </div>
        </div>
        {/* <SendMessage /> */}
      </Col>
    </Row>
  );
};

export default AboutPage;
