import React from 'react';
// import { Spinner } from 'react-bootstrap';

import './Loading.css';

const Loading = () => (
  <div id='loading-spinner'>
    <span className='sr-only'>Loading...</span>
  </div>
);
/*
// BS 5's
const Loading = () => {
  return (
    <Spinner
      animation='border'
      role='status'
      style={{
        width: '50px',
        height: '50px',
        margin: 'auto',
        display: 'block',
      }}
    >
      <span className='sr-only'>Loading...</span>
    </Spinner>
  );
};
*/

export default Loading;
