import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import Loading from './Loading';

const PopupWindow = (props) => {
  return (
    <Modal
      {...props}
      size={props.modalSize ? props.modalSize : 'lg'}
      aria-labelledby='contained-modal-title-vcenter'
      centered
      className={props.className}
    >
      {props.header ? (
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>
            {props.modalTitle}
          </Modal.Title>
        </Modal.Header>
      ) : (
        ''
      )}

      <Modal.Body>
        <Button onClick={props.onHide} className='btn-transparent'>
          <i className='fa fa-times'></i>
        </Button>
        {props.setModalLoading ? <Loading /> : ''}
        {props.iframe ? (
          <iframe
            id='iframe-invoice'
            className='iframe-invoice'
            title='Invoice'
            src={props.iframeUrl}
          ></iframe>
        ) : (
          '<p>Modal Static Content</p>'
        )}
      </Modal.Body>
      {props.footer ? (
        <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      ) : (
        ''
      )}
    </Modal>
  );
};

export default PopupWindow;
