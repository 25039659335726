export const ORDER_CREATE_REQUEST = 'ORDER_CREATE_REQUEST';
export const ORDER_CREATE_SUCCESS = 'ORDER_CREATE_SUCCESS';
export const ORDER_CREATE_FAIL = 'ORDER_CREATE_FAIL';

export const ORDER_DETAILS_REQUEST = 'ORDER_DETAILS_REQUEST';
export const ORDER_DETAILS_SUCCESS = 'ORDER_DETAILS_SUCCESS';
export const ORDER_DETAILS_FAIL = 'ORDER_DETAILS_FAIL';

export const ORDER_PAY_REQUEST = 'ORDER_PAY_REQUEST';
export const ORDER_PAY_SUCCESS = 'ORDER_PAY_SUCCESS';
export const ORDER_PAY_FAIL = 'ORDER_PAY_FAIL';
export const ORDER_PAY_RESET = 'ORDER_PAY_RESET';

export const ORDER_CREATE_INVOICE_PAY_XENDIT_REQUEST =
  'ORDER_CREATE_INVOICE_PAY_XENDIT_REQUEST';
export const ORDER_CREATE_INVOICE_PAY_XENDIT_SUCCESS =
  'ORDER_CREATE_INVOICE_PAY_XENDIT_SUCCESS';
export const ORDER_CREATE_INVOICE_PAY_XENDIT_FAIL =
  'ORDER_CREATE_INVOICE_PAY_XENDIT_FAIL';
export const ORDER_CREATE_INVOICE_PAY_XENDIT_RESET =
  'ORDER_CREATE_INVOICE_PAY_XENDIT_RESET';

export const ORDER_PAY_XENDIT_REQUEST = 'ORDER_PAY_XENDIT_REQUEST';
export const ORDER_PAY_XENDIT_SUCCESS = 'ORDER_PAY_XENDIT_SUCCESS';
export const ORDER_PAY_XENDIT_FAIL = 'ORDER_PAY_XENDIT_FAIL';
export const ORDER_PAY_XENDIT_RESET = 'ORDER_PAY_XENDIT_RESET';

export const ORDER_DELIVER_REQUEST = 'ORDER_DELIVER_REQUEST';
export const ORDER_DELIVER_SUCCESS = 'ORDER_DELIVER_SUCCESS';
export const ORDER_DELIVER_FAIL = 'ORDER_DELIVER_FAIL';
export const ORDER_DELIVER_RESET = 'ORDER_DELIVER_RESET';

export const ORDER_ZEROPAY_REQUEST = 'ORDER_ZEROPAY_REQUEST';
export const ORDER_ZEROPAY_SUCCESS = 'ORDER_ZEROPAY_SUCCESS';
export const ORDER_ZEROPAY_FAIL = 'ORDER_ZEROPAY_FAIL';
export const ORDER_ZEROPAY_RESET = 'ORDER_ZEROPAY_RESET';

export const ORDER_MY_LIST_REQUEST = 'ORDER_MY_LIST_REQUEST';
export const ORDER_MY_LIST_SUCCESS = 'ORDER_MY_LIST_SUCCESS';
export const ORDER_MY_LIST_FAIL = 'ORDER_MY_LIST_FAIL';
export const ORDER_MY_LIST_RESET = 'ORDER_MY_LIST_RESET';

export const ORDER_FOR_ME_LIST_REQUEST = 'ORDER_FOR_ME_LIST_REQUEST';
export const ORDER_FOR_ME_LIST_SUCCESS = 'ORDER_FOR_ME_LIST_SUCCESS';
export const ORDER_FOR_ME_LIST_FAIL = 'ORDER_FOR_ME_LIST_FAIL';
export const ORDER_FOR_ME_LIST_RESET = 'ORDER_FOR_ME_LIST_RESET';

export const ORDER_ALL_LIST_REQUEST = 'ORDER_ALL_LIST_REQUEST';
export const ORDER_ALL_LIST_SUCCESS = 'ORDER_ALL_LIST_SUCCESS';
export const ORDER_ALL_LIST_FAIL = 'ORDER_ALL_LIST_FAIL';
