import axios from 'axios';

import {
  CART_ADD_ITEM,
  CART_REMOVE_ITEM,
  CART_REMOVE_ALL,
  CART_SAVE_PAYMENT_METHOD,
  CART_SAVE_SHIPPING_ADDRESS,
} from '../constants/cartConstants';

export const addToCart =
  (id, seller, qty, toBeServedDate, toBeServedTime, notes) =>
  async (dispatch, getState) => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/api/products/${id}`
    );

    dispatch({
      type: CART_ADD_ITEM,
      payload: {
        product: data._id,
        brand: data.brand,
        name: `${data.brand}, ${data.name}`,
        description: data.description,
        image: data.image,
        price: data.price,
        priceUnit: data.priceUnit,
        countInStock: data.countInStock,
        maxOrderQty: data.maxOrderQty,
        seller: data.user,
        qty,
        toBeServedDate,
        toBeServedTime,
        notes,
      },
    });

    localStorage.setItem(
      'cartItems',
      JSON.stringify(getState().cart.cartItems)
    );
  };

export const removeFromCart = (id) => (dispatch, getState) => {
  dispatch({
    type: CART_REMOVE_ITEM,
    payload: id,
  });

  localStorage.setItem('cartItems', JSON.stringify(getState().cart.cartItems));
};

export const removeAllFromCart = () => (dispatch, getState) => {
  dispatch({
    type: CART_REMOVE_ALL,
  });

  localStorage.setItem('cartItems', JSON.stringify(getState().cart.cartItems));
};

export const saveShippingAddress = (data) => (dispatch, getState) => {
  dispatch({
    type: CART_SAVE_SHIPPING_ADDRESS,
    payload: data,
  });

  localStorage.setItem('shippingAddress', JSON.stringify(data));
};

export const savePaymentMethod = (data) => (dispatch, getState) => {
  dispatch({
    type: CART_SAVE_PAYMENT_METHOD,
    payload: data,
  });

  localStorage.setItem('paymentMethod', JSON.stringify(data));
};
