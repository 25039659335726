import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

// import MobileMenu from './MobileMenu';
import {
  Container,
  Navbar,
  Nav,
  NavDropdown,
  Form,
  Button,
  Row,
  Col,
} from 'react-bootstrap';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { LinkContainer } from 'react-router-bootstrap';

import { logout } from '../actions/userActions';
import { removeAllFromCart } from '../actions/cartActions';
// import SearchBox from './SearchBox';

const Header = (props) => {
  const [displayName, setDisplayName] = useState('');
  const [userName, setUserName] = useState('');
  const history = useHistory();

  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  // console.log('userInfo:', userInfo);

  const [darkTheme, setDarkTheme] = useState(false);

  let userOptions;
  useEffect(() => {
    // initial state
    userOptions = JSON.parse(localStorage.getItem('userOptions'));
    if (userOptions && userOptions.darkTheme === true) {
      setDarkTheme(true); // opposite
      document.body.classList.add('dark-theme');
    } else if (userOptions && userOptions.darkTheme === false) {
      setDarkTheme(false);
      document.body.classList.remove('dark-theme');
    }
  }, []);

  const darkThemeHandler = (e) => {
    e.preventDefault();
    const userOptions = JSON.parse(localStorage.getItem('userOptions'));
    if (userOptions && userOptions.darkTheme === true) {
      setDarkTheme(false); // opposite
      document.body.classList.remove('dark-theme');
    } else if (userOptions && userOptions.darkTheme === false) {
      setDarkTheme(true);
      document.body.classList.add('dark-theme');
    } else if (!userOptions) {
      setDarkTheme(false);
    }
  };
  useEffect(() => {
    localStorage.setItem(
      'userOptions',
      JSON.stringify({ darkTheme: darkTheme })
    );
  }, [darkTheme]);

  useEffect(() => {
    if (userInfo) {
      if (userInfo.firstName) {
        setDisplayName(`${userInfo.firstName} ${userInfo.lastName}`);
      } else if (userInfo.name) {
        setDisplayName(`${userInfo.name}`);
      }
      setUserName(userInfo.userName);
    }
  }, [userInfo]);

  const logoutHandler = () => {
    dispatch(logout());
    dispatch(removeAllFromCart());
    history.push('/');
  };

  // The forwardRef is important!!
  // Dropdown needs access to the DOM node in order to position the Menu
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <span ref={ref} className='nav__user-profile'>
      <i
        className='fas fa-flag-checkered'
        style={{ position: 'absolute', top: '16px' }}
      ></i>
      {children}
    </span>
  ));

  return (
    <header>
      <Navbar collapseOnSelect bg='light' expand='lg' className='mb-3'>
        <Container>
          <LinkContainer
            to='/'
            title={`${process.env.REACT_APP_SITENAME} E${process.env.REACT_APP_SITETYPE}`}
          >
            <Navbar.Brand className='logo'>
              <svg
                fill='#ffffff'
                viewBox='0 0 219 32'
                className='svg-icon size-med st000'
              >
                <use xlinkHref='#selfie-jobs-logo' />
              </svg>
            </Navbar.Brand>
          </LinkContainer>
          <Navbar.Toggle aria-controls='offcanvasNavbar-expand-lg' />
          <Navbar.Offcanvas
            id='mobile-menu-offcanvasNavbar'
            aria-labelledby='offcanvasNavbarLabel-expand-lg'
            placement='end'
            className='mobile-menu'
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title id='offcanvasNavbarLabel-expand-lg'>
                <LinkContainer to='/'>
                  <Nav.Link>
                    <img
                      src={`/assets/img/selfie-logo-txt.svg`}
                      style={{ height: '24px' }}
                      alt='Selfie Jobs Network'
                    />
                  </Nav.Link>
                </LinkContainer>
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav className='justify-content-end flex-grow-1'>
                {/* <Nav.Item as='li'>
                <LinkContainer to='/cart'>
                  <Nav.Link>
                    <i className='fas fa-shopping-basket'></i> Cart
                  </Nav.Link>
                </LinkContainer>
              </Nav.Item> */}

                <Nav.Item as='li'>
                  <LinkContainer to='/maps/'>
                    <Nav.Link>
                      <i className='fas fa-map-marker-alt'></i> Maps
                    </Nav.Link>
                  </LinkContainer>
                </Nav.Item>
                <Nav.Item as='li'>
                  {userInfo ? (
                    <>
                      <NavDropdown
                        as={CustomToggle}
                        title={`${displayName}`}
                        id='username'
                        popperConfig='static'
                        renderMenuOnMount='true'
                      >
                        <LinkContainer to='/my-products'>
                          <NavDropdown.Item>
                            <i className='fas fa-award'></i>
                            &nbsp;&nbsp; My Products
                          </NavDropdown.Item>
                        </LinkContainer>
                        <LinkContainer to='/orders-for-me'>
                          <NavDropdown.Item>
                            <i className='fas fa-gift'></i>
                            &nbsp;&nbsp; Orders For Me
                          </NavDropdown.Item>
                        </LinkContainer>
                        <NavDropdown.Divider />

                        <LinkContainer to='/buy'>
                          <NavDropdown.Item>
                            <i className='fas fa-shopping-bag'></i>
                            &nbsp;&nbsp; Items in My Cart
                          </NavDropdown.Item>
                        </LinkContainer>
                        <LinkContainer to='/my-orders'>
                          <NavDropdown.Item>
                            <i className='fas fa-address-book'></i>
                            &nbsp;&nbsp; My Orders
                          </NavDropdown.Item>
                        </LinkContainer>
                        <LinkContainer to={`/user/${userName}`}>
                          <NavDropdown.Item>
                            <i className='fas fa-user-alt'></i>
                            &nbsp;&nbsp; My Profile
                          </NavDropdown.Item>
                        </LinkContainer>
                        <LinkContainer to='/my-profile'>
                          <NavDropdown.Item>
                            <i className='fas fa-user-alt'></i>
                            &nbsp;&nbsp; Update Profile
                          </NavDropdown.Item>
                        </LinkContainer>

                        {/*<LinkContainer to='/profile'>
                          <NavDropdown.Item>
                            <i className='fas fa-user-circle'></i>&nbsp;&nbsp;
                            Profile
                          </NavDropdown.Item>
                  </LinkContainer>*/}

                        <NavDropdown.Item onClick={logoutHandler}>
                          <i className='fas fa-door-open'></i>&nbsp;&nbsp;
                          Logout
                        </NavDropdown.Item>
                      </NavDropdown>
                    </>
                  ) : (
                    <>
                      <NavDropdown
                        as={CustomToggle}
                        title='Masuk / Daftar'
                        id='loginsignup'
                        popperConfig='static'
                        renderMenuOnMount='true'
                        // show='true'
                      >
                        <LinkContainer to='/login'>
                          <NavDropdown.Item>
                            <i className='fas fa-user-check'></i>&nbsp;&nbsp;
                            Masuk
                          </NavDropdown.Item>
                        </LinkContainer>
                        <LinkContainer to='/signup'>
                          <NavDropdown.Item>
                            <i className='fas fa-user-plus'></i>&nbsp;&nbsp;
                            Daftar
                          </NavDropdown.Item>
                        </LinkContainer>
                        {/* <LinkContainer to='/dark' onClick={darkThemeHandler}>
                          <NavDropdown.Item>
                            {darkTheme ? (
                              <>
                                <i className='fas fa-cloud-moon'></i> Dark Mode
                              </>
                            ) : (
                              <>
                                <i className='fas fa-cloud-moon'></i>{' '}
                                <s>Dark Mode</s>
                              </>
                            )}
                          </NavDropdown.Item>
                        </LinkContainer> */}
                      </NavDropdown>
                    </>
                  )}
                </Nav.Item>

                <Nav.Item as='li'>
                  <LinkContainer to='/about/'>
                    <Nav.Link>
                      <i className='fas fa-compress'></i> About
                    </Nav.Link>
                  </LinkContainer>
                </Nav.Item>

                <Nav.Item as='li'>
                  <a href='https://selfie.id/loker/' className='nav-link'>
                    <i className='fa fa-hashtag'></i> Jobs Blog
                  </a>
                </Nav.Item>

                {userInfo && userInfo.isAdmin && (
                  <Nav.Item as='li'>
                    <NavDropdown
                      as={CustomToggle}
                      title='Administration'
                      id='useradmin'
                      popperConfig='static'
                      renderOnMount='true'
                      // show='true'
                    >
                      <LinkContainer to='/admin/users-list'>
                        <NavDropdown.Item>
                          <i className='fas fa-users'></i>&nbsp;&nbsp; Users
                          List
                        </NavDropdown.Item>
                      </LinkContainer>
                      {/* <NavDropdown.Divider /> */}
                      <LinkContainer to='/admin/products-list'>
                        <NavDropdown.Item>
                          <i className='fas fa-grip-lines'></i>&nbsp;&nbsp;
                          Products List
                        </NavDropdown.Item>
                      </LinkContainer>
                      {/* <NavDropdown.Divider /> */}
                      <LinkContainer to='/admin/orders-list'>
                        <NavDropdown.Item>
                          <i className='fas fa-file-alt'></i>&nbsp;&nbsp; Orders
                          List
                        </NavDropdown.Item>
                      </LinkContainer>
                    </NavDropdown>
                  </Nav.Item>
                )}

                {/*
                <Nav.Link href='#action1'>Home</Nav.Link>
                <Nav.Link href='#action2'>Link</Nav.Link>
                <NavDropdown
                  title='Dropdown'
                  id='offcanvasNavbarDropdown-expand-lg'
                >
                  <NavDropdown.Item href='#action3'>Action</NavDropdown.Item>
                  <NavDropdown.Item href='#action4'>
                    Another action
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item href='#action5'>
                    Something else here
                  </NavDropdown.Item>
              </NavDropdown>*/}
              </Nav>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
    </header>
  );
};

export default Header;
