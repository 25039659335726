import { BrowserRouter as Router, Route } from 'react-router-dom';
import { Container } from 'react-bootstrap';

import Header from './components/Header';
import Footer from './components/Footer';
import HomePage from './pages/HomePage';
import ProductPage from './pages/ProductPage';
import CartPage from './pages/CartPage';
import BuyPage from './pages/BuyPage';
import LoginPage from './pages/LoginPage';
import SignupPage from './pages/SignupPage';
import ProfilePage from './pages/ProfilePage';
import MyOrdersPage from './pages/MyOrdersPage';
import ShippingPage from './pages/ShippingPage';
import PaymentSelectPage from './pages/PaymentSelectPage';
import PlaceOrderPage from './pages/PlaceOrderPage';
import OrderPage from './pages/OrderPage';
import UsersListPage from './pages/UsersListPage';
import UserEditPage from './pages/UserEditPage';
import ProductsListPage from './pages/ProductsListPage';
import ProductEditPage from './pages/ProductEditPage';
import OrdersListPage from './pages/OrdersListPage';
import MyProductsPage from './pages/MyProducts';
import MyProfilePage from './pages/MyProfilePage';
import OrdersForMePage from './pages/OrdersForMePage';
import PaymentSuccessPage from './pages/PaymentSuccessPage';
import MapsGooglePage from './pages/MapsGooglePage';
import PaymentFailedPage from './pages/PaymentFailedPage';
import AboutPage from './pages/AboutPage';
import UserPage from './pages/UserPage';
// import OffCanvasMenu from './components/OffCanvasMenu';
// import ProductsTopRatedCarousel from './components/ProductsTopRatedCarousel';

const App = (props) => {
  return (
    <Router>
      {/* <OffCanvasMenu placement='end' name='end' /> */}
      <Header />
      <main>
        {/* {window.location.pathname === '/' && <ProductsTopRatedCarousel />} */}
        <Container>
          {/* {this.state.token && (
                  <Redirect
                    from={'/login'}
                    to={'/'}
                    exact
                  />
                )} */}
          <Route path='/login' component={LoginPage} exact />
          <Route path='/signup' component={SignupPage} exact />
          <Route path='/profile' component={ProfilePage} exact />
          <Route path='/my-profile' component={MyProfilePage} exact />
          <Route path='/my-orders' component={MyOrdersPage} exact />
          <Route path='/my-products' component={MyProductsPage} exact />
          <Route path='/orders-for-me' component={OrdersForMePage} exact />
          <Route path='/products/:id' component={ProductPage} exact />
          {/* <Route path='/cart/:id?' component={CartPage} exact /> */}
          <Route path='/buy/:id?' component={BuyPage} exact />
          <Route path='/shipping' component={ShippingPage} exact />
          <Route path='/payment' component={PaymentSelectPage} exact />
          <Route path='/placeorder' component={PlaceOrderPage} exact />
          <Route path='/order/:id' component={OrderPage} exact />
          <Route
            path='/payment/xendit/success/:id'
            component={PaymentSuccessPage}
            exact
          />
          <Route
            path='/payment/xendit/failed/:id'
            component={PaymentFailedPage}
            exact
          />
          <Route path='/admin/orders-list' component={OrdersListPage} exact />
          <Route path='/admin/users-list' component={UsersListPage} exact />
          <Route path='/admin/user/:id/edit' component={UserEditPage} exact />
          <Route
            path='/admin/product/:id/edit'
            component={ProductEditPage}
            exact
          />
          <Route
            path='/admin/products-list'
            component={ProductsListPage}
            exact
          />
          <Route
            path='/admin/products-list/page/:pageNumber'
            component={ProductsListPage}
            exact
          />
          <Route path='/search/:keyword' component={HomePage} exact />
          <Route
            path='/search/:keyword/page/:pageNumber'
            component={HomePage}
            exact
          />
          <Route path='/page/:pageNumber' component={HomePage} exact />
          <Route path='/maps' component={MapsGooglePage} exact />
          <Route path='/about' component={AboutPage} exact />
          <Route path='/user/:userName' component={UserPage} exact />
          {/* <Route path='/create' component={HomePage} exact /> */}
          <Route path='/' component={HomePage} exact />
        </Container>
      </main>
      <Footer match={props.match} />
    </Router>
  );
};

export default App;
