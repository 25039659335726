import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga';

function PaymentFailedPage({ history, match, location, ...props }) {
  ReactGA.initialize(process.env.REACT_APP_GUA);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <div>
      <h2>Maaf, Pembayaran Gagal.</h2>
      <p>
        Pembayaran order no <strong>{match.params.id.toUpperCase()}</strong>{' '}
        tidak berhasil.
      </p>
      <p>
        Lihat <Link to={`/order/${match.params.id}`}>pesanan Anda</Link>.
      </p>
    </div>
  );
}

export default PaymentFailedPage;
