import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useHistory } from 'react-router-dom';
import { Table, Form, Button, Row, Col } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Message from '../components/Message';
import Loading from '../components/Loading';
import { getUserProfile } from '../actions/userActions';
import { USER_PROFILE_UPDATE_RESET } from '../constants/userConstant';
import { listOrdersForMe } from '../actions/orderActions';
import { formatSeparator } from '../shared/functions';

const OrdersForMePage = ({ location, match }) => {
  const [name, setName] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [birthday, setBirthday] = useState('');
  const [sex, setSex] = useState('');
  const [bio, setBio] = useState('');
  const [image, setImage] = useState('');
  const [message, setMessage] = useState(null);

  const dispatch = useDispatch();

  const pageNumber = match.params.pageNumber || 1;

  const userProfile = useSelector((state) => state.userProfile);
  const { loading, error, user } = userProfile;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const orderForMe = useSelector((state) => state.ordersForMe);
  const { loading: loadingOrders, error: errorOrders, orders } = orderForMe; // rename needed to differentiate

  const history = useHistory();
  const redirect = location.search ? location.search.split('=')[1] : '/';

  useEffect(() => {
    if (!userInfo) {
      history.push('/login');
    }
  }, [dispatch, userInfo, history, pageNumber]);

  useEffect(() => {
    if (!userInfo) {
      history.push('/login');
    } else {
      if (!user || !user.name) {
        dispatch({ type: USER_PROFILE_UPDATE_RESET });
        dispatch(getUserProfile('profile'));

        // setOrdersList(orders);
      }
      // console.log('products:', products);
    }
  }, [dispatch, history, userInfo, user, redirect]);

  useEffect(() => {
    dispatch(listOrdersForMe());
  }, [dispatch, history, userInfo, user, redirect]);

  return (
    <Row className='user-profile'>
      <Col xs={12}>
        <h1 className='mt-3 mb-5'>Orders For Me</h1>
      </Col>
      <Col xs={12}>
        <div className='mx-3 my-3'>
          <h2 className='mb-3'>Daftar Pesanan Yang Saya Terima</h2>
          {loadingOrders ? (
            <Loading />
          ) : errorOrders ? (
            <Message variant='danger'>{errorOrders}</Message>
          ) : (
            <Table striped bordered hover responsive className='table-sm'>
              <thead>
                <tr>
                  <th>Tanggal Order (#ID)</th>
                  <th>Untuk Kapan?</th>
                  <th>Rincian Pesanan</th>
                  <th>Sudah Bayar?</th>
                  <th>Customer Detail</th>
                  <th>Account</th>
                  <th>Notes</th>
                  <th>Completed?</th>
                  {/* <th></th> */}
                </tr>
              </thead>
              <tbody>
                {orders.length > 0 ? (
                  orders.map((order) => (
                    <tr key={order._id}>
                      <td>
                        <strong>
                          {order.createdAt
                            ? order.createdAt.substring(0, 10)
                            : ''}
                        </strong>
                        <br />
                        (Order #{' '}
                        <span className='break-all txt-underlined'>
                          {order._id}
                        </span>
                        )
                      </td>

                      <td>
                        {order.orderItems.map((item) =>
                          item.seller === user._id ? (
                            <div>
                              {item.toBeServedDate.substring(0, 10)} <br />/{' '}
                              {item.toBeServedTime}
                            </div>
                          ) : (
                            ''
                          )
                        )}
                      </td>
                      <td>
                        {order.orderItems.map((item) =>
                          item.seller === user._id ? (
                            <>
                              <h5>
                                <Link
                                  className='no-underline'
                                  to={`/products/${item.product}`}
                                >
                                  {item.name} [{item.product}]
                                </Link>
                              </h5>
                              Qty: {item.qty} @ Rp
                              {formatSeparator(item.price)}/{item.priceUnit}
                              <br />
                              Total Rp
                              {formatSeparator(
                                parseInt(item.qty) * parseInt(item.price)
                              )}
                            </>
                          ) : (
                            ''
                          )
                        )}
                      </td>
                      <td>
                        {order.isPaid ? (
                          <>
                            <i
                              className='fas fa-check'
                              style={{ color: 'green' }}
                            ></i>
                            <br />(
                            {!order.paidAt
                              ? 'Date/time unknown'
                              : order.paidAt.substring(0, 10)}
                            )
                          </>
                        ) : (
                          <i
                            className='fas fa-times'
                            style={{ color: 'red' }}
                          ></i>
                        )}
                      </td>
                      <td>
                        {Object.values(order.shippingAddress).map(
                          (value, index) => (
                            <div className='item' key={index}>
                              {value}
                            </div>
                          )
                        )}
                      </td>
                      <td>
                        {order.user.firstName} {order.user.lastName}
                        <br />({order.user.userName})
                      </td>
                      <td>
                        {order.orderItems.map((item) =>
                          item.seller === user._id ? (
                            <div>Notes: {item.notes}</div>
                          ) : (
                            ''
                          )
                        )}
                      </td>
                      <td style={{ textAlign: 'center' }}>
                        {order.isFulfilled ? (
                          order.fulfilledAt.substring(0, 10)
                        ) : (
                          <i
                            className='fas fa-hourglass-start'
                            style={{ color: 'grey', fontSize: 'small' }}
                          ></i>
                        )}
                      </td>
                      {/* <td>
                        <LinkContainer to={`/order/${order._id}`}>
                          <Button className='btn' variant='light'>
                            <i className='fa fa-eye'></i>
                          </Button>
                        </LinkContainer>
                      </td> */}
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={8}>Belum ada</td>
                  </tr>
                )}
              </tbody>
            </Table>
          )}
        </div>
      </Col>
    </Row>
  );
};

export default OrdersForMePage;
