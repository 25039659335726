import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
// import ReactGA from 'react-ga';

import { createProduct } from '../actions/productActions';
import { PRODUCT_CREATE_RESET } from '../constants/productConstants';
import Loading from './Loading';
import Message from './Message';

function AddNewProductButton({ userInfo, ...props }) {
  const history = useHistory();
  const dispatch = useDispatch();

  const productCreate = useSelector((state) => state.productCreate);

  const {
    loading: loadingCreate,
    error: errorCreate,
    success: successCreate,
    product: createdProduct,
  } = productCreate;

  const addProductHandler = () => {
    console.log('Create new product..');

    if (!userInfo) {
      history.push('/login?redirect=/');
    } else if (userInfo) {
      console.log('userInfo:', userInfo);
      dispatch(createProduct());
    }
  };

  useEffect(() => {
    dispatch({ type: PRODUCT_CREATE_RESET });
  }, []);

  useEffect(() => {
    if (successCreate) {
      history.push(`/admin/product/${createdProduct._id}/edit`);
    }
  }, [history, successCreate, createdProduct]);

  // const user = userInfo;
  /*
  useEffect(() => {
    if (!userInfo) {
      history.push('/login');
    } else if (userInfo) {
      console.log('userInfo:', userInfo);
    }
  }, [userInfo, history, dispatch]); */
  // Bikin error, v=create berulang
  // useEffect(() => {
  //   if (props.createProduct === true && userInfo) {
  //     // addProductHandler();
  //   }
  // }, []);

  return (
    <div>
      {loadingCreate && <Loading />}
      {errorCreate && <Message variant='danger'>{errorCreate}</Message>}
      <Button
        className='btn-transparent add-product-btn btn-green mb-3'
        onClick={() => {
          addProductHandler();
          window.ga(
            'send',
            'event',
            'Interact',
            'Contribute',
            'Create New Product @ Front Page'
          );
        }}
      >
        <i className='fas fa-plus' style={{ fontSize: '1.5rem' }}></i> &nbsp;
        Add Your Product
      </Button>
    </div>
  );
}

export default AddNewProductButton;
