import React, { useState } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Collapse from 'react-bootstrap/Collapse';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Accordion from 'react-bootstrap/Accordion';
import { Link } from 'react-router-dom';

function HowItWorks({ name, ...props }) {
  const [show, setShow] = useState(false);
  const [showFaqs, setShowFaqs] = useState(false);
  // for collapse element
  const [open, setOpen] = useState(true);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleCloseFaqs = () => setShowFaqs(false);
  const handleShowFaqs = () => setShowFaqs(true);
  return (
    <>
      <div className='center-horizontal mt-4'>
        <Button
          className='btn-transparent text-center intro mb-2'
          style={{ color: '#139948' }}
          onClick={() => setOpen(!open)}
          aria-controls='intro-what-text'
          aria-expanded={open}
        >
          <h2
            className='text-center'
            style={{ cursor: 'pointer', color: 'inherit', fontWeight: '300' }}
          >
            Apa Itu Selfie.ID?
          </h2>
        </Button>
      </div>
      <Collapse in={open}>
        <div id='intro-what-text' className='mb-4'>
          <p
            className='text-center'
            style={{
              textAlign: 'center',
              color: '#787878',
              width: '320px',
              maxWidth: '100%',
              margin: '0 auto',
            }}
          >
            {/* Selfie.ID (Selfie Jobs Network) adalah platform yg membantu pencari
            kerja lepas untuk membuat income, dengan menjual produk berisi
            layanan unik, spesifik &amp; personal yg siap dibeli konsumen yg
            membutuhkan... */}
            Selfie.ID (Selfie Jobs Network) adalah platform yg membantu pekerja
            lepas membuat income dari keterampilan, skill, hobby & kepribadian
            yang sudah dimiliki masing-masing individu.
          </p>
        </div>
      </Collapse>

      <h2
        onClick={handleShow}
        className='text-center'
        style={{ cursor: 'pointer', color: '#a8a8a8', fontWeight: '300' }}
      >
        How It Works?
      </h2>
      <h2
        onClick={handleShowFaqs}
        className='text-center mt-2'
        style={{ cursor: 'pointer', color: '#a8a8a8', fontWeight: '300' }}
      >
        F.A.Q.s
      </h2>

      <Offcanvas
        show={show}
        className='how-it-works-cont'
        onHide={handleClose}
        {...props}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <h2 className='text-center mt-2' style={{ color: '#139948' }}>
              How Does It Work?
            </h2>
            <h3
              className='text-center'
              style={{
                textTransform: 'none',
                fontWeight: '300',
                color: '#080808',
                padding: '0',
              }}
            >
              Bagaimana Cara Kerjanya? <br />
              Ikuti langkah-langkah berikut ini:
            </h3>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Container>
            <Row>
              <Col></Col>
            </Row>
            <Row>
              <Col xs={12} sm={3} md={2}></Col>
              <Col xs={12} sm={6} md={8} className='how-it-works round'>
                <ol>
                  <li>
                    <img
                      alt='Daftar'
                      src='/assets/img/how-it-works/thumb_1_Sign_Up.png'
                      width={200}
                      style={{ marginTop: '1rem' }}
                    />
                    <Link to='/signup'>Daftar / Signup</Link> atau{' '}
                    <Link to='/login'>Login</Link> Jika Sudah Punya Account
                  </li>
                  <li>
                    <p>
                      <img
                        alt='Daftar'
                        src='/assets/img/how-it-works/thumb_2_Create_Product_Services.png'
                        width={200}
                      />
                      Buat Produk Berisikan Layanan Yang Kamu Bisa Sediakan,
                      dengan Deskripsi dan Perincian yang Sangat Spesifik &
                      Jelas.
                    </p>
                    <p>
                      Jangan Lupa Beri Harga Yang Pantas Untuk Produk Layanan
                      Yang Kamu Tawarkan Tadi.
                    </p>
                    <p>
                      Pembeli yang Membutuhkan Produk Layananmu akan Membayar
                      Senilai Harga* yang Tertera pada Produkmu.
                    </p>
                  </li>
                  <li>
                    <img
                      alt='Daftar'
                      src='/assets/img/how-it-works/thumb_3_Tunggu_Pesanan.png'
                      width={200}
                    />
                    Tunggu Pesanan Dari Pembeli yang Membutuhkan Produk /
                    Layananmu. Atau Bahkan Lebih Cepat Lagi Sebarkan di Sosial
                    Media Kamu Produk Layanan Siap Beli Kamu.
                  </li>
                  <li>
                    <img
                      alt='Daftar'
                      src='/assets/img/how-it-works/thumb_4_Selesaikan_Order.png'
                      width={200}
                    />
                    Kerjakan Order Pesanan Sesuai Deskripsi Produk / Layanan
                    Yang Kamu Janjikan
                  </li>
                  <li>
                    <img
                      alt='Daftar'
                      src='/assets/img/how-it-works/thumb_5_Terima_Bayaran.png'
                      width={200}
                    />
                    Terima Pembayaran Atas Penjualan Produkmu
                  </li>
                </ol>
                <h3 className='mt-5'>
                  Ayo Daftar Sekarang! <Link to='/signup'>Di sini</Link>
                </h3>
              </Col>
              <Col xs={12} sm={3} md={2}></Col>
            </Row>
          </Container>
        </Offcanvas.Body>
      </Offcanvas>
      <Offcanvas
        show={showFaqs}
        className='how-it-works-cont faqs-cont'
        onHide={handleCloseFaqs}
        {...props}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <h2
              className='text-center mt-2'
              style={{ textTransform: 'none', color: '#139948' }}
            >
              FAQs
            </h2>
            <h3
              className='text-center'
              style={{
                textTransform: 'none',
                fontWeight: '300',
                color: '#080808',
                padding: '0',
              }}
            >
              -Frequently Asked Questions-
              <br />
              Hal-hal Yang Paling Sering Ditanyakan
            </h3>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Container>
            <Row>
              <Col></Col>
            </Row>
            <Row>
              <Col xs={12} sm={1} md={1}></Col>
              <Col xs={12} sm={10} md={10} className='how-it-works round'>
                <Accordion className='faqs'>
                  <Accordion.Item eventKey='7'>
                    <Accordion.Header>
                      <h3 className='faqs__title'>
                        1. Apa Itu Selfie Jobs Network? dan Jelaskan apa itu
                        &quot;Ekonomi Gig&quot;?
                      </h3>
                    </Accordion.Header>
                    <Accordion.Body>
                      <p>
                        <u style={{ fontWeight: '600' }}>
                          Selfie.ID (Selfie Jobs Network)
                        </u>{' '}
                        adalah platform yang membantu pencari kerja lepas untuk
                        mendapatkan income dengan menjual produk berisi layanan
                        unik spesifik yang siap dibeli.
                      </p>
                      <p>
                        Di platform Selfie.ID setiap user yang telah mendaftar
                        dapat menjadi penjual atau pembeli produk / layanan.
                      </p>
                      <p>
                        <u style={{ fontWeight: '600' }}>
                          Ekonomi Gig (Gig Economy)
                        </u>{' '}
                        adalah model ekonomi di mana semakin banyak orang
                        memilih bekerja sebagai pekerja lepas, freelancer atau
                        kontraktor yang bebas, dari pada sebagai karyawan tetap
                        di suatu perusahaan formal dengan jangka waktu kontrak
                        yang mengikat.
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey='0'>
                    <Accordion.Header>
                      <h3 className='faqs__title'>
                        2. Apa Syarat untuk Gabung di Selfie Jobs Network?
                      </h3>
                    </Accordion.Header>
                    <Accordion.Body>
                      <p>
                        Cukup dengan mendaftar dan mengisi data dengan lengkap
                        agar mudah dihubungi pembeli.
                      </p>
                      <p>
                        Lalu, setiap user akan perlu untuk memverifikasi
                        identitas bukti diri, dengan dokumen resmi seperti KTP
                        atau passport. Setiap user yang telah terverifikasi akan
                        ada centang biru (terverifikasi / verified) pada profil
                        nya
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey='1'>
                    <Accordion.Header>
                      <h3 className='faqs__title'>
                        3. Aku Sudah Daftar Lengkap, Gimana Langkah Selanjutnya?
                      </h3>
                    </Accordion.Header>
                    <Accordion.Body>
                      <ol style={{ marginLeft: '3rem' }}>
                        <li>
                          <p>
                            Kamu bisa lanjut dengan buat / bikin / ciptakan
                            produk berisi layanan spesifik yg kamu mau tawarkan
                          </p>
                        </li>
                        <li>
                          <p>
                            Tentukan juga berapa harga atau &quot;rate&quot;
                            bagi khayalak yang berminat membeli dan perinci
                            dalam satuan apa.
                            <br />
                            Apakah per pax, per jam, per hari, per bulan, per
                            paket, per sesi atau lainnya
                          </p>
                        </li>
                        <li>
                          {' '}
                          <p>
                            Bagikan di media sosial kamu lewat link share{' '}
                            <svg
                              style={{ width: '16px', margin: '0 0.5rem' }}
                              className='icon icon-svg'
                              fill='#787878'
                              viewBox='0 0 16 18'
                            >
                              <use xlinkHref='#icon-svg-share'></use>
                            </svg>{' '}
                            yang telah disediakan pada masing-masing laman
                            produk.
                          </p>
                        </li>
                      </ol>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey='2'>
                    <Accordion.Header>
                      <h3 className='faqs__title'>
                        4. Produk / Layanan Apa yang Bisa Aku Tawarkan?
                      </h3>
                    </Accordion.Header>
                    <Accordion.Body>
                      <p>
                        Hampir Tidak Ada Batasan. Segala sesuatu yang legal
                        menurut hukum Republik Indonesia.
                      </p>
                      <p>
                        Gunakan imajinasimu, hobby, skill & ketrampilan apa yang
                        sudah kamu kuasai dan akan dengan mudah kerjakan.{' '}
                      </p>
                      <p>
                        Terutama segala sesuatu hal yang orang lain akan
                        butuhkan dan akan bersedia untuk membayar agar
                        kebutuhannya terpenuhi.
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey='3'>
                    <Accordion.Header>
                      <h3 className='faqs__title'>
                        5. Terus Bagaimana Nanti Sistem Kerjanya?
                      </h3>
                    </Accordion.Header>
                    <Accordion.Body>
                      <ol type='i' style={{ marginLeft: '3rem' }}>
                        <li>
                          <p>
                            Mula-mula pembeli yang butuh produk pelayanan kamu
                            mengkonfirmasi pemesanan, dengan memesan & membayar
                            secara online melalui kanal pembayaran yang
                            Selfie.ID sediakan. Sesuai dengan harga rate tertera
                            + pajak negara + fee platform.
                          </p>
                        </li>
                        <li>
                          <p>
                            Penjual mendapatkan pemberitahuan bahwa ada order
                            terkonfirmasi masuk.
                          </p>
                        </li>
                        <li>
                          <p>
                            Penjual menyelesaikan order yang masuk sesuai
                            tanggal & waktu sesuai pesanan.
                          </p>
                        </li>
                        <li>
                          <p>
                            Setelah order terkonfirmasi selesai, pembayaran akan
                            diteruskan platform Selfie.ID ke penjual.
                          </p>
                        </li>
                      </ol>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey='4'>
                    <Accordion.Header>
                      <h3 className='faqs__title'>
                        6. Bisakah melakukan pemesanan / penjualan dengan COD?
                      </h3>
                    </Accordion.Header>
                    <Accordion.Body>
                      <p>
                        Untuk saat ini tidak dikenal proses COD pada platform
                        Selfie.ID.
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey='8'>
                    <Accordion.Header>
                      <h3 className='faqs__title'>
                        7. Bisakah menjual barang fisik di platform Selfie?
                      </h3>
                    </Accordion.Header>
                    <Accordion.Body>
                      <p>
                        Singkatnya Bisa. <br />
                        Misalnya: hampers, makanan spesial, kue kering, koleksi
                        coin kuno, dll.
                      </p>
                      <p>
                        Satu hal yang perlu perhatian, ongkos kirim ke pembeli
                        mesti sudah dihitung / di-antisipasi (include) di depan.
                      </p>
                      <p>
                        Sebab pembelian setelah konfirmasi pembayaran adalah
                        final, yaitu tidak ada lagi pembayaran tambahan.
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey='5'>
                    <Accordion.Header>
                      <h3 className='faqs__title'>
                        8. Apa Syarat Pembayaran yang Sah?
                      </h3>
                    </Accordion.Header>
                    <Accordion.Body>
                      <ol type='a' style={{ marginLeft: '3rem' }}>
                        <li>
                          <p>
                            Pembeli wajib menyelesaikan pembayaran secara online
                            di situs web Selfie.ID (https://selfie.id) agar
                            pesanan / order terkonfirmasi.
                          </p>
                        </li>
                        <li>
                          <p>
                            Pembayaran hanya bisa dilakukan pembeli secara
                            online di platform Selfie.ID (https://selfie.id),
                            melalui online payment via payment processor{' '}
                            <u>
                              <strong>Xendit</strong>
                            </u>{' '}
                            yang telah disediakan.
                          </p>
                        </li>
                        <li>
                          <p>
                            Yaitu wajib masuk ke rekening bank atas nama account
                            perusahaan{' '}
                            <u>
                              <strong>PT. Super Technology Indonesia</strong>
                            </u>{' '}
                            di rekening giro perseroan pada{' '}
                            <u>
                              <strong>Bank BCA # 437-4093-888</strong>
                            </u>
                            .
                          </p>
                        </li>
                        <li>
                          <p>
                            Penjual hanya akan mengerjakan pesanan yang sudah
                            berstatus <u>terkonfirmasi</u> oleh platform
                            Selfie.ID.
                          </p>
                        </li>
                        <li>
                          <p>
                            Pembayaran akan diteruskan ke penjual hanya jika
                            order telah diselesaikan. Jadi platform Selfie.ID
                            bertindak sebagai pihak escrow (penengah).
                          </p>
                        </li>
                      </ol>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey='9'>
                    <Accordion.Header>
                      <h3 className='faqs__title'>
                        9. Kapan Saya Sebagai Penjual Menerima Pembayaran?
                      </h3>
                    </Accordion.Header>
                    <Accordion.Body>
                      <p>Setelah order dikonfirmasi selesai.</p>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>

                <h4 className='mt-5 no-upcase'>
                  <Link to='/signup' className='text-thin'>
                    Belum Daftar? Click Di Sini.
                  </Link>
                </h4>
              </Col>
              <Col xs={12} sm={1} md={1}></Col>
            </Row>
          </Container>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default HowItWorks;
