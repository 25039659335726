import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Form, Button, Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import ReactGA from 'react-ga';

import Meta from '../components/Meta';
import Message from '../components/Message';
import Loading from '../components/Loading';
import FormContainer from '../components/FormContainer';
import { loginUser } from '../actions/userActions';
import CheckoutSteps from '../components/CheckoutSteps';
import InputPassword from '../components/InputPassword';

const LoginPage = (props) => {
  ReactGA.initialize(process.env.REACT_APP_GUA);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);

  const { loading, error, userInfo } = userLogin;

  const history = useHistory();
  const redirect = props ? props.location.search.split('=')[1] : '/';

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
    // console.log('userInfo: ', userInfo);
    // console.log('redirect:', redirect);
    // console.log('location.location.search: ', location.location.search);
    if (userInfo && redirect) {
      history.push(redirect);
    } else if (userInfo && !redirect) {
      history.push('/my-products');
    }
  }, [history, userInfo, redirect]);

  const submitHandler = (e) => {
    e.preventDefault();
    // console.log(email, password);
    dispatch(loginUser(email, password));
  };

  return (
    <FormContainer>
      <Meta title={`Login`} />
      {redirect === 'shipping' ? <CheckoutSteps step1 /> : ''}
      <h1>Masuk / Sign In</h1>
      {error && <Message variant='danger'>{error}</Message>}
      {loading && <Loading />}
      <Form onSubmit={submitHandler}>
        <Form.Group controlId='email'>
          <Form.Label>Email Address</Form.Label>
          <Form.Control
            type='email'
            placeholder='Enter email'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            autoComplete='email'
          ></Form.Control>
        </Form.Group>
        <Form.Group>
          {/*
          <Form.Label>Password</Form.Label>
          <Form.Control
            type='password'
            placeholder='Enter password'
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            autoComplete='password'
  ></Form.Control>*/}
          <InputPassword
            id='password'
            label='Password*'
            autoComplete='current-password'
            className='password'
            placeholder='Masukkan password'
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </Form.Group>
        <Form.Group controlId='buttonLogin'>
          <Button type='submit' variant='primary' className='my-3'>
            Sign In
          </Button>
        </Form.Group>
      </Form>
      <Row className='py-3'>
        <Col>
          Pengguna Baru? &nbsp;
          <Link to={redirect ? `/signup?redirect=${redirect}` : '/signup'}>
            Daftar
          </Link>
        </Col>
      </Row>
    </FormContainer>
  );
};

export default LoginPage;
