import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import ReactGA from 'react-ga';

import { Table, Button, Row, Col } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Message from '../components/Message';
import Loading from '../components/Loading';
import { getUserProfile, updateUserProfile } from '../actions/userActions';
import { USER_PROFILE_UPDATE_RESET } from '../constants/userConstant';
import { listMyProducts, createProduct } from '../actions/productActions';
import { PRODUCT_CREATE_RESET } from '../constants/productConstants';

const MyProductsPage = ({ location, match }) => {
  ReactGA.initialize(process.env.REACT_APP_GUA);
  const [name, setName] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [birthday, setBirthday] = useState('');
  const [sex, setSex] = useState('');
  const [bio, setBio] = useState('');
  const [image, setImage] = useState('');
  const [message, setMessage] = useState(null);

  const dispatch = useDispatch();

  const pageNumber = match.params.pageNumber || 1;

  const userProfile = useSelector((state) => state.userProfile);
  const { loading, error, user } = userProfile;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const productsListMy = useSelector((state) => state.productsListMy);
  const {
    loading: loadingProducts,
    error: errorProducts,
    products,
    page,
    pages,
    keyword,
  } = productsListMy;

  const productCreate = useSelector((state) => state.productCreate);
  const {
    loading: loadingCreate,
    error: errorCreate,
    success: successCreate,
    product: createdProduct,
  } = productCreate;

  const createProductHandler = (product) => {
    dispatch(createProduct());
  };

  const history = useHistory();
  const redirect = location.search ? location.search.split('=')[1] : '/';

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  useEffect(() => {
    dispatch({ type: PRODUCT_CREATE_RESET });
    dispatch({ type: USER_PROFILE_UPDATE_RESET });

    if (!userInfo) {
      history.push('/login');
      // dispatch(listProducts());
    }
    if (successCreate) {
      history.push(`/admin/product/${createdProduct._id}/edit`);
    }
    // else {
    //   dispatch(listProducts('', pageNumber)); // keyword set to empty ''
    // }
  }, [
    dispatch,
    userInfo,
    history,
    // successDelete,
    successCreate,
    createdProduct,
    pageNumber,
  ]);

  useEffect(() => {
    if (!userInfo) {
      history.push('/login');
    } else {
      if (!user || !user.name) {
        dispatch({ type: USER_PROFILE_UPDATE_RESET });
        dispatch(getUserProfile('profile'));

        // setOrdersList(orders);
      }
    }
  }, [dispatch, history, userInfo, user, redirect]);

  useEffect(() => {
    dispatch(listMyProducts());
  }, [dispatch, history, userInfo, user, redirect]);

  const deleteHandler = (product) => {
    if (
      window.confirm(
        `Anda yakin akan menghapus produk "${product.name}" dengan id # ${product._id}?`
      )
    ) {
      // dispatch(deleteProduct(product._id));
    }
  };

  return (
    <Row className='user-profile'>
      <Col xs={12}>
        <h1 className='mt-3 mb-5'>My Products</h1>
      </Col>
      <Col xs={12}>
        <div className='mx-3 my-3'>
          <Button
            className='my-3'
            style={{ float: 'right' }}
            onClick={() => {
              createProductHandler();
              window.ga(
                'send',
                'event',
                'Interact',
                'Contribute',
                'Create New Product'
              );
            }}
          >
            <i className='fas fa-plus' style={{ fontSize: '1.5rem' }}></i>{' '}
            &nbsp; Tambah Produk Baru
          </Button>
        </div>
      </Col>
      <Col xs={12}>
        <div className='mx-3 my-3'>
          <h2 className='mb-3'>Produk / Layanan Saya</h2>
          {loadingProducts ? (
            <Loading />
          ) : errorProducts ? (
            <Message variant='danger'>{errorProducts}</Message>
          ) : (
            <Table
              striped
              bordered
              hover
              responsive
              className='table-sm'
              style={{ minWidth: '640px' }}
            >
              <thead>
                <tr>
                  <th>EDIT/VIEW</th>
                  <th>NAME</th>
                  <th>PRICE</th>
                  <th>UNIT PRICE</th>
                  <th>CATEGORY</th>
                  <th>PERSONAL BRAND</th>
                  <th>ID</th>
                </tr>
              </thead>
              <tbody>
                {products.length > 0 ? (
                  products.map((product) => (
                    <tr key={product._id}>
                      <td>
                        <LinkContainer
                          to={`/admin/product/${product._id}/edit`}
                        >
                          <Button
                            variant='light'
                            className='btn btn-txt inline-block'
                          >
                            <i className='fa fa-edit'></i>
                          </Button>
                        </LinkContainer>{' '}
                        &nbsp;{' '}
                        <LinkContainer to={`/products/${product._id}`}>
                          <Button
                            variant='light'
                            className='btn btn-txt inline-block'
                          >
                            <i className='fa fa-eye'></i>
                          </Button>
                        </LinkContainer>
                        {/* <Button
                            variant='danger'
                            className='btn btn-txt inline-block'
                            onClick={() => deleteHandler(product)}
                          >
                            <i className='fa fa-times'></i>
                          </Button> */}
                      </td>
                      <td>{product.name}</td>
                      <td>Rp{product.price}</td>
                      <td>/{product.priceUnit}</td>
                      <td>{product.category}</td>
                      <td>{product.brand}</td>

                      <td className='break-all'>{product._id}</td>
                    </tr>
                  ))
                ) : (
                  <>
                    <tr>
                      <td colSpan={7}>Anda Belum Punya Produk / Layanan</td>
                    </tr>
                  </>
                )}
              </tbody>
            </Table>
          )}
        </div>
      </Col>
    </Row>
  );
};

export default MyProductsPage;
