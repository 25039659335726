import React from 'react';

function ListItemsArray({ items }) {
  return (
    <ul>
      {items.map((item, index) => (
        <li key={index}>
          <a href={item} target='_blank' rel='noreferrer noopener'>
            File {index + 1}
          </a>
        </li>
      ))}
    </ul>
  );
}

export default ListItemsArray;
