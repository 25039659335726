import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Form, Button, Row, Col } from 'react-bootstrap';

const SearchBox = () => {
  const [keyword, setKeyword] = useState('');

  const history = useHistory();

  const submitHandler = (e) => {
    e.preventDefault();
    if (keyword.trim().length > 2) {
      history.push(`/search/${keyword}`);
    } else {
      history.push('/');
    }
  };

  return (
    <Form onSubmit={submitHandler} inline className='search-box mb-4'>
      <div className='search__input-field'>
        <div className='search__icon-filter'>
          <i
            className='fa fa-sliders-h'
            style={{ fontSize: '1.2rem', color: '#c8c8c8' }}
          ></i>
        </div>
        <Form.Control
          type='text'
          name='q'
          onChange={(e) => setKeyword(e.target.value)}
          placeholder='Cari layanan apa_?'
          className='mr-sm-2 ml-sm-5'
        ></Form.Control>
        <Button
          type='submit'
          variant='success'
          className='search__button search-btn btn-inline p-2'
          title='Search'
        >
          <i
            className='fa fa-search'
            style={{ fontSize: '1.2rem', color: '#c8c8c8' }}
          ></i>
        </Button>
      </div>
    </Form>
  );
};

export default SearchBox;
