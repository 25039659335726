import { Link } from 'react-router-dom';
import { Button, Card } from 'react-bootstrap';
import Rating from './Rating';
import { formatPrice } from '../shared/functions';

const Product = ({ product }) => {
  return (
    <Card className='my-3 p-3 rounded'>
      <Card.Body>
        <Link
          className='product__img rounded'
          title={`${product.brand},  ${product.name}`}
          to={`/products/${product._id}`}
        >
          <Card.Img src={`${product.image}`} variant='top' />
        </Link>
        <Card.Title as='h2' className='product__title-home'>
          <Link
            className='no-underline'
            title={product.name}
            to={`/products/${product._id}`}
          >
            {product.brand}, {product.name}
          </Link>
        </Card.Title>
        <Card.Text as='div' className='desc'>
          {product.description}
        </Card.Text>
        <Card.Text as='div'>
          <Rating
            value={product.rating}
            text={`${product.numReviews.toLocaleString()} reviews`}
          />
        </Card.Text>
        <Card.Text as='text' className='price'>
          <span className='price-cur'>Rp</span>
          {formatPrice(product.price)}
          <span className='price-unit'>/{product.priceUnit}</span>
        </Card.Text>
        <Link
          // onClick={addToCartHandler}
          className='btn btn-block'
          disabled={product.isActive === false}
          to={`/products/${product._id}`}
        >
          Select
        </Link>
      </Card.Body>
    </Card>
  );
};

export default Product;
