import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useHistory } from 'react-router-dom';
import { Table, Form, Button, Row, Col } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Message from '../components/Message';
import Loading from '../components/Loading';
import { getUserProfile } from '../actions/userActions';
import { USER_PROFILE_UPDATE_RESET } from '../constants/userConstant';
import { listMyOrders } from '../actions/orderActions';
import { formatSeparator } from '../shared/functions';

const MyOrdersPage = ({ location, match }) => {
  const [name, setName] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [birthday, setBirthday] = useState('');
  const [sex, setSex] = useState('');
  const [bio, setBio] = useState('');
  const [image, setImage] = useState('');
  const [message, setMessage] = useState(null);

  const dispatch = useDispatch();

  const pageNumber = match.params.pageNumber || 1;

  const userProfile = useSelector((state) => state.userProfile);
  const { loading, error, user } = userProfile;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const orderMyList = useSelector((state) => state.ordersMyList);
  const { loading: loadingOrders, error: errorOrders, orders } = orderMyList; // rename needed to differentiate

  const history = useHistory();
  const redirect = location.search ? location.search.split('=')[1] : '/';

  useEffect(() => {
    if (!userInfo) {
      history.push('/login');
    }
  }, [dispatch, userInfo, history, pageNumber]);

  useEffect(() => {
    if (!userInfo) {
      history.push('/login');
    } else {
      if (!user || !user.name) {
        dispatch({ type: USER_PROFILE_UPDATE_RESET });
        dispatch(getUserProfile('profile'));

        // setOrdersList(orders);
      }
    }
  }, [dispatch, history, userInfo, user, redirect]);

  useEffect(() => {
    dispatch(listMyOrders());
    // console.log('orders:', orders);
  }, [dispatch, history, userInfo, user, redirect]);

  return (
    <Row className='user-profile'>
      <Col xs={12}>
        <h1 className='mt-3 mb-5'>My Orders</h1>
      </Col>
      <Col xs={12}>
        <div className='mx-3 my-3'>
          <h2 className='mb-3'>Daftar Pesanan Yang Saya Beli</h2>
          {loadingOrders ? (
            <Loading />
          ) : errorOrders ? (
            <Message variant='danger'>{errorOrders}</Message>
          ) : (
            <Table striped bordered hover responsive className='table-sm'>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>View</th>
                  <th>Total</th>
                  <th>Paid?</th>
                  <th>Fulfilled</th>
                  <th>ID</th>
                </tr>
              </thead>
              <tbody>
                {orders.length > 0 ? (
                  orders.map((order) => (
                    <tr key={order._id}>
                      <td>
                        {order.createdAt
                          ? order.createdAt.substring(0, 10)
                          : ''}
                      </td>
                      <td>
                        <LinkContainer to={`/order/${order._id}`}>
                          <Button className='btn' variant='light'>
                            <i className='fa fa-eye'></i>
                          </Button>
                        </LinkContainer>
                      </td>
                      <td>Rp {order.totalPrice}</td>
                      <td>
                        {order.isPaid ? (
                          <>
                            <i
                              className='fas fa-check'
                              style={{ color: 'green' }}
                            ></i>
                            <br />(
                            {!order.paidAt
                              ? 'Date/time unknown'
                              : order.paidAt.substring(0, 10)}
                            )
                          </>
                        ) : (
                          <i
                            className='fas fa-times'
                            style={{ color: 'red' }}
                          ></i>
                        )}
                      </td>
                      <td>
                        {order.isDelivered ? (
                          order.deliveredAt.substring(0, 10)
                        ) : (
                          <i
                            className='fas fa-hourglass-start'
                            style={{ color: 'grey', fontSize: 'small' }}
                          ></i>
                        )}
                      </td>

                      <td className='break-all'>{order._id}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={6}>Belum ada</td>
                  </tr>
                )}
              </tbody>
            </Table>
          )}
        </div>
      </Col>
    </Row>
  );
};

export default MyOrdersPage;
