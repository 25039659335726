import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Form, Button, Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import ReactGA from 'react-ga';

import Meta from '../components/Meta';
import Message from '../components/Message';
import Loading from '../components/Loading';
import FormContainer from '../components/FormContainer';
import InputPassword from '../components/InputPassword';
import { registerUser } from '../actions/userActions';
import GetKecamatan from '../components/GetKecamatan';

const SignupPage = (props) => {
  ReactGA.initialize(process.env.REACT_APP_GUA);

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [userName, setUserName] = useState('');
  const [referralCode, setReferralCode] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState(null);

  const [subDistrict, setSubDistrict] = useState('');
  const [district, setDistrict] = useState('');
  const [province, setProvince] = useState('');
  const [village, setVillage] = useState('');
  const [country, setCountry] = useState('Indonesia');

  const dispatch = useDispatch();

  const userRegister = useSelector((state) => state.userRegister);
  const userLogin = useSelector((state) => state.userLogin);

  const { loading, error, userInfo } = userRegister;

  const history = useHistory();
  // const redirect = location.search ? location.search.split('=')[1] : '/';
  let redirect = props ? props.location.search.split('=')[1] : '/';
  // if (!redirect) {
  //   redirect = '/profile';
  // }

  // console.log('redirect: ', redirect);

  useEffect(() => {
    // Analytics data
    ReactGA.pageview(window.location.pathname + window.location.search);

    if (userInfo && redirect) {
      history.push(redirect);
    } else if (userInfo && !redirect) {
      history.push('/my-products');
    }
  }, [props, history, userInfo, redirect, userRegister]);
  useEffect(() => {
    // console.log('redirect:', redirect, props);
    /*if (userInfo && (redirect !== '' || redirect)) {
      history.push(redirect);
    } else if (userInfo && (redirect === '' || !redirect)) {
      history.push('/profile');
    }*/

    const {
      loading: loadingLoginInfo,
      error: errorLoginInfo,
      userInfo,
    } = userLogin;

    // console.log('userInfo:', userInfo);
    if (userInfo && redirect) {
      history.push(redirect);
    } else if (userInfo && !redirect) {
      history.push('/my-products');
    }
  }, [history, redirect, userInfo, userLogin]);

  const submitHandler = (e) => {
    e.preventDefault();
    window.scrollTo(0, 0);
    setMessage('');

    if (!email) {
      setMessage('Alamat email perlu diisi');
    } else if (!phone) {
      setMessage('Nomer ponsel perlu diisi');
    } else if (!userName) {
      setMessage(
        'Username yg diinginkan perlu diisi, nanti terpakai utk link profil user kamu'
      );
    } else if (/[^a-z0-9-_]/.test(userName)) {
      setMessage(
        'Username hanya boleh menggunakan karakter alfa numerik, tanpa huruf besar, tanpa spasi'
      );
    } else if (!firstName) {
      setMessage('Nama depan perlu diisi');
    } else if (!lastName) {
      setMessage('Nama belakang perlu diisi');
    } else if (!password || !confirmPassword) {
      setMessage('Passwords tidak boleh kosong');
    } else if (password !== confirmPassword) {
      setMessage('Password & konfirmasi password tidak sama');
    } else {
      dispatch(
        registerUser(
          firstName,
          lastName,
          email,
          phone,
          userName,
          referralCode,
          password,
          village,
          subDistrict,
          district,
          province,
          country
        )
      );
    }
  };

  return (
    <FormContainer>
      <Meta title={`Signup`} />
      <h1>Daftar / Sign Up</h1>
      {message && <Message variant='danger'>{message}</Message>}
      {error && <Message variant='danger'>{error}</Message>}
      {loading && <Loading />}
      <Form onSubmit={submitHandler}>
        <div className='divider mt-4'>
          <strong className='text-uppercase'>Buat Login & Kontak</strong>
        </div>
        <Row>
          <Col xs={6} sm={6}>
            <Form.Group controlId='firstName'>
              <Form.Label>Nama Depan*</Form.Label>
              <Form.Control
                type='text'
                placeholder='Nama Depan'
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                required={true}
              ></Form.Control>
            </Form.Group>
          </Col>
          <Col xs={6} sm={6}>
            <Form.Group controlId='lastName'>
              <Form.Label>N Belakang*</Form.Label>
              <Form.Control
                type='text'
                placeholder='Nama Belakang'
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                required={true}
              ></Form.Control>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs={6} sm={6}>
            <Form.Group controlId='email'>
              <Form.Label>Alamat Email*</Form.Label>
              <Form.Control
                type='email'
                placeholder='Alamat Email'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required={true}
              ></Form.Control>
            </Form.Group>
          </Col>
          <Col xs={6} sm={6}>
            <Form.Group controlId='phone'>
              <Form.Label>Nomer Ponsel*</Form.Label>
              <Form.Control
                type='text'
                placeholder='Nomer Ponsel'
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                required={true}
              ></Form.Control>
            </Form.Group>
          </Col>
        </Row>

        {/* <div className='divider mt-4'>
          <strong className='text-uppercase'>User name &amp; Password</strong>
        </div> */}
        <Row>
          <Col xs={6} sm={6}>
            <Form.Group>
              <Form.Group controlId='userName'>
                <Form.Label>Username*</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='username yg diinginkan, unik'
                  value={userName}
                  onChange={(e) => setUserName(e.target.value.toLowerCase())}
                  required={true}
                ></Form.Control>
              </Form.Group>
            </Form.Group>
          </Col>
          <Col xs={6} sm={6}>
            <Form.Group>
              {/*
              <Form.Label>Password*</Form.Label>
              <Form.Control
                type='password'
                placeholder='Masukkan password'
                value={password}
                onChange={(e) => setPassword(e.target.value)}
  ></Form.Control> */}
              <InputPassword
                id='password'
                label='Password*'
                className='password'
                placeholder='Password'
                autocomplete='new-password'
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                  setConfirmPassword(e.target.value);
                }}
                required={true}
              />
              <InputPassword
                id='confirmPassword'
                label='Konfirmasi*'
                className='password'
                placeholder='Password'
                autocomplete='new-password'
                value={confirmPassword}
                // onChange={(e) => setConfirmPassword(e.target.value)}
                required={true}
                style={{
                  visibility: 'hidden',
                  position: 'absolute',
                }}
              />
            </Form.Group>
          </Col>
        </Row>

        <div className='divider mt-4'>
          <strong className='text-uppercase'>
            Area / Daerah Kamu (Opsional)
          </strong>
        </div>
        <GetKecamatan
          province={province}
          district={district}
          subDistrict={subDistrict}
          village={village}
          onProvince={setProvince}
          onDistrict={setDistrict}
          onSubDistrict={setSubDistrict}
          onVillage={setVillage}
          villageColHide={false}
        />

        <div className='divider mt-4'>
          <strong className='text-uppercase'>Kode Referal (Opsional)</strong>
        </div>
        <Row>
          {/* <Col xs={6} sm={6}>
          </Col> */}
          <Col xs={12} sm={6}>
            <Form.Group controlId='referralCode'>
              <Form.Label>Kode Referal</Form.Label>
              <Form.Control
                type='text'
                placeholder='kode referal jika ada'
                value={referralCode}
                onChange={(e) => setReferralCode(e.target.value)}
              ></Form.Control>
            </Form.Group>
          </Col>
        </Row>

        <Button type='submit' variant='primary' className='my-3'>
          Sign Up
        </Button>
      </Form>
      <Row className='py-3'>
        <Col>
          Sudah Punya Account? &nbsp;
          <Link to={redirect ? `/login?redirect=${redirect}` : '/login'}>
            Masuk
          </Link>
        </Col>
      </Row>
    </FormContainer>
  );
};

export default SignupPage;
