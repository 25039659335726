import React, { useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';

import { capitalizeFirstChar } from '../shared/functions';
import data from '../shared/data/txt/provinsi.json';
import Loading from './Loading';
// import dataKab from '../shared/data/txt/kabupaten/11.json';

function GetKecamatan(props) {
  const [province, setProvince] = useState(props.province);
  const [district, setDistrict] = useState(props.district);
  const [dataKab, setDataKab] = useState({});
  const [subDistrict, setSubDistrict] = useState(props.subDistrict);
  const [dataKec, setDataKec] = useState({});
  const [village, setVillage] = useState(props.village);
  const [dataKelurahan, setDataKelurahan] = useState({});

  // const [loadingP, setLoadingP] = useState(false);
  const [loadingD, setLoadingD] = useState(false);
  const [loadingS, setLoadingS] = useState(false);
  const [loadingV, setLoadingV] = useState(false);

  const [selectKab, setSelectKab] = useState(true);
  const [selectKec, setSelectKec] = useState(true);
  const [selectKel, setSelectKel] = useState(true);

  // const [displaySelectDD, setDisplaySelectDD] = useState();

  const selectDistrictHandler = async (id) => {
    // import data from '../shared/data/txt/provinsi.json';
    // const fileName = `../shared/data/txt/kabupaten/${id}.json`;
    // console.log('dataKab:', dataKab);
    if (id) {
      // console.log('id:', id);
      setLoadingD(true);
      const fileName = `/assets/data/txt/kabupaten/${id}.json`;
      await fetch(fileName)
        .then((response) => response.json())
        .then((data) => {
          // console.log('kabupaten:', data);
          setDataKab(data);
          setSelectKab(false);
          setLoadingD(false);
        })
        .catch((error) => {
          console.error('Error:', error);
          setLoadingD(false);
        });
    }
  };
  const selectSubDistrictHandler = async (id) => {
    setLoadingS(true);
    const fileName2 = `/assets/data/txt/kecamatan/${id}.json`;
    await fetch(fileName2)
      .then((response) => response.json())
      .then((data) => {
        // console.log('kecamatan:', data);
        setDataKec(data);
        setSelectKec(false);
        setLoadingS(false);
      })
      .catch((error) => {
        console.error('Error:', error);
        setLoadingS(false);
      });
  };

  const selectVillageHandler = async (id) => {
    setLoadingV(true);
    const fileName3 = `/assets/data/txt/kelurahan/${id}.json`;
    await fetch(fileName3)
      .then((response) => response.json())
      .then((data) => {
        // console.log('kelurahan:', data);
        setDataKelurahan(data);
        setSelectKel(false);
        setLoadingV(false);
      })
      .catch((error) => {
        console.error('Error:', error);
        setLoadingV(false);
      });
  };

  return (
    <div>
      Petunjuk: Jika perlu, hapus isi kolom u/ munculkan opsi dropdown pilih
      daerah otomatis
      {/* {JSON.stringify(data)} */}
      {props.province !== '' &&
        props.district !== '' &&
        props.subDistrict !== '' &&
        props.village !== '' && (
          <Row>
            <Col xs={6} md={4}>
              <Form.Label>Propinsi:</Form.Label>
              <Form.Control
                as='input'
                type='text'
                placeholder='Propinsi'
                value={props.province}
                onChange={(e) => props.onProvince(e.target.value)}
                onClick={(e) => e.target.select()}
              ></Form.Control>
            </Col>
            <Col xs={6} md={4}>
              <Form.Label>Kab. / Kota:</Form.Label>
              <Form.Control
                as='input'
                type='text'
                placeholder='Kab. / Kota'
                value={props.district}
                onChange={(e) => props.onDistrict(e.target.value)}
                onClick={(e) => e.target.select()}
              ></Form.Control>
            </Col>
            <Col xs={6} md={4}>
              <Form.Label>Kecamatan:</Form.Label>
              <Form.Control
                as='input'
                type='text'
                placeholder='Kecamatan'
                value={props.subDistrict}
                onChange={(e) => props.onSubDistrict(e.target.value)}
                onClick={(e) => e.target.select()}
              ></Form.Control>
            </Col>
            <Col xs={6} md={12} className='mt-2'>
              <Form.Label>Kelurahan:</Form.Label>
              <Form.Control
                as='input'
                type='text'
                placeholder='Kelurahan'
                value={props.village}
                onChange={(e) => props.onVillage(e.target.value)}
                onClick={(e) => e.target.select()}
              ></Form.Control>
            </Col>
          </Row>
        )}
      {(props.province === '' ||
        props.district === '' ||
        props.subDistrict === '' ||
        props.village === '') && (
        <Row>
          <Col xs={6} md={4} className='boxshadow'>
            <Form.Label>Propinsi:</Form.Label>
            <Form.Control
              as='select'
              value={province}
              onChange={(e) => {
                e.preventDefault();
                console.log(e);
                setProvince(e.target.value);
                props.onProvince(e.target.options[e.target.selectedIndex].text);
                props.onDistrict('');
                props.onSubDistrict('');
                props.onVillage('');
                selectDistrictHandler(e.target.value);
                setDataKab({});
                setSelectKab(true);
                setDataKec({});
                setSelectKec(true);
                setDataKelurahan({});
                setSelectKel(true);
              }}
            >
              <option></option>
              {data.length > 0
                ? data.map((item) => {
                    return (
                      <option key={item.id} value={item.id}>
                        {item.nama}
                      </option>
                    );
                  })
                : ''}
            </Form.Control>
          </Col>
          <Col xs={6} md={4} className='boxshadow'>
            {loadingD && <Loading />}
            <Form.Label>Kab / Kota:</Form.Label>
            <Form.Control
              as='select'
              disabled={selectKab}
              value={district}
              onChange={(e) => {
                setDistrict(e.target.value);
                props.onDistrict(e.target.options[e.target.selectedIndex].text);
                props.onSubDistrict('');
                props.onVillage('');
                selectSubDistrictHandler(e.target.value);
              }}
            >
              <option></option>
              {dataKab.length > 0
                ? dataKab.map((item) => {
                    return (
                      <option key={item.id} value={item.id}>
                        {capitalizeFirstChar(item.nama)}
                      </option>
                    );
                  })
                : ''}
            </Form.Control>
          </Col>
          <Col xs={6} md={4} className='boxshadow'>
            {loadingS && <Loading />}
            <Form.Label>Kecamatan:</Form.Label>
            <Form.Control
              as='select'
              disabled={selectKec}
              value={subDistrict}
              onChange={(e) => {
                setSubDistrict(e.target.value);
                selectVillageHandler(e.target.value);
                props.onSubDistrict(
                  e.target.options[e.target.selectedIndex].text
                );
                props.onVillage('');
              }}
            >
              <option></option>
              {dataKec.length > 0
                ? dataKec.map((item) => {
                    return (
                      <option key={item.id} value={item.id}>
                        {item.nama}
                      </option>
                    );
                  })
                : ''}
            </Form.Control>
          </Col>
          {props.villageColHide ? (
            ''
          ) : (
            <Col xs={6} md={12} className='boxshadow'>
              {loadingV && <Loading />}
              <Form.Label>Kelurahan:</Form.Label>
              <Form.Control
                as='select'
                disabled={selectKel}
                value={village}
                onChange={(e) => {
                  setVillage(e.target.value);
                  props.onVillage(
                    e.target.options[e.target.selectedIndex].text
                  );
                  selectVillageHandler(e.target.value);
                }}
              >
                <option></option>
                {dataKelurahan.length > 0
                  ? dataKelurahan.map((item) => {
                      return (
                        <option key={item.id} value={item.id}>
                          {item.nama}
                        </option>
                      );
                    })
                  : ''}
              </Form.Control>
            </Col>
          )}
        </Row>
      )}
    </div>
  );
}

export default GetKecamatan;
