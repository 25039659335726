import React, { useState, useEffect } from 'react';
// import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
// import { Link } from 'react-router-dom';
import {
  Row,
  Col,
  Image,
  ListGroup,
  Card,
  Button,
  Form,
  Carousel,
} from 'react-bootstrap';
import ReactGA from 'react-ga';
// import TimePicker from 'rc-time-picker';
// import 'rc-time-picker/assets/index.css';

import { getUserProfile } from '../actions/userActions';
import Meta from '../components/Meta';
import Loading from '../components/Loading';
import Message from '../components/Message';
import Rating from '../components/Rating';
import ShareThis from '../components/ShareThis';
import { formatPrice, getVideoId } from '../shared/functions';
import PopupWindow from '../components/PopupWindow';
import MapsGoogleOnePin from '../components/MapsGoogleOnePin';
// import products from '../products';
import {
  listProductDetails,
  createProductReview,
} from '../actions/productActions';

import {
  PRODUCT_CREATE_REVIEW_RESET,
  PRODUCT_DETAILS_RESET,
} from '../constants/productConstants';
import { Link } from 'react-router-dom';

const ProductPage = ({ history, match, location, ...props }) => {
  ReactGA.initialize(process.env.REACT_APP_GUA);
  //   const product = products.find((p) => p._id === match.params.id);
  // const [product, setProduct] = useState({});
  const date = new Date();
  const dateToday = date.toISOString().split('T')[0];
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);
  const tomorrowDate = tomorrow.toISOString().split('T')[0];
  const [toBeServedDate, setToBeServedDate] = useState(tomorrowDate);
  // const [time, setTime] = useState('10:00');
  const [toBeServedTime, setToBeServedTime] = useState('10:00');
  const [notes, setNotes] = useState('');
  const [qty, setQty] = useState(1);
  const [maxOrderQty, setMaxOrderQty] = useState(null);
  const [sellerId, setSellerId] = useState('');
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState('');

  const [modalShow, setModalShow] = useState(false);
  const [modalLoading, setModalLoading] = useState('true');

  const [ytSdkReady, setYtSdkReady] = useState(false);
  const [players, setPlayers] = useState([]);

  const dispatch = useDispatch();
  // const history = useHistory();

  const productDetails = useSelector((state) => state.productDetails);
  const { loading, error, product } = productDetails;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin; // it has data when user is logged-in
  // console.log('userInfo:', userInfo);

  // const sellerDetails = useSelector((state) => state.userProfile);
  // const {
  //   loading: loadingSeller,
  //   error: errorSeller,
  //   user: seller,
  // } = sellerDetails;

  const productReviewCreate = useSelector((state) => state.productReviewCreate);
  const {
    loading: loadingProductReview,
    success: successProductReview,
    error: errorProductReview,
  } = productReviewCreate;

  // consolidate with same function on component product
  /*const formatPrice = (int) => {
    let number = int / 1000;
    let price = new Intl.NumberFormat('en-US');
    const fNum = price.format(number) + 'k'; // "1,234,567,890"
    return fNum;
  };*/
  const arrayTime = [
    '00:00',
    '01:00',
    '02:00',
    '03:00',
    '04:00',
    '05:00',
    '06:00',
    '07:00',
    '08:00',
    '09:00',
    '10:00',
    '11:00',
    '12:00',
    '13:00',
    '14:00',
    '15:00',
    '16:00',
    '17:00',
    '18:00',
    '19:00',
    '20:00',
    '21:00',
    '22:00',
    '23:00',
  ];

  useEffect(() => {
    // dispatch({ type: PRODUCT_DETAILS_RESET });
  }, [dispatch]);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
    // get seller id
    setSellerId(product.user);
    // if (sellerId) dispatch(getUserProfile(sellerId));
    // console.log('sellerId:', sellerId);
  }, [product, sellerId, dispatch]);

  // useEffect(() => {
  //   console.log('seller:', seller);
  // }, [seller, sellerId]);

  useEffect(() => {
    // console.log('useEffect match: ', match.path);
    // console.log('useEffect url : ', process.env.REACT_APP_DEPLOY_URL);

    dispatch({ type: PRODUCT_CREATE_REVIEW_RESET });
    if (successProductReview) {
      // alert('Review submitted successfully');
      setRating(0);
      setComment('');
      dispatch({ type: PRODUCT_CREATE_REVIEW_RESET });
    }
    dispatch(listProductDetails(match.params.id));
    /*
    const fetchProduct = async () => {
      // res.data destructuring
      const { data } = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/products/${match.params.id}`
      );

      setProduct(data);
    };
    fetchProduct();*/
  }, [dispatch, match, successProductReview]);

  const addToCartHandler = () => {
    // console.log('addToCart match : ', match);
    // console.log('url : ', process.env.REACT_APP_DEPLOY_URL);
    // console.log('notes: ', notes);
    // console.log('sellerId:', product.user);
    history.push(
      `/buy/${match.params.id}?s=${sellerId}&n=${notes}&q=${qty}&d=${toBeServedDate}&t=${toBeServedTime}`
    );
  };

  const openMapHandler = (e) => {
    e.preventDefault();
    setModalShow(true);
  };

  const submitRatingHandler = (e) => {
    e.preventDefault();
    dispatch(
      createProductReview(match.params.id, {
        rating,
        comment,
      })
    );
  };

  /*
  const addYtScript = async () => {
    const script2 = document.createElement('script');
    script2.type = 'text/javascript';
    script2.src = 'https://www.youtube.com/iframe_api';
    // script.async = true;
    script2.defer = false;

    console.log('Hello Bandung!', script2);
    script2.onload = () => {
      console.log('Hello Bandung 2');
    };
    if (typeof window.YT === 'undefined') {
      document.body.appendChild(script2);
    } else if (window.YT) {
      setYtSdkReady(true);
      if (ytSdkReady) {
        /*
        var players = [];
        onYouTubeIframeAPIReady();
        function onYouTubeIframeAPIReady() {
          var iframes = document.querySelectorAll('iframe');
          console.log('iframes:', iframes);
          for (var i = 0; i < iframes.length; i++) {
            players[i] = new window.YT.Player(iframes[i], {
              events: {
                onReady: onPlayerReady,
                onStateChange: onPlayerStateChange,
              },
            });
          }
        }
        function onPlayerReady(event) {
          var carousel = document.querySelector('#carouselExampleIndicators');
          carousel.addEventListener('slid.bs.carousel', function () {
            var activeSlide = carousel.querySelector('.carousel-item.active');
            var activePlayer = players.find(function (player) {
              return player.a.id === activeSlide.querySelector('iframe').id;
            });
            activePlayer.playVideo();
          });
        }
        function onPlayerStateChange(event) {
          if (event.data === window.YT.PlayerState.PLAYING) {
            var carousel = document.querySelector('#carouselExampleIndicators');
            var activeSlide = carousel.querySelector('.carousel-item.active');
            var activePlayer = players.find(function (player) {
              return player.a.id === activeSlide.querySelector('iframe').id;
            });
            if (event.target !== activePlayer) {
              event.target.pauseVideo();
            }
          }
        }
        */
  // }
  // }
  // };
  // */

  useEffect(() => {
    // addYtScript();
    /*
    carousel.addEventListener('slid.bs.carousel', function () {
      var activeSlide = carousel.querySelector('.carousel-item.active');
      var activePlayer = players.find(function (player) {
        return player.a.id === activeSlide.querySelector('iframe').id;
      });
      var hiddenSlides = carousel.querySelectorAll(
        '.carousel-item:not(.active)'
      );
      for (var i = 0; i < hiddenSlides.length; i++) {
        var hiddenPlayer = players.find(function (player) {
          return player.a.id === hiddenSlides[i].querySelector('iframe').id;
        });
        hiddenPlayer.destroy();
      }
    });
    */
  }, []);

  useEffect(() => {
    let tag = document.createElement('script');
    tag.src = 'https://www.youtube.com/iframe_api';
    let firstScriptTag = document.getElementsByTagName('script')[0];
    // if (firstScriptTag === )

    /*
    console.log('typeOf:', typeof firstScriptTag);
    const regex = /https:\/\/www\.youtube\.com\/iframe_api/;
    let strFirstScriptTag = JSON.stringify(firstScriptTag);
    const match = strFirstScriptTag.match(regex);
    // const url = match[0]; // "https://www.youtube.com/iframe_api"
    if (match[0] === 'https://www.youtube.com/iframe_api') {
      console.log('firstScriptTag: YES!!!');
    } else {
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    }
    console.log('strFirstScriptTag:', strFirstScriptTag);

    // console.log('firstScriptTag:', firstScriptTag);
    //

    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

    // console.log('firstScriptTag:', firstScriptTag);

    window.onYouTubeIframeAPIReady = onYouTubeIframeAPIReady;

    return function cleanup() {
      for (var i = 0; i < players.length; i++) {
        players[i].destroy();
      }
    };*/
  }, []);

  /*
  const onYouTubeIframeAPIReady = () => {
    var playersElements = document.querySelectorAll('.youtube-player');
    console.log('playersElements: ', playersElements.length, playersElements);
    const numPlayers = playersElements.length;
    for (var i = 0; i < numPlayers; i++) {
      setPlayers((currentPlayers) => [
        ...currentPlayers,
        new window.YT.Player(playersElements[i], {
          events: {
            onReady: onPlayerReady,
          },
        }),
      ]);
    }
  };

  const onPlayerReady = (event) => {
    event.target.playVideo();
  };
*/
  return (
    <>
      {loading ? (
        <Loading />
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : (
        <>
          <Meta
            title={`${product.brand}, ${product.name} - Rp
            ${formatPrice(product.price)}/${product.priceUnit}`}
            description={`${product.description}`}
            image={`${product.image}`}
          />
          <Row className='product-page'>
            <Col xs={12} md={6}>
              <ListGroup variant='flush'>
                <ListGroup.Item>
                  <h1 className='product__title-page'>
                    {product.brand}, {product.name}
                  </h1>
                </ListGroup.Item>
              </ListGroup>
            </Col>
            <Col xs={12} md={6}>
              <ListGroup variant='flush' className='pos-relative'>
                <ListGroup.Item>
                  <Rating
                    value={product.rating}
                    text={`${parseInt(
                      product.numReviews
                    ).toLocaleString()} reviews`}
                  />
                </ListGroup.Item>
                <ListGroup.Item className='price flex justify-between'>
                  <div>
                    <span className='price-cur'>Rp</span>
                    {formatPrice(product.price)}
                    <span className='price-unit'>/{product.priceUnit}</span>
                  </div>
                  <ShareThis
                    title={`${product.brand}, ${
                      product.name
                    } - Rp ${formatPrice(product.price)}/${product.priceUnit}`}
                    description={`${product.description}`}
                    props={props}
                    location={location}
                    match={match}
                  />
                </ListGroup.Item>
              </ListGroup>
            </Col>

            <Col xs={12} md={6}>
              {product.productImages || product.productVideos ? (
                <Carousel pause='hover' className='border rounded mb-5'>
                  {/* <Carousel.Item key={seller.image}>
                    <Image src={seller.image} alt={seller.name} fluid />
                  </Carousel.Item> */}

                  {product.productVideos &&
                    product.productVideos.map((prodVid, index) => (
                      <Carousel.Item
                        key={prodVid + '_' + index}
                        interval={100000000}
                        id={'vid_' + index}
                      >
                        <iframe
                          className='youtube-player d-block w-100'
                          width='560'
                          height='315'
                          src={`https://www.youtube.com/embed/${getVideoId(
                            prodVid
                          )}`}
                          title={product.title}
                          allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
                          allowFullScreen
                        ></iframe>
                      </Carousel.Item>
                    ))}

                  <Carousel.Item key={product.image}>
                    <Image src={product.image} alt={product.name} fluid />
                  </Carousel.Item>

                  {product.productImages &&
                    product.productImages.map((prodImg) => (
                      <Carousel.Item key={prodImg}>
                        <Image src={prodImg} alt={product.name} fluid />
                      </Carousel.Item>
                    ))}
                </Carousel>
              ) : (
                <Image src={product.image} alt={product.name} fluid />
              )}
              <Col xs={12} md={12} className='float-end'>
                {/*<Row>
                <div className='service-area txt-uppercase mb-3'>
                  <MapsGoogleOnePin
                    center={{
                      lat: parseFloat(product.latitude),
                      lng: parseFloat(product.longitude),
                    }}
                    product={product}
                    zoom={13}
                    style={{
                      width: '100%',
                      height: '100%',
                      minHeight: '240px',
                      border: '1px solid #c8c8c8',
                    }}
                  />
                </div>
              </Row>*/}
                <Card>
                  <ListGroup variant='flush'>
                    <ListGroup.Item>
                      <h2 style={{ fontWeight: '300' }}>Pesan di Sini</h2>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <Row>
                        <Col>Rate:</Col>
                        <Col className='price-plain'>
                          Rp
                          {formatPrice(product.price)}/{product.priceUnit}
                        </Col>
                      </Row>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <Row>
                        <Col>Status:</Col>
                        <Col>
                          {product.isActive > 0 ? 'Menerima order' : 'Inactive'}
                        </Col>
                      </Row>
                    </ListGroup.Item>
                    {/* {product.countInStock > 0 && ( */}
                    <ListGroup.Item>
                      <Row className='mt-1'>
                        <Col>
                          Unit Harga dalam Satuan (
                          <strong>/{product.priceUnit}</strong>), Pilih Quantity{' '}
                          {product.maxOrderQty
                            ? ` (Max ${product.maxOrderQty})`
                            : ''}
                          :
                        </Col>
                        <Col className='boxshadow'>
                          <label>
                            <Form.Control
                              as='select'
                              value={qty}
                              onChange={(e) => setQty(e.target.value)}
                            >
                              {[
                                ...Array(
                                  product.maxOrderQty ? product.maxOrderQty : 24
                                ).keys(),
                              ].map((x) => (
                                <option key={x + 1} value={x + 1}>
                                  {x + 1}
                                  {x === 0 ? ' x' : ' x'}
                                </option>
                              ))}
                            </Form.Control>
                          </label>
                        </Col>
                      </Row>
                      <Row className='mt-1'>
                        <Col>
                          Notes / Catatan Pesanan (opsional):
                          <br />
                          <em className='dimmed'>
                            Tambahkan pesan notes untuk item ini jika ada..
                          </em>
                        </Col>
                        <Col>
                          <label>
                            <Form.Control
                              as='textarea'
                              placeholder='Notes / Catatan Pesanan?'
                              rows={3}
                              cols={40}
                              value={notes}
                              onChange={(e) => setNotes(e.target.value)}
                            ></Form.Control>
                          </label>
                          <Form.Control
                            id='selleruserid'
                            type='hidden'
                            value={product.user}
                          ></Form.Control>
                        </Col>
                      </Row>
                      <Row className='mt-1'>
                        <Col>
                          Pilih Tanggal (Opsional):
                          <br />
                          <em className='dimmed'>
                            Abaikan jika tidak digunakan
                          </em>
                        </Col>
                        <Col className='boxshadow'>
                          <Form.Control
                            as='input'
                            type='date'
                            min={dateToday}
                            value={toBeServedDate}
                            onChange={(e) => setToBeServedDate(e.target.value)}
                          ></Form.Control>
                          {/* <label>
                            <Form.Control
                              as='select'
                              value={qty}
                              onChange={(e) => setQty(e.target.value)}
                            >
                              {[...Array(product.countInStock).keys()].map(
                                (x) => (
                                  <option key={x + 1} val={x + 1}>
                                    {x + 1}
                                  </option>
                                )
                              )}
                            </Form.Control>
                          </label> */}
                        </Col>
                      </Row>
                      <Row className='mt-1'>
                        <Col>
                          Waktu (Opsional):
                          <br />
                          <em className='dimmed'>
                            Abaikan jika tidak digunakan
                          </em>
                        </Col>
                        <Col className='boxshadow'>
                          <label>
                            <Form.Control
                              as='select'
                              value={toBeServedTime}
                              onChange={(e) =>
                                setToBeServedTime(e.target.value)
                              }
                            >
                              {arrayTime.map((t) => (
                                <option key={t} value={t}>
                                  {t}
                                </option>
                              ))}
                            </Form.Control>
                          </label>
                        </Col>
                      </Row>
                      {/* <Row>
                      <Col>Time</Col>
                      <Col className='boxshadow'>
                        <TimePicker
                          placeholder='Time'
                          use24Hours
                          showSecond={false}
                          showMinute={false}
                          focusOnOpen={true}
                          format='hh:mm'
                          onChange={(e) => settoBeServedTime(e.format('LT'))}
                        />
                        {toBeServedTime}
                      </Col>
                    </Row> */}
                    </ListGroup.Item>
                    {/* )} */}
                    <ListGroup.Item>
                      <Button
                        onClick={addToCartHandler}
                        className='btn-block'
                        type='button'
                        disabled={product.isActive === false}
                      >
                        Lanjutkan
                      </Button>
                    </ListGroup.Item>
                  </ListGroup>
                </Card>

                {product.connectedProducts &&
                product.connectedProducts.length > 0 ? (
                  <Card className='mt-5'>
                    <ListGroup variant='flush'>
                      <ListGroup.Item>
                        <h2 style={{ fontWeight: '300' }}>Connected Items</h2>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <Row>
                          {/* <Col xs={12} sm={2}>
                            Products:
                          </Col> */}
                          <Col xs={12}>
                            <ul>
                              {product.connectedProducts &&
                                product.connectedProducts.map(
                                  (prodConnected, index) => (
                                    <li key={prodConnected._id}>
                                      <Link
                                        className='text-thin no-underline'
                                        to={`/products/${prodConnected._id}`}
                                        style={{ lineHeight: '0.8rem' }}
                                      >
                                        <img
                                          src={prodConnected.image}
                                          alt={prodConnected.name}
                                          className='img-fluid rounded'
                                          style={{
                                            width: '100px',
                                          }}
                                        />
                                        <br />
                                        {prodConnected.brand}:{' '}
                                        {prodConnected.name}
                                      </Link>
                                    </li>
                                  )
                                )}
                            </ul>
                          </Col>
                        </Row>
                      </ListGroup.Item>
                    </ListGroup>
                  </Card>
                ) : (
                  ''
                )}
              </Col>
            </Col>
            <Col xs={12} md={6}>
              <Col xs={12}>
                <ListGroup variant='flush'>
                  {product.latitude && product.longitude ? (
                    <>
                      <ListGroup.Item className='service-area'>
                        <MapsGoogleOnePin
                          center={{
                            lat: parseFloat(product.latitude),
                            lng: parseFloat(product.longitude),
                          }}
                          product={product}
                          zoom={16}
                          style={{
                            width: '100%',
                            height: '100%',
                            minHeight: '320px',
                            border: '1px solid #d8d8d8',
                          }}
                        />
                      </ListGroup.Item>
                      {/* <ListGroup.Item className='service-area txt-uppercase'>
                      <a
                        className='no-underline'
                        title='Buka Peta Estimasi Lokasi'
                        // href={`https://maps.google.com/?q=${product.latitude},${product.longitude}&z=16`}
                        href={`https://maps.google.com/?q=${product.subDistrict},%20${product.district},%20${product.province}`}
                        target='gmap'
                        onClick={openMapHandler}
                      >
                        <i className='icon-dimmed fa fa-map-marked-alt'></i>
                        &nbsp; {product.subDistrict}, {product.district},{' '}
                        {product.province}
                      </a>
                    </ListGroup.Item> */}
                    </>
                  ) : product.subDistrict && !product.latitude ? (
                    <>
                      <ListGroup.Item className='service-area'>
                        <iframe
                          title='productLocationMapDefinedArea'
                          src={`https://maps.google.com/?q=${product.subDistrict},%20${product.district},%20${product.province}&z=14&output=embed`}
                          style={{
                            width: '100%',
                            height: '100%',
                            minHeight: '320px',
                            border: '1px solid #d8d8d8',
                          }}
                        ></iframe>
                      </ListGroup.Item>
                    </>
                  ) : (
                    ''
                  )}
                  {product.village ||
                  product.subDistrict ||
                  product.district ||
                  product.province ? (
                    <>
                      <ListGroup.Item className='service-area txt-uppercase'>
                        <a
                          className='no-underline'
                          title='Buka Peta Estimasi Lokasi'
                          // href={`https://maps.google.com/?q=${product.latitude},${product.longitude}&z=16`}
                          href={`https://maps.google.com/?q=${product.village}%20${product.subDistrict},%20${product.district},%20${product.province}`}
                          target='gmap'
                          onClick={openMapHandler}
                        >
                          <i className='icon-dimmed fa fa-map-marked-alt'></i>{' '}
                          {product.subDistrict}, {product.district},{' '}
                          {product.province}
                        </a>
                      </ListGroup.Item>
                    </>
                  ) : (
                    ''
                  )}

                  <ListGroup.Item className='product__description mb-3'>
                    <h2 className='mt-2' style={{ fontWeight: '300' }}>
                      Deskripsi
                    </h2>

                    {product.description}
                  </ListGroup.Item>
                  {/* <ListGroup.Item>
                  <Button
                    onClick={addToCartHandler}
                    className='btn-block'
                    type='button'
                    disabled={product.countInStock === 0}
                  >
                    Add To Cart
                  </Button>
                </ListGroup.Item> */}
                </ListGroup>
              </Col>
            </Col>
          </Row>
          <PopupWindow
            className='invoice-modal'
            modalSize='lg'
            show={modalShow}
            modalTitle='Peta Estimasi Lokasi Seller'
            iframe={true}
            // iframeUrl={`https://maps.google.com/?q=${product.latitude},${product.longitude}&z=16&output=embed`}
            iframeUrl={`https://maps.google.com/?q=${product.subDistrict},%20${product.district},%20${product.province}&z=14&output=embed`}
            onHide={() => setModalShow(false)}
            setModalLoading={modalLoading}
            onLoad={() => setModalLoading(false)}
          />
          {/* <Row>
            <Col md={6}>
              <h2>Product Reviews</h2>
              {product.reviews.length === 0 && (
                <Message>No Reviews Yet</Message>
              )}
              <ListGroup variant='flush'>
                {product.reviews.map((review) => (
                  <ListGroup.Item key={review._id}>
                    <strong>{review.name}</strong>
                    <Rating value={review.rating} />
                    <p>{review.createdAt.substring(0, 10)}</p>
                    <p>{review.comment}</p>
                  </ListGroup.Item>
                ))}
                <ListGroup.Item>
                  <h2>Write a Customer Review</h2>
                  {loadingProductReview && <Loading />}
                  {successProductReview && (
                    <Message variant='success'>{successProductReview}</Message>
                  )}
                  {errorProductReview && (
                    <Message variant='danger'>{errorProductReview}</Message>
                  )}
                  {userInfo ? (
                    <Form onSubmit={submitRatingHandler}>
                      <Form.Group controlId='rating' className='boxshadow'>
                        <Form.Label>Rating</Form.Label>
                        <Form.Control
                          as='select'
                          value={rating}
                          required={true}
                          onChange={(e) => setRating(e.target.value)}
                        >
                          <option value=''>Select...</option>
                          <option value='5'>5 - Excellent</option>
                          <option value='4'>4 - Very Good</option>
                          <option value='3'>3 - Good</option>
                          <option value='2'>2 - Fair</option>
                          <option value='1'>1 - Poor</option>
                        </Form.Control>
                      </Form.Group>
                      <Form.Group controlId='comment'>
                        <Form.Label>Your Comment</Form.Label>
                        <Form.Control
                          as='textarea'
                          row='3'
                          value={comment}
                          required={true}
                          onChange={(e) => setComment(e.target.value)}
                        />
                      </Form.Group>
                      <Button type='submit' variant='success'>
                        Submit Review
                      </Button>
                    </Form>
                  ) : (
                    <Message>
                      Please{' '}
                      <Link to={`${process.env.REACT_APP_DEPLOY_URL}/login`}>
                        Sign in
                      </Link>{' '}
                      to write a review
                    </Message>
                  )}
                </ListGroup.Item>
              </ListGroup>
            </Col>
          </Row> */}
          {/* <Link
            to={`${process.env.REACT_APP_DEPLOY_URL}/`}
            className='btn btn-transparent my-3'
          >
            Go Back
          </Link> */}
        </>
      )}
    </>
  );
};

export default ProductPage;
