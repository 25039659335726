import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const Meta = ({ title, description, keywords, ...props }) => {
  let location = useLocation();
  const [path, setPath] = useState();
  const [image, setImage] = useState(
    'https://selfie.id/assets/img/selfie_jobs_network.png'
  );

  useEffect(() => {
    setPath(location.pathname);
  }, [location.pathname]);

  useEffect(() => {
    setImage(props.image ? props.image : image);
  }, [props.image, image]);

  return (
    <Helmet>
      <title>{title}</title>
      <meta name='description' content={description} />
      <meta name='keyword' content={keywords} />
      <meta property='og:title' content={title} />
      <meta property='og:description' content={description} />
      <meta property='og:url' content={path} />
      <meta property='og:image' content={image} />
    </Helmet>
  );
};

Meta.defaultProps = {
  title: process.env.REACT_APP_SITENAME,
  description: '',
  keywords: '',
};

export default Meta;
