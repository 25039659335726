import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Carousel, Image } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { formatPrice } from '../shared/functions';
import Loading from './Loading';
import Message from './Message';
import { listFeaturedProducts } from '../actions/productActions';
import Rating from './Rating';

const ProductsFeaturedCarousel = () => {
  const dispatch = useDispatch();

  const productFeatured = useSelector((state) => state.productFeatured);
  const { loading, error, products } = productFeatured;
  console.error('products:', products);

  useEffect(() => {
    dispatch(listFeaturedProducts());
  }, [dispatch]);

  return loading ? (
    <Loading />
  ) : error ? (
    <Message variant='danger'>{error}</Message>
  ) : (
    <Carousel
      slide
      pause='hover'
      className='top-rated featured border rounded'
      style={{ width: '100%' }}
    >
      {products.map((product) => (
        <Carousel.Item key={product._id} interval={30000}>
          <Link to={`/products/${product._id}`}>
            <Image src={product.image} alt={product.name} fluid />
            <Carousel.Caption className='carousel-caption'>
              <h2>
                {product.brand}, {product.name}
              </h2>
              <h3>
                Rp{formatPrice(product.price)}{' '}
                <span
                  style={{
                    fontSize: '1rem',
                    fontWeight: '300',
                    color: '#787878',
                  }}
                >
                  /{product.priceUnit}
                </span>
              </h3>
              <Rating
                value={product.rating}
                text={`${product.numReviews.toLocaleString()} reviews`}
              />
            </Carousel.Caption>
          </Link>
        </Carousel.Item>
      ))}
    </Carousel>
  );
};

export default ProductsFeaturedCarousel;
