import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ReactDOMServer from 'react-dom/server';

// import { MarkerClusterer } from '@googlemaps/markerclusterer';

import Loading from './Loading';
import Message from './Message';
import Rating from './Rating';
import { listTopRatedProducts } from '../actions/productActions';
import { formatPrice } from '../shared/functions';

const MapsGooglePins = (props) => {
  const [gMapsSdkReady, setGMapsSdkReady] = useState(false);

  const mapRef = useRef();

  const dispatch = useDispatch();
  const productTopRated = useSelector((state) => state.productTopRated);
  const { loading, error, products } = productTopRated;

  useEffect(() => {
    //
    dispatch(listTopRatedProducts());
  }, [dispatch]);

  const addPinsMaps = () => {
    const map = new window.google.maps.Map(mapRef.current, {
      center: props.center,
      zoom: props.zoom,
      mapTypeId: 'roadmap',
      // disableDefaultUI: true,
      panControl: true,
      zoomControl: true,
      scaleControl: true,
      mapTypeControl: true,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: true,
    });
    // const iconDefUrl = 'https://selfie.id/assets/img/icons/maps/icon-map-pin-lg.png';
    const markerIcon = {
      url: 'https://selfie.id/assets/img/icons/maps/icon-map-pin-lg.png',
      scaledSize: new window.google.maps.Size(30, 40),
      origin: new window.google.maps.Point(0, 0),
      anchor: new window.google.maps.Point(15, 50),
      labelOrigin: new window.google.maps.Point(40, 35),
    };

    const pins = [];
    const htmlContentsArray = [];
    const brand = [];

    let ratingHtml;

    products.forEach((item) => {
      pins.push({
        position: {
          lat: parseFloat(item.latitude),
          lng: parseFloat(item.longitude),
        }, //new window.google.maps.LatLng(item.latitude, item.longitude),
      });

      brand.push(item.brand);

      ratingHtml = ReactDOMServer.renderToString(
        <Rating
          value={item.rating}
          text={`${parseInt(item.numReviews).toLocaleString()} reviews`}
        />
      );

      htmlContentsArray.push(
        `<a class='no-underline' href='/products/${item._id}'><img src='${
          item.image
        }' alt='${
          item.brand
        }' style='width:60px;height:auto;float:left;display:inline-block;margin: 0 10px 0 0;overflow:hidden;' /><h2 style='font-size:0.8rem;line-height:1;' class='text-no-upcase'>${
          item.brand
        }, ${
          item.name
        }</h2><p>Rp<span style='font-size:1rem;font-weight:600;'>${formatPrice(
          item.price
        )}</span>/${item.priceUnit}</p>
          <p>${ratingHtml}</p></a>`
      );
    });

    // console.log('pins:', pins);

    // Create markers
    const marker = [];
    const infowindow = [];

    for (let i = 0; i < pins.length; i++) {
      //
      marker[i] = new window.google.maps.Marker({
        position: pins[i].position,
        map: map,
        icon: markerIcon,
        // animation: window.google.maps.Animation.DROP,
        label: {
          text: brand[i].toUpperCase(),
          color: '#139948',
          fontSize: '14px',
          fontWeight: '300',
        } /*(i + 1).toString() + ') ' +*/,
        draggable: false,
      });
      //   console.log(`pins[${i}].position: ${pins[i].position}`);

      infowindow[i] = new window.google.maps.InfoWindow({
        content: htmlContentsArray[i],
        maxWidth: 200,
        // ariaLabel: 'Judul Label',
      });
      //   console.log(`infowindow[${i}]: ${infowindow[i]}`);

      infowindow[i].close();

      window.google.maps.event.addListener(
        marker[i],
        'click',
        function (event) {
          // marker.addListener('click', () => {
          infowindow.forEach((item) => item.close());
          infowindow[i].open({
            anchor: marker[i],
            map: map,
          });
        }
      );
    }

    // Add a marker clusterer to manage the markers.
    // const markerCluster = new MarkerClusterer({ map, marker });
    // new MarkerClusterer({ marker, map });
    // markerCluster();

    for (let i = 0; i < pins.length; i++) {
      setTimeout(() => {
        window.google.maps.event.trigger(marker[i], 'click');
      }, 1500 * i);
    }
  };

  useEffect(() => {
    setGMapsSdkReady(true);
  }, []);

  if (gMapsSdkReady) {
    addPinsMaps();
  }

  return (
    <>
      <div
        ref={mapRef}
        className={`map ${props.className}`}
        style={props.style}
      ></div>
    </>
  );
};

export default MapsGooglePins;
