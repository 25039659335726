import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

function ContactWa() {
  let location = useLocation();
  const [path, setPath] = useState();

  useEffect(() => {
    setPath(location.pathname);
  }, [location.pathname]);

  const a = 'https://api.whatsapp.com/send?phone=';
  const b = '6281805674588&text=';
  const c =
    'Halo%20Selfie!%20%F0%9F%98%83%20%0A%0ANama%20Saya%3A%0AEmail%20Saya%3A%0A%0ASaya%20mau%20tanya%3A%20%0A%0A';
  const d = `_Ref%3A%20%5B${path}%5D_%0A___________________%0A%0A`;

  return (
    <div className='selfie-chat-el'>
      <a
        id='selfieWaLink'
        href={`${a}${b}${d}${c}`}
        target='whatapp'
        rel='noopener noreferrer'
        className='selfie-chat-el'
        onClick={() => {
          window.ga('send', 'event', 'Interact', 'Contact WA', path);
        }}
      >
        <img
          className='selfie-chat-icon'
          src='/assets/img/icon_whatsapp_chat.svg'
          alt='Chat via WA icon'
          height='20'
        />
        {/* <span className='selfie-chat-el'></span> */}
      </a>
    </div>
  );
}

export default ContactWa;
