import React, { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import { Table, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Message from '../components/Message';
import Loading from '../components/Loading';
import { listAllOrders } from '../actions/orderActions';
import { formatSeparator } from '../shared/functions';

const OrdersListPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const ordersAllList = useSelector((state) => state.ordersAllList);
  const { loading, error, orders } = ordersAllList;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    if (userInfo && userInfo.isAdmin) {
      dispatch(listAllOrders());
    } else {
      history.push('/');
    }
  }, [dispatch, userInfo, history]);

  return (
    <>
      <h1>Orders List</h1>
      {loading ? (
        <Loading />
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : (
        <Table striped bordered hover responsive className='table-sm'>
          <thead>
            <tr>
              <th>TANGGAL ORDER (#ID)</th>
              <th>RINCIAN PESANAN</th>
              <th>NOTES</th>
              {/* <th>CUSTOMER</th> */}
              {/* <th>DATE</th> */}
              <th>TOTAL</th>
              <th>PAID</th>
              <th>CUSTOMER DETAILS</th>
              <th>USER ACCOUNT</th>
              {/* <th>CUSTOMER DETAILS</th> */}
              <th>COMPLETED</th>
              <th>ADMIN VIEW</th>
            </tr>
          </thead>
          <tbody>
            {orders.length > 0 ? (
              orders.map((order) => (
                <tr key={order._id}>
                  <td>
                    <strong>
                      {order.createdAt ? order.createdAt.substring(0, 10) : ''}
                    </strong>
                    <br />
                    (Order #{' '}
                    <span className='break-all txt-underlined'>
                      <Link to={`/order/${order._id}`}>{order._id}</Link>
                    </span>
                    )
                  </td>
                  <td>
                    {order.orderItems.map((item) =>
                      item.seller ? (
                        <>
                          <h5>
                            <Link
                              className='no-underline'
                              to={`/products/${item.product}`}
                            >
                              {item.name} [{item.product}]
                            </Link>
                          </h5>
                          Qty: {item.qty} @ Rp
                          {formatSeparator(item.price)}/{item.priceUnit}
                          <br />
                          Total Rp
                          {formatSeparator(
                            parseInt(item.qty) * parseInt(item.price)
                          )}
                        </>
                      ) : (
                        ''
                      )
                    )}
                  </td>
                  <td>
                    {order.orderItems.map((item, index) => (
                      <div key={index}>
                        Notes Item #{index + 1}: {item.notes}
                      </div>
                    ))}
                  </td>
                  {/* <td>
                  {order.user && order.user.name}
                  <br />
                  {order.user && order.user.email}
                </td> */}
                  {/* <td style={{ width: '100px' }}>
                  {order.createdAt ? order.createdAt.substring(0, 10) : ''}
                </td> */}
                  <td>Rp{order.totalPrice}</td>
                  <td>
                    {order.isPaid ? (
                      <>
                        <i
                          className='fas fa-check'
                          style={{ color: 'green' }}
                        ></i>
                        <br />(
                        {!order.paidAt
                          ? 'Date/time unknown'
                          : order.paidAt.substring(0, 10)}
                        )
                      </>
                    ) : (
                      <i
                        className='fas fa-times'
                        style={{ color: 'red', fontSize: '1.2rem' }}
                      ></i>
                    )}
                  </td>
                  <td>
                    {Object.values(order.shippingAddress).map(
                      (value, index) => (
                        <div className='item' key={index}>
                          {value}
                        </div>
                      )
                    )}
                  </td>
                  <td>
                    {order.user.firstName} {order.user.lastName}
                    <br />
                    {order.user.phone}
                    <br />
                    {order.user.userName}
                  </td>
                  {/* <td>
                    {order.shippingAddress.address}, <br />
                    {order.shippingAddress.city}{' '}
                    {order.shippingAddress.postalCode},
                    <br />
                    {order.shippingAddress.country}
                  </td> */}
                  <td>
                    {order.isFulfilled ? (
                      order.fulfilledAt.substring(0, 10)
                    ) : (
                      <i
                        className='fas fa-times'
                        style={{ color: 'maroon', fontSize: '1.2rem' }}
                      ></i>
                    )}
                  </td>
                  <td>
                    <LinkContainer to={`/order/${order._id}`}>
                      <Button className='btn' variant='light'>
                        Order Details
                      </Button>
                    </LinkContainer>
                    {/* <Link to={`/order/${order._id}`}>
                    <div className='btn'>Order Details</div>
                  </Link> */}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={9}>Belum ada</td>
              </tr>
            )}
          </tbody>
        </Table>
      )}
    </>
  );
};

export default OrdersListPage;
