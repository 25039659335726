import React, { useState } from 'react';

function InputPassword(props) {
  const [passInput, setPassInput] = useState('password');
  const [eyeIcon, setEyeIcon] = useState('#icon-svg-eye');
  const [eyeIconColor, setEyeIconColor] = useState('#c8c8c8');

  const handleClick = () =>
    // this.setState(({ type }) => ({
    //   type: type === 'text' ? 'password' : 'text',
    // }));
    {
      if (passInput === 'password') {
        setPassInput('text');
        setEyeIcon('#icon-svg-eye-shut');
        setEyeIconColor('#787878');
      } else {
        setPassInput('password');
        setEyeIcon('#icon-svg-eye');
        setEyeIconColor('#c8c8c8');
      }
    };

  return (
    <div className='rel' style={props.style}>
      <label className={`form-label ${props.className}`}>{props.label}</label>
      <input
        id={props.id}
        type={passInput}
        className='password form-control'
        autoComplete={props.autoComplete}
        placeholder={props.placeholder}
        value={props.value}
        onChange={props.onChange}
      />
      <span className='abs icon-eye-passw' onClick={handleClick}>
        <svg
          fill={eyeIconColor}
          viewBox='0 0 24 24'
          className='svg-icon size-med st000'
        >
          <use xlinkHref={eyeIcon} />
        </svg>
      </span>
    </div>
  );
}

export default InputPassword;
