import React, { useState, useEffect } from 'react';
import axios from 'axios';
// import { useHistory } from 'react-router-dom';
import { Table, Form, Button, Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Message from '../components/Message';
import Loading from '../components/Loading';
import { getUserProfile, updateUserProfile } from '../actions/userActions';
import { USER_PROFILE_UPDATE_RESET } from '../constants/userConstant';
import FormContainer from '../components/FormContainer';
import InputPassword from '../components/InputPassword';
import GetKecamatan from '../components/GetKecamatan';

const MyProfilePage = ({ location, match, history }) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [name, setName] = useState(`${firstName} ${lastName}`);
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [birthday, setBirthday] = useState('');
  const [sex, setSex] = useState('');
  const [bio, setBio] = useState('');
  const [image, setImage] = useState('');
  const [images, setImages] = useState([]);
  const [products, setProducts] = useState([]);
  // const [fileUploading, setFileUploading] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [userName, setUserName] = useState('');
  const [message, setMessage] = useState(null);

  const [subDistrict, setSubDistrict] = useState('');
  const [district, setDistrict] = useState('');
  const [province, setProvince] = useState('');
  const [village, setVillage] = useState('');
  const [country, setCountry] = useState('Indonesia');

  const [fileUploadingSingle, setFileUploadingSingle] = useState(false);
  const [fileUploading, setFileUploading] = useState(false);
  const [errorUploadSingle, setErrorUploadSingle] = useState('');
  const [errorUpload, setErrorUpload] = useState('');

  const dispatch = useDispatch();

  const userProfile = useSelector((state) => state.userProfile);
  const { loading, error, user } = userProfile;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  // console.log('userLogin:', userLogin);

  const userProfileUpdate = useSelector((state) => state.userProfileUpdate);
  const {
    loading: loadingProfileUpdate,
    error: errorProfileUpdate,
    success: successProfileUpdate,
  } = userProfileUpdate;
  // console.log('userProfileUpdate:', userProfileUpdate);

  // const history = useHistory();
  const redirect = location.search ? location.search.split('=')[1] : '/';

  useEffect(() => {
    if (!userInfo) {
      history.push('/login');
    }
  }, [dispatch, userInfo, history]);

  useEffect(() => {
    // dispatch({ type: USER_PROFILE_UPDATE_RESET });
    if (!userInfo) {
      history.push('/login');
    } else {
      if (!user || !user.name) {
        dispatch(getUserProfile('profile'));
      } else {
        setName(user.name);
        setFirstName(user.firstName);
        setLastName(user.lastName);
        setUserName(user.userName);
        setEmail(user.email);
        setImage(user.image);
        user.images ? setImages(user.images) : setImages([]);
        user.products ? setProducts(user.products) : setProducts([]);
        setPhone(user.phone);

        user.village ? setVillage(user.village) : setVillage('');
        user.subDistrict
          ? setSubDistrict(user.subDistrict)
          : setSubDistrict('');
        user.district ? setDistrict(user.district) : setDistrict('');
        user.province ? setProvince(user.province) : setProvince('');
        user.country ? setCountry(user.country) : setCountry('');

        setBirthday(user.birthday);
        setSex(user.sex);
        setBio(user.bio);
      }
    }
  }, [dispatch, history, userInfo, user, redirect, userProfileUpdate]);

  const today = new Date();
  const adultDate = new Date(today);
  adultDate.setDate(adultDate.getDate() - 6570); // 18 years old
  const minDate = adultDate.toISOString().split('T')[0];

  /*
  const uploadFilehandler = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append('image', file);
    setFileUploading(true);

    try {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };

      const { data } = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/upload`,
        formData,
        config
      );

      setImage(`${process.env.REACT_APP_BACKEND_URL}${data}`);
      setFileUploading(false);
    } catch (error) {
      console.log(error);
      setFileUploading(false);
    }
  };
*/
  // Todo re-use consolidate
  const uploadFilehandler = async (e) => {
    console.log('e.target:', e.target.id);
    const formData = new FormData();

    if (e.target.id === 'images-multiple') {
      for (let i = 0; i < e.target.files.length; i++) {
        // console.log('e.target.files[i]:', i, e.target.files[i]);
        formData.append('multi-files', e.target.files[i]);
      }
    } else if (e.target.id === 'image-single') {
      const file = e.target.files[0];
      formData.append('image', file);
    }

    // setFileUploading(true);

    try {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };

      //   setImage(`${process.env.REACT_APP_BACKEND_URL}${data}`);

      if (e.target.id === 'images-multiple') {
        try {
          setFileUploading(true);
          setErrorUpload('');
          // multiple
          const { data } = await axios.post(
            `${process.env.REACT_APP_BACKEND_URL}/api/upload/multiple`,
            formData,
            config
          );
          let imagesArr = [];
          for (let j = 0; j < data.length; j++) {
            // imagesArr[j] = `${process.env.REACT_APP_BACKEND_URL}/${data[j]}`;
            imagesArr[j] = `${data[j]}`;
          }
          setImages(imagesArr);
          setFileUploading(false);
        } catch (error) {
          setFileUploading(false);
          error.response && error.response.data.message
            ? setErrorUpload(error.response.data.message)
            : setErrorUpload(error.message);
        }
      } else if (e.target.id === 'image-single') {
        try {
          setFileUploadingSingle(true);
          setErrorUploadSingle('');
          // single
          const { data } = await axios.post(
            `${process.env.REACT_APP_BACKEND_URL}/api/upload`,
            formData,
            config
          );
          // setImage(`${process.env.REACT_APP_BACKEND_URL}${data}`);
          setImage(`${data}`);
          setFileUploadingSingle(false);
        } catch (error) {
          setFileUploadingSingle(false);

          error.response && error.response.data.message
            ? setErrorUploadSingle(error.response.data.message)
            : setErrorUploadSingle(error.message);
        }
      }

      // setFileUploading(false);
    } catch (error) {
      console.log(error);
      setFileUploadingSingle(false);
      setFileUploading(false);
    }
  };
  const updateFullName = (firstName, lastName) => {
    setName(`${firstName} ${lastName}`);
  };

  const submitHandler = (e) => {
    e.preventDefault();

    window.scrollTo(0, 0);

    console.log('userName.length:', userName.length);

    // set full name
    if (firstName && lastName) {
      updateFullName(firstName, lastName);
    }

    if (!userName) {
      setMessage('Username perlu diisi..');
    } else if (/[^a-zA-Z0-9-_]/.test(userName)) {
      setMessage(
        'Username hanya boleh menggunakan karakter alfa numerik & tanpa spasi'
      );
    }

    if (userName.length <= 4) {
      setMessage('Panjang username minimal 4 karakter');
    }
    if (password !== confirmPassword) {
      setMessage('Password dan konfirmasi password tidak sama');
    } else {
      setMessage('');
      // set full name
      if (firstName && lastName) {
        updateFullName(firstName, lastName);
      }
      dispatch(
        updateUserProfile({
          id: user._id,
          name,
          firstName,
          lastName,
          userName,
          email,
          phone,
          village,
          subDistrict,
          district,
          province,
          country,
          birthday,
          sex,
          bio,
          image,
          images,
          password,
        })
      );
      // dispatch({ type: USER_PROFILE_UPDATE_RESET });
      const displayUpdatedProfile = () => {
        setFirstName(firstName);
        setLastName(lastName);
        setUserName(userName);
        setEmail(email);
        setPhone(phone);
        setProvince(province);
        setDistrict(district);
        setSubDistrict(subDistrict);
        setVillage(village);
        setCountry(country);
        setBirthday(birthday);
        setSex(sex);
        setBio(bio);
        setImage(image);
        setImages(images);
      };

      displayUpdatedProfile();
    }
  };

  return (
    <>
      <Form
        onSubmit={submitHandler}
        autoComplete='on'
        className='my-profile-update-form'
      >
        <Row className='user-profile'>
          <Col xs={12}>
            <h1 className='mt-3 mb-5 text-center'>{name}</h1>

            {message && <Message variant='danger'>{message}</Message>}
            {errorProfileUpdate && (
              <Message variant='danger'>{errorProfileUpdate}</Message>
            )}
            {successProfileUpdate && (
              <Message variant='success'>
                Sukses! Profil berhasil diperbaharui...
              </Message>
            )}
            {loading && <Loading />}
            {loadingProfileUpdate && <Loading />}
            <div className='center-horizontal'>
              <img
                src={
                  image
                    ? image
                    : user.image
                    ? user.image
                    : 'https://selfie.id/assets/img/users/user.png'
                }
                alt={user.name}
                style={{ maxWidth: '200px', borderRadius: '50%' }}
              />
            </div>
            <h2 className='mb-3 text-center'>Form Update Profil Pribadi</h2>
          </Col>
          <Col xs={12} md={4}></Col>
          <Col xs={12} md={4}>
            <Form.Group controlId='image-cont'>
              <Form.Label>Foto Utama (max file size: 1 mb)</Form.Label>
              <Form.Control
                type='text'
                value={image}
                placeholder='Enter image URL'
                onChange={(e) => setImage(e.target.value)}
                style={{ display: 'none' }}
              ></Form.Control>
              <Form.Control
                type='file'
                id='image-single'
                label='Choose File'
                custom
                onChange={uploadFilehandler}
              ></Form.Control>
              {fileUploadingSingle && <Loading />}
              {errorUploadSingle && (
                <Message variant='danger'>{errorUploadSingle}</Message>
              )}
            </Form.Group>
          </Col>
          <Col xs={12} md={4}></Col>
          <Col xs={12} md={6}>
            <div className='mx-3 my-3'>
              <div className='divider mt-4'>
                <strong className='text-uppercase'>Foto-foto Tambahan</strong>
              </div>
              <Form.Group>
                <Form.Label>(each max file size: 500 kb)</Form.Label>
                <Form.Control
                  as='textarea'
                  row='10'
                  col='6'
                  value={images}
                  placeholder='List URL gambar-gambar akan muncul di sini...'
                  onChange={(e) => setImages(e.target.value)}
                  style={{ height: '150px', display: 'none' }}
                  disabled={true}
                ></Form.Control>
                <Form.Group className='mt-3'>
                  {images && images !== [] && images !== ['']
                    ? images.map((imgUrl) => (
                        <img
                          key={imgUrl}
                          src={imgUrl}
                          alt={name}
                          style={{
                            width: '100px',
                            height: '100px',
                            display: 'inline-block',
                            marginRight: '10px',
                          }}
                        />
                      ))
                    : ''}
                </Form.Group>
                <Form.Label className='mt-3'>
                  Unggah Gambar-gambar Tambahan? (max 10 pcs, max file size: 500
                  kb)
                </Form.Label>
                <Form.Control
                  className='row-item'
                  type='file'
                  id='images-multiple'
                  accept='image/*'
                  name='multi-files'
                  label='Pilih Gambar'
                  custom
                  onChange={uploadFilehandler}
                  multiple
                ></Form.Control>
                {fileUploading && <Loading />}
                {errorUpload && (
                  <Message variant='danger'>{errorUpload}</Message>
                )}
              </Form.Group>

              <div className='divider mt-4'>
                <strong className='text-uppercase'>
                  Nama Kamu Sesuai Identitas
                </strong>
              </div>
              <Form.Group controlId='firstName'>
                <Form.Label>Nama Depan</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Enter First Name'
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                ></Form.Control>
              </Form.Group>
              <Form.Group controlId='lastName'>
                <Form.Label>Nama Belakang</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Enter Surname'
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                ></Form.Control>
              </Form.Group>

              <div className='divider mt-4'>
                <strong className='text-uppercase'>
                  Bagaimana Menghubungi Kamu?
                </strong>
              </div>
              <Form.Group controlId='email'>
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type='email'
                  placeholder='Masukkan email'
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                ></Form.Control>
              </Form.Group>
              <Form.Group controlId='phone'>
                <Form.Label>Nomer Ponsel</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Masukkan nomer ponsel'
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                ></Form.Control>
              </Form.Group>

              <div className='divider mt-4'>
                <strong className='text-uppercase'>
                  Isi hanya jika ganti password
                </strong>
              </div>
              <Form.Group>
                {/* <Form.Label>Password</Form.Label>
                <Form.Control
                  type='password'
                  placeholder='Enter password'
                  value={password}
                  autoComplete='new-password'
                  onChange={(e) => setPassword(e.target.value)}
                ></Form.Control> */}
                <InputPassword
                  id='password'
                  label='Ganti Password'
                  className='password'
                  placeholder='Masukkan password'
                  value={password}
                  autoComplete='new-password'
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Form.Group>
              <Form.Group>
                {/* <Form.Label>Konfirmasi Password</Form.Label>
                <Form.Control
                  type='password'
                  placeholder='Enter Confirm Password'
                  value={confirmPassword}
                  autoComplete='new-password'
                  onChange={(e) => setConfirmPassword(e.target.value)}
                ></Form.Control> */}
                <InputPassword
                  id='confirmPassword'
                  label='Konfirmasi Ganti Password'
                  className='password'
                  placeholder='Masukkan Konfirmasi Password'
                  value={confirmPassword}
                  autoComplete='new-password'
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
              </Form.Group>
            </div>
          </Col>
          <Col xs={12} md={6}>
            <div className='divider mt-4'>
              <strong className='text-uppercase'>
                Lokasi Kamu? (Opsional)
              </strong>
            </div>
            <GetKecamatan
              province={province}
              district={district}
              subDistrict={subDistrict}
              village={village}
              onProvince={setProvince}
              onDistrict={setDistrict}
              onSubDistrict={setSubDistrict}
              onVillage={setVillage}
              villageColHide={false}
            />

            <div className='divider mt-4'>
              <strong className='text-uppercase'>Ceritakan Tentang Kamu</strong>
            </div>
            <Form.Group controlId='sex'>
              <div>
                <Form.Label>Jenis Kelamin</Form.Label>
              </div>
              <Form.Check
                inline
                type='radio'
                name='sex'
                id='sexMale'
                label='Pria'
                value='Male'
                checked={sex === 'Male' ? true : false}
                onChange={(e) => setSex(e.target.value)}
              ></Form.Check>
              <Form.Check
                inline
                type='radio'
                name='sex'
                id='sexFemale'
                label='Wanita'
                value='Female'
                checked={sex === 'Female' ? true : false}
                onChange={(e) => setSex(e.target.value)}
              ></Form.Check>
              <Form.Check
                inline
                type='radio'
                name='sex'
                id='sexNotToSay'
                label='Prefer Not to Say'
                value='Not to Say'
                checked={sex === 'Not to Say' ? true : false}
                onChange={(e) => setSex(e.target.value)}
              ></Form.Check>
            </Form.Group>
            <Form.Group controlId='bio'>
              <Form.Label>Bio</Form.Label>
              <Form.Control
                as='textarea'
                value={bio}
                onChange={(e) => setBio(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <Form.Group controlId='birthDate'>
              <Form.Label>Tanggal Lahir</Form.Label>
              <Form.Control
                as='input'
                type='date'
                placeholder='Enter your birth date'
                max={minDate}
                value={birthday}
                onChange={(e) => setBirthday(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <div className='divider mt-4'>
              <strong className='text-uppercase'>User Name Selfie.ID</strong>
            </div>
            <Form.Group controlId='userName'>
              <Form.Label>Username*</Form.Label>
              <Form.Control
                type='text'
                placeholder='username yg diinginkan, unik'
                value={userName}
                onChange={(e) => setUserName(e.target.value)}
              ></Form.Control>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Button type='submit' variant='primary' className='my-3'>
              Perbaharui Profil
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default MyProfilePage;
