import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';

import ContactWa from './ContactWa';
import ShareThis from './ShareThis';

const Footer = ({ match, ...props }) => {
  let location = useLocation();
  const [path, setPath] = useState();

  useEffect(() => {
    setPath(location.pathname);
  }, [location.pathname]);

  const yearNow = () => {
    return new Date().getFullYear();
  };

  return (
    <footer>
      <Container
        style={{
          marginTop: '150px',
          borderTop: '1px solid #d8d8d8',
          position: 'relative',
        }}
      >
        <Row>
          <Col xs={12} md={4} className='text-center-sm py-3'>
            &copy;{yearNow()} {process.env.REACT_APP_SITENAME}
          </Col>
          <Col
            xs={12}
            md={8}
            className='text-center-sm py-3 text-right text-thin2'
          >
            PT. Super Technology Indonesia, Jl. Trunojoyo No.11, Citarum,
            Bandung Wetan, Kota Bandung, Jawa Barat 40115
          </Col>
        </Row>
        {(path === '/' || path === '/maps/') && (
          <ShareThis
            title={`${process.env.REACT_APP_SITENAME}`}
            // description={`${product.description}`}
            props={props}
            location={location}
            match={match}
          />
        )}
        <ContactWa />
      </Container>
    </footer>
  );
};

export default Footer;
