import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { PayPalButton } from 'react-paypal-button-v2';
import { Link, useHistory } from 'react-router-dom';
import ReactGA from 'react-ga';
import { Row, Col, ListGroup, Image, Card, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { formatPrice, formatSeparator, addDecimals } from '../shared/functions';
import Message from '../components/Message';
import Loading from '../components/Loading';
import ListItemsArray from '../components/ListItemsArray';
import {
  getOrderDetails,
  payOrder,
  payOrderXendit,
  createXenditInvoice,
  deliverOrder,
  setZeroPriceOrderPaid,
} from '../actions/orderActions';
import {
  ORDER_PAY_RESET,
  ORDER_DELIVER_RESET,
  ORDER_PAY_XENDIT_RESET,
} from '../constants/orderConstants';
import PopupWindow from '../components/PopupWindow';

const OrderPage = ({ match }) => {
  ReactGA.initialize(process.env.REACT_APP_GUA);
  const orderId = match.params.id;

  const dispatch = useDispatch();
  const history = useHistory();

  const [sdkReady, setSdkReady] = useState(false);
  const [sdkXenditReady, setSdkXenditReady] = useState(true);
  // const [loadingZeroPay, setLoadingZeroPay] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [invoiceUrl, setInvoiceUrl] = useState('');
  const [modalPayLoading, setModalPayLoading] = useState('true');

  const orderDetails = useSelector((state) => state.orderDetails);
  const { order, loading, error } = orderDetails;

  const orderPay = useSelector((state) => state.orderPay);
  const { loading: loadingPay, success: successPay } = orderPay;

  const orderZeroPay = useSelector((state) => state.orderZeroPay);
  const {
    orderZeroPayResult,
    loading: loadingZeroPay,
    error: errorZeroPay,
  } = orderZeroPay;

  const orderCreateInvoiceXendit = useSelector(
    (state) => state.orderCreateInvoicePayXendit
  );
  console.log('orderCreateInvoicePayXendit:', orderCreateInvoiceXendit);
  const {
    invoiceData,
    loading: loadingCreateInvoiceXen,
    success: successCreateInvoiceXen,
    error: errorCreateInvoiceXen,
  } = orderCreateInvoiceXendit;

  const orderDeliver = useSelector((state) => state.orderDeliver);
  const { loading: loadingDeliver, success: successDeliver } = orderDeliver;

  // const orderDeliver = useSelector((state) => state.orderDeliver);
  // const { loading: loadingDeliver, success: successDeliver } = orderDeliver;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  console.log('userInfo:', userLogin);
  console.log('orderDetails:', orderDetails, 'error:', error);

  if (!loading) {
    // Calculate total prices
    order.itemsPrice = addDecimals(
      order.orderItems.reduce((acc, item) => acc + item.price * item.qty, 0)
    );
  }

  // console.log('order', order);

  //   useEffect(() => {
  //     dispatch(getOrderDetails(orderId));
  //   }, [dispatch, orderId]);
  const orderPayXendit = useSelector((state) => state.orderPayXendit);
  // console.log('orderPayXendit:', orderPayXendit);
  const {
    loading: loadingPayXen,
    success: successPayXen,
    error: errorPayXen,
  } = orderPayXendit;

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  useEffect(() => {
    // console.log('order:', order);
    // console.log('orderDetails.order:', orderDetails.order);
    // console.log('userInfo:', userInfo);

    if (!userInfo) {
      history.push('/login');
    }

    // const orderId = match.params.id;
    // console.error('orderId: ', orderId);

    const addPaypalScript = async () => {
      const { data: clientId } = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/config/paypal`
      );

      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = `https://www.paypal.com/sdk/js?client-id=${clientId}&locale=en_US`;
      script.async = true;
      script.onload = () => {
        setSdkReady(true);
      };
      document.body.appendChild(script); // dynamically added SDK script when it's ready / loaded
    };

    // eslint-disable-next-line

    // if (!order || order._id !== orderId) {
    //   dispatch(getOrderDetails(orderId));
    // }
    if (!order || successPay || successDeliver) {
      dispatch({ type: ORDER_PAY_RESET });
      //
      dispatch({ type: ORDER_DELIVER_RESET });
      dispatch(getOrderDetails(orderId));
    } else if (!order.isPaid && order.paymentMethod === 'Paypal') {
      if (!window.paypal) {
        addPaypalScript();
      } else {
        setSdkReady(true);
      }
    }
  }, [
    dispatch,
    order,
    orderId,
    successPay,
    successDeliver,
    userInfo,
    history,
    match,
  ]);

  useEffect(() => {
    console.log('orderZeroPayResult:', orderZeroPayResult);
    if (orderZeroPayResult) {
      if (
        orderZeroPayResult.isPaid === true &&
        orderZeroPayResult.paymentResult.payment_status === 'PAID'
      ) {
        window.location.reload();
      }
    }
  }, [orderZeroPayResult]);

  useEffect(() => {
    if (!order || successPayXen || successCreateInvoiceXen) {
      dispatch({ type: ORDER_PAY_XENDIT_RESET });
    } else if (!order.isPaid && order.paymentMethod === 'Xendit') {
      setSdkXenditReady(true);
    }
    if (invoiceData) {
      setInvoiceUrl(invoiceData.invoice_url);
    }
    if (loadingCreateInvoiceXen) {
      setModalPayLoading(true);
    } else if (!loadingCreateInvoiceXen) {
      setTimeout(() => {
        setModalPayLoading(false);
      }, 3500);
    }
  }, [
    dispatch,
    order,
    orderId,
    successCreateInvoiceXen,
    invoiceData,
    loadingCreateInvoiceXen,
    successPayXen,
  ]);

  const successPaymentHandler = (paymentResult) => {
    console.log('paymentResult:', paymentResult);
    dispatch(payOrder(orderId, paymentResult));
  };

  const successXenditPaymentHandler = (paymentResult) => {
    console.log('paymentResult:', paymentResult);
    dispatch(payOrderXendit(orderId, paymentResult));
  };

  const deliverHandler = () => {
    // console.log('orderId:', orderId);
    dispatch(deliverOrder(orderId));
  };

  const xenditPaymentHandler = async (
    currency,
    totalPrice,
    redirect_url,
    redirect_failed_url,
    payer_email,
    order
  ) => {
    if (totalPrice <= 0) {
      // console.log('Order value is: ', currency, totalPrice);
      // console.log('order._id:', order._id, order);
      dispatch(setZeroPriceOrderPaid(order));
    } else {
      dispatch(
        createXenditInvoice({
          currency,
          totalPrice,
          redirect_url,
          redirect_failed_url,
          payer_email,
          order,
        })
      );
      setModalShow(true);
    }

    /*
    // console.log('paymentResult:', 'paymentResult', currency, amount, onSuccess);
    // dispatch(payOrder(orderId, paymentResult));
    // redirect_url = `${window.location.origin}/try-checkout`
    let invoiceUrl = '';

    if (!invoiceUrl || invoiceUrl === '') {
      // setup invoice data
      // const { currency, amount } = displayedCartDetails.cart.total;
      const invoiceData = {
        currency,
        amount,
        redirect_url,
      };

      // create an invoice for store checkout
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/api/invoice`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
            },
            body: JSON.stringify(invoiceData),
          }
        );

        const data = await response.json();
        if (response.status >= 200 && response.status <= 299) {
          invoiceUrl = data.invoice_url;
          console.log('/api/invoice data response:', data);
        } else {
          alert(data.message);
        }
      } catch (error) {
        alert(error);
      }
    }

    if (invoiceUrl) {
      console.log('invoiceUrl:', invoiceUrl);
      window.location.href = invoiceUrl;
    } */
  };

  return loading ? (
    <Loading />
  ) : error ? (
    <Message variant='danger'>{error}</Message>
  ) : errorPayXen ? (
    <Message variant='danger'>{errorPayXen}</Message>
  ) : errorCreateInvoiceXen ? (
    <Message variant='danger'>{errorCreateInvoiceXen}</Message>
  ) : (
    <>
      <PopupWindow
        className='invoice-modal'
        modalSize='md'
        show={modalShow}
        modalTitle='Pembayaran Online'
        iframe={true}
        // iframeUrl={`https://checkout-staging.xendit.co/web/${invoiceData.id}`}
        iframeUrl={invoiceUrl}
        onHide={() => setModalShow(false)}
        setModalLoading={modalPayLoading}
      />
      <h1>
        Order <span className='text-thin'>#{order._id}</span>
      </h1>
      {loadingZeroPay && <Loading />}
      {errorZeroPay && <Message variant='danger'>{errorZeroPay}</Message>}
      <Row className='place-order-page'>
        <Col md={8}>
          <ListGroup variant='flush'>
            <ListGroup.Item>
              <h2>Invoiced To:</h2>
              <p>
                <strong>User:</strong> {order.user.name}
                <br />
                <strong>Email:</strong>{' '}
                <a href={`mailto:${order.user.email}`}>{order.user.email}</a>
              </p>
              <h2>Melayani Customer:</h2>
              <p>
                <strong>Nama:</strong> {order.shippingAddress.customerName}
                <br />
                <strong>Telepon:</strong> {order.shippingAddress.customerPhone}
              </p>
              <p>
                <strong className='txt-uppercase'>Serving Address:</strong>
                <br />
                {order.shippingAddress.address}, <br />
                {order.shippingAddress.city} {order.shippingAddress.postalCode},
                <br />
                {order.shippingAddress.country}
              </p>
              {order.isFulfilled ? (
                <Message variant='success'>
                  Fulfilled on {order.fulfilledAt}
                </Message>
              ) : (
                <Message variant='danger'>Not Completed Yet</Message>
              )}
            </ListGroup.Item>

            <ListGroup.Item>
              <h2>Payment Method</h2>
              <p>
                <strong>Method:</strong>
                <br />
                {order.paymentMethod}
              </p>
              {order.isPaid ? (
                <Message variant='success'>Paid on {order.paidAt}</Message>
              ) : (
                <Message variant='danger'>Not Paid</Message>
              )}
            </ListGroup.Item>

            <ListGroup.Item>
              <h2>Services Ordered</h2>
              {order.orderItems.length === 0 ? (
                <Message>Order is empty</Message>
              ) : (
                <ListGroup variant='flush'>
                  {order.orderItems.map((item, index) => (
                    <ListGroup.Item key={index}>
                      <Row>
                        <Col md={1}>
                          <Link to={`/products/${item.product}`}>
                            <Image
                              src={item.image}
                              alt={item.name}
                              fluid
                              rounded
                            />
                          </Link>
                        </Col>
                        <Col>
                          <Link
                            className='no-underline'
                            to={`/products/${item.product}`}
                          >
                            {item.name}
                          </Link>
                        </Col>
                        <Col md={2} style={{ textAlign: 'right' }}>
                          {item.qty} x Rp{formatPrice(item.price)}/
                          {item.priceUnit}
                        </Col>
                        <Col md={2} style={{ textAlign: 'right' }}>
                          <strong
                            style={{
                              fontWeight: '400',
                            }}
                          >
                            Rp
                            {formatSeparator(
                              (item.qty * item.price).toFixed(0)
                            )}
                          </strong>
                        </Col>
                      </Row>
                      <Row className='mt-3 pt-2 border-top'>
                        <Col md={8}>
                          <strong>Customer notes:</strong>
                          <br />
                          {item.notes ? item.notes : '<none>'}
                        </Col>
                        <Col md={4}>
                          {item.productSpecialUrlFiles &&
                          item.productSpecialUrlFiles.length > 0 ? (
                            <>
                              Link File(s):
                              <br />
                              <ListItemsArray
                                items={item.productSpecialUrlFiles}
                              />
                            </>
                          ) : (
                            <></>
                          )}
                        </Col>
                      </Row>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              )}
            </ListGroup.Item>
          </ListGroup>
        </Col>
        <Col md={4}>
          <Card style={{ padding: '1rem' }}>
            <ListGroup.Item className='nbtop'>
              <h2 className='my-2 mx2'>Ringkasan Pesanan</h2>
              <p>Metode Pembayaran: ({order.paymentMethod})</p>
            </ListGroup.Item>

            <ListGroup.Item>
              <Row>
                <Col>Items</Col>
                <Col>Rp{formatSeparator(order.itemsPrice)}</Col>
              </Row>
            </ListGroup.Item>
            {/* <ListGroup.Item>
              <Row>
                <Col>Shipping</Col>
                <Col>Rp{order.shippingPrice}</Col>
              </Row>
            </ListGroup.Item> */}
            <ListGroup.Item>
              <Row>
                <Col>Tax</Col>
                <Col>Rp{formatSeparator(order.taxPrice)}</Col>
              </Row>
            </ListGroup.Item>
            <ListGroup.Item>
              <Row>
                <Col>Platform Fee</Col>
                <Col>Rp{formatSeparator(order.platformFee)}</Col>
              </Row>
            </ListGroup.Item>
            <ListGroup.Item className='mb-5'>
              <Row>
                <Col>
                  <strong
                    style={{ fontWeight: '900', textTransform: 'uppercase' }}
                  >
                    Total
                  </strong>
                </Col>
                <Col>
                  <strong style={{ fontWeight: '900' }}>
                    Rp{formatSeparator(order.totalPrice)}
                  </strong>
                </Col>
              </Row>
            </ListGroup.Item>
            {/* <ListGroup.Item>
              <Row>
                <Col></Col>
                <Col>Metode Pembayaran: ({order.paymentMethod})</Col>
              </Row>
            </ListGroup.Item> */}

            {/* {JSON.stringify(order, 4)} */}

            {order.paymentMethod === 'Paypal' && !order.isPaid && (
              <ListGroup.Item>
                {loadingPay && <Loading />}

                {!sdkReady ? (
                  <Loading />
                ) : (
                  <PayPalButton
                    amount={order.totalPrice}
                    onSuccess={successPaymentHandler}
                  />
                )}
              </ListGroup.Item>
            )}
            {(order.paymentMethod === 'Xendit' ||
              order.paymentMethod === 'Free') &&
              !order.isPaid && (
                <ListGroup.Item>
                  {loadingPayXen && <Loading />}
                  {loadingCreateInvoiceXen && <Loading />}
                  {!sdkXenditReady || loadingZeroPay ? (
                    <Loading />
                  ) : (
                    <button
                      className='btn btn-primary pos-relative px-5'
                      onClick={() => {
                        xenditPaymentHandler(
                          'IDR',
                          order.totalPrice,
                          `${window.location.origin}/payment/xendit/success/${orderId}`,
                          `${window.location.origin}/payment/xendit/failed/${orderId}`,
                          userInfo.email,
                          order
                        );
                      }}
                      onSuccess={successXenditPaymentHandler}
                    >
                      {userInfo && parseInt(order.totalPrice) > 0
                        ? 'Bayar Sekarang'
                        : 'Dapatkan Penawaran Gratis Ini'}
                      <i
                        style={{
                          position: 'absolute',
                          top: '10px',
                          right: '1rem',
                        }}
                        className='fa fa-angle-right'
                      ></i>
                    </button>
                  )}
                </ListGroup.Item>
              )}
            {loadingDeliver && <Loading />}
            {userInfo &&
              userInfo.isAdmin &&
              order.isPaid &&
              !order.isFulfilled && (
                <ListGroup.Item>
                  <Button
                    type='button'
                    className='btn btn-block'
                    onClick={deliverHandler}
                  >
                    Mark as Fulfilled
                  </Button>
                </ListGroup.Item>
              )}
          </Card>
          {/* <Button variant='primary' onClick={() => setModalShow(true)}>
            Launch modal
          </Button> */}
        </Col>
      </Row>
    </>
  );
};

export default OrderPage;
