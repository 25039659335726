import React from 'react';
// import { useHistory } from 'react-router-dom';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';

function ShareThis({ match, location, ...props }) {
  const handleShareThis = () => {
    // console.log('Share:', props.title);
    // console.log('match:', match);
    // console.log('location:', location);
  };

  // const history = useHistory();

  const handleShareThisUrl = (str) => {
    const whatShare = str;
    const URL = !location.pathname
      ? `${process.env.REACT_APP_DEPLOY_URL}${location}`
      : `${process.env.REACT_APP_DEPLOY_URL}${location.pathname}`;
    const title = props.title;
    const description = props.description;

    // console.log('URL:', URL);
    // console.log('LOC:', location);

    const shareWa =
      'https://api.whatsapp.com/send?text=' +
      encodeURIComponent('Yuk lihat: ' + title + ' ' + URL);

    const shareFb =
      'https://www.facebook.com/sharer/sharer.php?u=' +
      encodeURIComponent(URL) +
      '&quote=' +
      encodeURIComponent('Yuk lihat: ' + title) +
      '&hashtag=' +
      encodeURIComponent('Selfie Jobs Network');

    const shareTg =
      'https://telegram.me/share/url?url=' +
      encodeURIComponent(URL) +
      '&text=' +
      encodeURIComponent('Yuk lihat: ' + title);

    //

    const shareTw =
      'https://twitter.com/share?text=' +
      encodeURIComponent('Yuk lihat: ' + title) +
      '&url=' +
      encodeURIComponent(URL) +
      '&hashtags=' +
      encodeURIComponent('SelfieJobs,SelfieJobsNetwork');
    //http://twitter.com/share?text=text goes here&url=http://url goes here&hashtags=hashtag1,hashtag2,hashtag3

    const copyCb = () => {
      navigator.clipboard.writeText(URL);

      //   let tooltip = document.getElementById("myTooltip");
      //   tooltip.innerHTML = "Copied: " + copyText.value;
    };

    // function outFunc() {
    //   var tooltip = document.getElementById("myTooltip");
    //   tooltip.innerHTML = "Copy to clipboard";
    // }
    // }

    switch (whatShare) {
      case 'wa':
        window.open(shareWa);
        break;
      case 'tg':
        window.open(shareTg);
        break;
      case 'fb':
        window.open(shareFb);
        break;
      case 'tw':
        window.open(shareTw);
        break;
      case 'cb':
        copyCb();
        break;
      default:
    }

    // https://web.whatsapp.com/send?text=Yuk%20daftar%20lowongan%20Admin%20Online%20di%20SN%20PARFUME%20lewat%20Lumina!%20https%3A%2F%2Flumina.mba%2Fjob-opening%2Fpublic%2Fdetail%2Fab4eabed-c09e-4291-8d30-f82ad75b870a
  };

  const popover = (
    <Popover
      id='popover-basic'
      className='share-this-popover-cont'
      style={props.style}
    >
      <Popover.Header as='h3'>Bagikan</Popover.Header>
      <Popover.Body>
        <div className='flex justify-between'>
          <button
            className='btn-transparent'
            onClick={() => {
              handleShareThisUrl('wa');
              window.ga('send', 'event', 'Interact', 'Share', 'WhatsApp');
            }}
          >
            <svg className='icon-share' viewBox='0 0 32 32'>
              <use xlinkHref='#icon-svg-wa' />
            </svg>
          </button>
          <button
            className='btn-transparent'
            onClick={() => {
              handleShareThisUrl('fb');
              window.ga('send', 'event', 'Interact', 'Share', 'FB');
            }}
          >
            <svg className='icon-share alt2' viewBox='0 0 32 32'>
              <use xlinkHref='#icon-svg-fb' />
            </svg>
          </button>
          <button
            className='btn-transparent'
            onClick={() => {
              handleShareThisUrl('tw');
              window.ga('send', 'event', 'Interact', 'Share', 'Twitter');
            }}
          >
            <svg className='icon-share alt' viewBox='0 0 32 32'>
              <use xlinkHref='#icon-svg-twitter' />
            </svg>
          </button>
          <button
            className='btn-transparent'
            onClick={() => {
              handleShareThisUrl('tg');
              window.ga('send', 'event', 'Interact', 'Share', 'Telegram');
            }}
          >
            <svg className='icon-share alt' viewBox='0 0 32 32'>
              <use xlinkHref='#icon-svg-telegram' />
            </svg>
          </button>
          <button
            className='btn-transparent'
            onClick={() => {
              handleShareThisUrl('cb');
              window.ga('send', 'event', 'Interact', 'Share', 'Link');
            }}
          >
            <svg className='icon-share' fill='#787878' viewBox='0 0 24 24'>
              <use xlinkHref='#icon-svg-link' />
            </svg>
          </button>
        </div>
      </Popover.Body>
    </Popover>
  );

  return (
    <OverlayTrigger trigger='click' placement='bottom' overlay={popover}>
      <button
        className='btn-transparent share-this icon-cont'
        onClick={handleShareThis}
        title='Share This'
        style={props.style}
      >
        <svg fill='#b8b8b8' viewBox='0 0 16 18'>
          <use xlinkHref='#icon-svg-share' />
        </svg>
      </button>
    </OverlayTrigger>
  );
}

export default ShareThis;
