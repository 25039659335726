import React, { useEffect, useState } from 'react';
// import { useHistory } from 'react-router-dom';
import { Form, Row, Col, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import ReactGA from 'react-ga';

import FormContainer from '../components/FormContainer';
// import CheckoutSteps from '../components/CheckoutSteps';
import { savePaymentMethod } from '../actions/cartActions';

const PaymentSelectPage = ({ location, history, match }) => {
  ReactGA.initialize(process.env.REACT_APP_GUA);

  const [paymentMethod, setPaymentMethod] = useState('Xendit');

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  console.log('userInfo:', userLogin);

  const cart = useSelector((state) => state.cart);
  const { shippingAddress } = cart;
  // const history = useHistory();

  if (!userInfo) {
    history.push('/login?redirect=/payment');
  } else if (!shippingAddress) {
    history.push('/buy?redirect=/payment');
  }

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  const dispatch = useDispatch();

  const submitHandler = (e) => {
    e.preventDefault();
    // console.log('event:', e);
    dispatch(savePaymentMethod(paymentMethod));
    history.push('/placeorder');
  };

  return (
    <FormContainer>
      {/* <CheckoutSteps step1 step2 step3 /> */}

      <h1>Payment Method</h1>
      <Form onSubmit={submitHandler}>
        <Form.Group>
          <Form.Label as='legend'>Select Method</Form.Label>

          <Row>
            <Col xs={12}>
              {'Payment Method: ' + paymentMethod}
              <Form.Check
                type='radio'
                label='Xendit (Credit Card, e-Wallet or VA)'
                id='Xendit'
                name='paymentMethod'
                value='Xendit'
                checked
                onChange={(e) => setPaymentMethod(e.target.value)}
              ></Form.Check>
              {/* <Form.Check
                type='radio'
                label='PayPal'
                id='PayPal'
                name='paymentMethod'
                value='PayPal'
                onChange={(e) => setPaymentMethod(e.target.value)}
              ></Form.Check> */}
              {/* <Form.Check
                type='radio'
                label='Stripe or Credit Card'
                id='Stripe'
                name='paymentMethod'
                value='Stripe'
                disabled
                onChange={(e) => setPaymentMethod(e.target.value)}
              ></Form.Check> */}
            </Col>
          </Row>
        </Form.Group>
        <Button type='submit' variant='primary' className='my-3'>
          Continue <i className='fas fa-angle-right px-2'></i>
        </Button>
      </Form>
    </FormContainer>
  );
};

export default PaymentSelectPage;
