import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import ReactDOMServer from 'react-dom/server';

import Rating from './Rating';
import { formatPrice } from '../shared/functions';

const MapsGoogleOnePin = (props) => {
  const [gMapsSdkReady, setGMapsSdkReady] = useState(false);

  const mapRef = useRef();

  const addGMapsScript = async () => {
    const { data: currentKey } = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/api/config/gmaps`
    );

    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = `https://maps.googleapis.com/maps/api/js?key=${currentKey}`;
    // script.async = true;
    script.defer = false;
    script.onload = () => {
      setGMapsSdkReady(true);
    };
    setGMapsSdkReady(true);
    // const scriptNotLoaded = typeof window.google === 'undefined' ? true : false;
    // document.body.appendChild(script);
    setTimeout(function () {
      if (typeof window.google === 'undefined') {
        // document.body.appendChild(script);
      }
    }, 100);
  };

  //   useEffect(() => {
  //     //
  //     dispatch(listTopRatedProducts());
  //   }, [dispatch]);

  useEffect(() => {
    // addGMapsScript();
    setGMapsSdkReady(true);
  }, []);

  if (gMapsSdkReady) {
    const map = new window.google.maps.Map(mapRef.current, {
      center: {
        lat: parseFloat(props.product.latitude),
        lng: parseFloat(props.product.longitude),
      },
      zoom: props.zoom,
      mapTypeId: 'roadmap',
      disableDefaultUI: true,
      panControl: true,
      zoomControl: true,
      scaleControl: true,
      mapTypeControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: true,
    });
    const markerIcon = {
      url: 'https://selfie.id/assets/img/icons/maps/icon-map-pin-lg.png',
      scaledSize: new window.google.maps.Size(30, 40),
      origin: new window.google.maps.Point(0, 0),
      anchor: new window.google.maps.Point(15, 50),
      labelOrigin: new window.google.maps.Point(40, 35),
    };

    const pins = [];
    const htmlContentsArray = [];
    const brand = [];
    let ratingHtml;

    const products = [props.product];

    products.forEach((item) => {
      pins.push({
        position: {
          lat: parseFloat(item.latitude),
          lng: parseFloat(item.longitude),
        }, //new window.google.maps.LatLng(item.latitude, item.longitude),
      });

      brand.push(item.brand);

      ratingHtml = ReactDOMServer.renderToString(
        <Rating
          value={item.rating}
          text={`${parseInt(item.numReviews).toLocaleString()} reviews`}
        />
      );

      htmlContentsArray.push(
        `<img src='${item.image}' alt='${
          item.brand
        }' style='width:60px;height:auto;float:left;display:inline-block;margin: 0 10px 0 0;overflow:hidden;' /><h2 style='font-size:0.8rem;line-height:1;' class='text-no-upcase'>${
          item.brand
        }, ${
          item.name
        }</h2><p>Rp<span style='font-size:1rem;font-weight:600;'>${formatPrice(
          item.price
        )}</span>/${item.priceUnit}</p>
        <p>${ratingHtml}</p>`
      );
    });

    // Create markers
    const marker = [];
    const infowindow = [];

    for (let i = 0; i < pins.length; i++) {
      //
      marker[i] = new window.google.maps.Marker({
        position: pins[i].position,
        map: map,
        icon: markerIcon,
        // animation: window.google.maps.Animation.DROP,
        label: {
          text: brand[i].toUpperCase(),
          color: '#139948',
          fontSize: '14px',
          fontWeight: '300',
        } /*(i + 1).toString() + ') ' +*/,
        draggable: false,
      });
      //   console.log(`pins[${i}].position: ${pins[i].position}`);

      infowindow[i] = new window.google.maps.InfoWindow({
        content: htmlContentsArray[i],
        maxWidth: 200,
        // ariaLabel: 'Judul Label',
      });
      //   console.log(`infowindow[${i}]: ${infowindow[i]}`);

      infowindow[i].close();

      window.google.maps.event.addListener(
        marker[i],
        'click',
        function (event) {
          // marker.addListener('click', () => {
          infowindow.forEach((item) => item.close());
          infowindow[i].open({
            anchor: marker[i],
            map: map,
          });
        }
      );
    }
  }

  return (
    <>
      <div
        ref={mapRef}
        className={`map ${props.className}`}
        style={props.style}
      ></div>
    </>
  );
};

export default MapsGoogleOnePin;
