import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Form, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Message from '../components/Message';
import Loading from '../components/Loading';
import FormContainer from '../components/FormContainer';
import { getUserProfile, updateUser } from '../actions/userActions';
import { USER_UPDATE_RESET } from '../constants/userConstant';

const UserEditPage = ({ match, location }) => {
  const userId = match.params.id;
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [userName, setUserName] = useState('');
  const [image, setImage] = useState('');
  const [products, setProducts] = useState('');
  const [fileUploading, setFileUploading] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [password, setPassword] = useState('');

  const dispatch = useDispatch();

  const userDetails = useSelector((state) => state.userProfile);
  const { loading, error, user } = userDetails;

  const userUpdate = useSelector((state) => state.userUpdate);
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = userUpdate;

  const history = useHistory();
  //   const redirect = location.search ? location.search.split('=')[1] : '/';

  useEffect(() => {
    // if (userInfo) {
    //   history.push(redirect);
    // }
    if (successUpdate) {
      dispatch({ type: USER_UPDATE_RESET });
      // history.push('/admin/users-list');
    } else {
      if (!user.name || user._id !== userId) {
        dispatch(getUserProfile(userId));
      } else {
        setFirstName(user.firstName);
        setLastName(user.lastName);
        setEmail(user.email);
        setUserName(user.userName);
        setImage(user.image);
        setProducts(user.products);
        setIsAdmin(user.isAdmin);
        console.log('user:', user);
      }
    }
  }, [user, userId, dispatch, successUpdate, history /*, userInfo, redirect*/]);

  const uploadFilehandler = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append('image', file);
    setFileUploading(true);

    try {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };

      const { data } = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/upload`,
        formData,
        config
      );

      setImage(`${process.env.REACT_APP_BACKEND_URL}${data}`);
      setFileUploading(false);
    } catch (error) {
      console.log(error);
      setFileUploading(false);
    }
  };

  const submitHandler = (e) => {
    window.scrollTo(0, 0);
    e.preventDefault();

    dispatch(
      updateUser({
        _id: userId,
        firstName,
        lastName,
        email,
        userName,
        products,
        image,
        password,
        isAdmin,
      })
    );
    // if (password !== confirmPassword) {
    //   setMessage('Passwords do not match');
    // } else {
    //   dispatch(registerUser(name, email, password));
    // }
  };

  return (
    <>
      <FormContainer>
        <h1>Edit User</h1>
        {/* {message && <Message variant='danger'>{message}</Message>}
        {error && <Message variant='danger'>{error}</Message>}
        {loading && <Loading />} */}
        {loadingUpdate && <Loading />}
        {errorUpdate && <Message variant='danger'>{errorUpdate}</Message>}
        {loading ? (
          <Loading />
        ) : error ? (
          <Message variant='danger'>{error}</Message>
        ) : (
          <Form onSubmit={submitHandler}>
            <Form.Group controlId='image'>
              {user.image ? (
                <>
                  <img
                    src={user.image}
                    alt={`${firstName} ${lastName}`}
                    style={{ maxWidth: '200px', borderRadius: '50%' }}
                  />
                  <br />
                </>
              ) : (
                ''
              )}
              <Form.Label>Edit Image?</Form.Label>
              <Form.Control
                type='text'
                value={image}
                placeholder='Enter image URL'
                onChange={(e) => setImage(e.target.value)}
              ></Form.Control>
              <Form.Control
                type='file'
                id='image-file'
                label='Choose File'
                custom
                onChange={uploadFilehandler}
              ></Form.Control>
              {fileUploading && <Loading />}
            </Form.Group>
            <Form.Group controlId='firstName'>
              <Form.Label>First Name</Form.Label>
              <Form.Control
                type='text'
                placeholder='First Name'
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <Form.Group controlId='lastName'>
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                type='text'
                placeholder='Last Name'
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <Form.Group controlId='products'>
              <Form.Label>Listed Products</Form.Label>
              <Form.Control
                as='textarea'
                placeholder='products array'
                value={products}
                onChange={(e) => setProducts(e.target.value)}
                disabled={true}
              ></Form.Control>
            </Form.Group>
            <Form.Group controlId='email'>
              <Form.Label>Email Address*</Form.Label>
              <Form.Control
                type='email'
                placeholder='Enter email'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <Form.Group controlId='userName'>
              <Form.Label>User Name*</Form.Label>
              <Form.Control
                type='text'
                placeholder='User Name Unique'
                value={userName}
                onChange={(e) => setUserName(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <Form.Group controlId='password'>
              <Form.Label>Edit User Password?</Form.Label>
              <Form.Control
                type='password'
                placeholder='<Leave blank to keep existing>'
                value={password}
                autoComplete='new-password'
                onChange={(e) => setPassword(e.target.value)}
              ></Form.Control>
            </Form.Group>
            {/* <Form.Group controlId='confirmPassword'>
              <Form.Label>Confirm Password</Form.Label>
              <Form.Control
                type='password'
                placeholder='Enter Confirm Password'
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              ></Form.Control>
            </Form.Group> */}
            <Form.Group controlId='isAdmin'>
              <Form.Check
                type='checkbox'
                label='Set as Admin'
                checked={isAdmin}
                onChange={(e) => setIsAdmin(e.target.checked)}
              ></Form.Check>
            </Form.Group>
            <Button type='submit' variant='primary' className='my-3'>
              Update User
            </Button>
          </Form>
        )}
      </FormContainer>
      <Link to='/admin/users-list' className='btn btn-light my-3'>
        Back
      </Link>
    </>
  );
};

export default UserEditPage;
