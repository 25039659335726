import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Button } from 'react-bootstrap';
import ReactGA from 'react-ga';

import Meta from '../components/Meta';
import Message from '../components/Message';
import Loading from '../components/Loading';
import Paginate from '../components/Paginate';
import SearchBox from '../components/SearchBox';
import ProductsTopRatedCarousel from '../components/ProductsTopRatedCarousel';
import Product from '../components/Product';
// import ProductsTopRatedCarousel from '../components/ProductsTopRatedCarousel';
// import products from '../products';
import { listProducts } from '../actions/productActions.js';
import HowItWorks from '../components/HowItWorks';
import ShareThis from '../components/ShareThis';
import AddNewProductButton from '../components/AddNewProductButton';
import ProductsFeaturedCarousel from '../components/ProductsFeaturedCarousel';

const HomePage = ({ match, location, history, ...props }) => {
  ReactGA.initialize(process.env.REACT_APP_GUA);
  const [user, setUser] = useState('');

  const keyword = match.params.keyword;
  const pageNumber = match.params.pageNumber || 1;

  // const [products, setProducts] = useState([]);
  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  console.log('userInfo:', userInfo);

  const productsList = useSelector((state) => state.productsList);
  const { loading, error, products, page, pages } = productsList;

  // const sellerDetails = useSelector((state) => state.userProfile);
  // const {
  //   loading: loadingSeller,
  //   error: errorSeller,
  //   user: seller,
  // } = sellerDetails;

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
    dispatch(listProducts(keyword, pageNumber));
    /*
    const fetchProducts = async () => {
      const res = await axios.get('${process.env.REACT_APP_BACKEND_URL}/api/products');

      setProducts(res.data);
    };
    fetchProducts();*/
  }, [dispatch, keyword, pageNumber]);

  /*
  useEffect(() => {
    if (
      userInfo &&
      user &&
      !userInfo.isAdmin &&
      user !== userInfo._id.toString()
    ) {
      // console.log('1) userInfo._id.toString():', user, userInfo._id.toString());
      history.push('/');
    } else if (!userInfo && !user) {
      history.push('/');
    }
  }, [user, userInfo, history]);
*/
  // useEffect(() => {
  //   if (product.user) dispatch(getUserProfile(product.user));
  // }, [dispatch, product]);

  return (
    <div className='home-page'>
      <Meta
        title={`Pekerja Lepas Yang Siap Membantu Anda - ${process.env.REACT_APP_SITENAME}`}
        description='Cari Pekerja Lepas yang Siap Membantu Memenuhi Kebutuhan Anda'
      />
      {/* {!keyword && <ProductsTopRatedCarousel />} */}
      <Container fluid>
        <Row>
          <Col xs='12'>
            <SearchBox />
          </Col>
        </Row>
      </Container>
      {/* {window.location.pathname === '/' && <ProductsTopRatedCarousel />} */}
      {loading ? (
        <Loading />
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : !keyword ? (
        window.location.pathname === '/' && (
          <>
            <ProductsTopRatedCarousel />
            <Container fluid>
              <Row fluid>
                <Col xs='12'>
                  <div className='center-horizontal mt-1'>
                    <AddNewProductButton userInfo={userInfo} />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs='12' sm='6' className='order-2 order-sm-1'>
                  {['bottom'].map((placement, idx) => (
                    <HowItWorks
                      key={idx}
                      placement={placement}
                      name={placement}
                    />
                  ))}
                </Col>
                <Col xs='12' sm='6' className='order-1 order-sm-2'>
                  <div className='center-horizontal featured mt-4 mb-3'>
                    <h2
                      className='text-center'
                      style={{
                        cursor: 'pointer',
                        color: 'inherit',
                        fontWeight: '300',
                      }}
                    >
                      Featured Products
                    </h2>
                  </div>
                  <div className='center-horizontal'>
                    <ProductsFeaturedCarousel />
                  </div>
                </Col>
              </Row>
            </Container>
          </>
        )
      ) : (
        <>
          <h1 className='product__title-page'>
            {keyword
              ? `Hasil pencarian: "${keyword}"`
              : 'Pekerja Lepas Yang Siap Membantu Anda'}
          </h1>
          <Row className='home'>
            {products.map((product) => (
              <Col
                sm={12}
                md={6}
                lg={4}
                xl={3}
                key={product._id}
                className={product.isActive ? '' : 'd-none'}
              >
                <Product product={product} />
              </Col>
            ))}
          </Row>
          {keyword && (
            <Link to='/' className='btn btn-txt'>
              <i className='fa fa-angle-left'></i> Go Back
            </Link>
          )}
          <Paginate
            page={page}
            pages={pages}
            keyword={keyword ? keyword : ''}
          />
        </>
      )}
    </div>
  );
};

export default HomePage;
