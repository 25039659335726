import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Button, Row, Col, ListGroup, Image, Card } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import ReactGA from 'react-ga';

import Message from '../components/Message';
import { formatPrice, formatSeparator, addDecimals } from '../shared/functions';
import CheckoutSteps from '../components/CheckoutSteps';
import { createOrder } from '../actions/orderActions';

const PlaceOrderPage = ({ history }) => {
  ReactGA.initialize(process.env.REACT_APP_GUA);
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart);

  // const history = useHistory();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  console.log('userInfo:', userLogin);
  if (!userInfo) {
    history.push('/login?redirect=/placeorder');
  }

  if (!cart.shippingAddress) {
    // history.push('/shipping');
    history.push('/buy');
  } else if (!cart.paymentMethod) {
    // history.push('/payment');
    history.push('/buy');
  }

  cart.itemsPrice = addDecimals(
    cart.cartItems.reduce((acc, item) => acc + item.price * item.qty, 0)
  );

  // Shipping price always 0, no shipping
  cart.shippingPrice = addDecimals(cart.itemsPrice > 100 ? 0 : 0);
  // cart.shippingPrice = addDecimals(cart.itemsPrice > 100 ? 0 : 100);
  cart.platformFee = addDecimals(Number((0.05 * cart.itemsPrice).toFixed(2)));
  cart.taxPrice = addDecimals(Number((0.05 * cart.itemsPrice).toFixed(2)));
  cart.totalPrice = (
    Number(cart.itemsPrice) +
    Number(cart.shippingPrice) +
    Number(cart.platformFee) +
    Number(cart.taxPrice)
  ).toFixed(0);

  const orderCreate = useSelector((state) => state.orderCreate);
  const { order, success, error } = orderCreate;

  // console.log('order', order);
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
    // console.log('cart:', cart);
  }, []);

  useEffect(() => {
    if (success) {
      history.push(`/order/${order.createdOrder._id}`);
    }
    // eslint-disable-next-line
  }, [history, success]);

  const placeOrderHandler = () => {
    dispatch(
      createOrder({
        orderItems: cart.cartItems,
        shippingAddress: cart.shippingAddress,
        paymentMethod: cart.paymentMethod,
        itemsPrice: cart.itemsPrice,
        shippingPrice: cart.shippingPrice,
        taxPrice: cart.taxPrice,
        platformFee: cart.platformFee,
        totalPrice: cart.totalPrice,
      })
    );
  };

  return (
    <>
      {/* <CheckoutSteps step1 step2 step3 step4 /> */}
      <h1>Pesanan Anda</h1>
      <Row className='place-order-page'>
        <Col md={8}>
          <ListGroup variant='flush'>
            <ListGroup.Item>
              <h2>Melayani Customer:</h2>
              <p>
                <strong>Nama:</strong> {cart.shippingAddress.customerName}
                <br />
                <strong>Telepon:</strong> {cart.shippingAddress.customerPhone}
              </p>
              <p>
                <strong>Alamat:</strong>
                <br />
                {cart.shippingAddress.address}, <br />
                {cart.shippingAddress.city} {cart.shippingAddress.postalCode},
                <br />
                {cart.shippingAddress.country}
              </p>
            </ListGroup.Item>
            <ListGroup.Item>
              <h2>Metode Pembayaran</h2>
              <p>
                <strong>Method:</strong>
                <br />
                {cart.paymentMethod}
              </p>
            </ListGroup.Item>
            <ListGroup.Item>
              <h2>Layanan Yang Dipesan</h2>
              {cart.cartItems.length === 0 ? (
                <Message>Keranjang Anda kosong</Message>
              ) : (
                <ListGroup variant='flush'>
                  {cart.cartItems.map((item, index) => (
                    <ListGroup.Item key={index}>
                      <Row>
                        <Col md={1}>
                          <Link to={`/products/${item.product}`}>
                            <Image
                              src={item.image}
                              alt={item.name}
                              fluid
                              rounded
                            />
                          </Link>
                        </Col>
                        <Col md={3}>
                          <Link
                            className='no-underline'
                            to={`/products/${item.product}`}
                          >
                            <strong
                              style={{
                                fontSize: '1rem',
                                fontWeight: 'bold',
                                textTransform: 'uppercase',
                              }}
                            >
                              {item.name}
                            </strong>
                          </Link>
                          <br />
                          Notes Item Ini:
                          <br />
                          {item.notes}
                        </Col>
                        <Col md={3} style={{ textAlign: 'right' }}>
                          {item.qty} x Rp{formatPrice(item.price)}/
                          {item.priceUnit}
                        </Col>
                        <Col md={3}>
                          Dipesan untuk kapan:
                          <br />
                          {item.toBeServedDate} @ {item.toBeServedTime}
                        </Col>
                        <Col md={2} style={{ textAlign: 'right' }}>
                          <strong
                            style={{
                              fontWeight: '600',
                            }}
                          >
                            Rp{formatPrice(item.qty * item.price, true)}
                          </strong>
                        </Col>
                      </Row>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              )}
            </ListGroup.Item>
          </ListGroup>
        </Col>
        <Col md={4}>
          <Card style={{ padding: '1rem' }}>
            <ListGroup.Item className='nbtop'>
              <h2 className='my-2 mx2'>Ringkasan Pesanan</h2>
            </ListGroup.Item>
            <ListGroup.Item>
              <Row>
                <Col>Items</Col>
                {/* <Col>Rp{formatPrice(cart.itemsPrice, true)}</Col> */}
                <Col>Rp{formatSeparator(cart.itemsPrice)}</Col>
              </Row>
            </ListGroup.Item>
            {/* <ListGroup.Item>
              <Row>
                <Col>Shipping</Col>
                <Col>${cart.shippingPrice}</Col>
              </Row>
            </ListGroup.Item> */}
            <ListGroup.Item>
              <Row>
                <Col>Platform Fee</Col>
                <Col>Rp{formatSeparator(cart.platformFee)}</Col>
              </Row>
              <Row>
                <Col style={{ fontStyle: 'italic', lineHeight: '1' }}>
                  <span>
                    Membantu menutupi biaya operasional dan terus meningkatkan
                    layanan platform R & D
                  </span>
                </Col>
                {/* <Col>This helps us operate & improve the platform</Col> */}
              </Row>
            </ListGroup.Item>
            <ListGroup.Item>
              <Row>
                <Col>Tax</Col>
                <Col>Rp{formatSeparator(cart.taxPrice)}</Col>
              </Row>
            </ListGroup.Item>
            <ListGroup.Item>
              <Row>
                <Col>
                  <strong
                    style={{ fontWeight: '900', textTransform: 'uppercase' }}
                  >
                    Total
                  </strong>
                </Col>
                <Col>
                  <strong style={{ fontWeight: '900' }}>
                    Rp{formatSeparator(cart.totalPrice)}
                  </strong>
                </Col>
              </Row>
            </ListGroup.Item>

            {error && (
              <Message variant='danger'>
                <ListGroup.Item>{error}</ListGroup.Item>
              </Message>
            )}

            <ListGroup.Item className='nbbottom mt-3'>
              <Row>
                <Col>
                  <Button
                    type='button'
                    className='btn-primary btn-block'
                    style={{ width: '100%', letterSpacing: '3px' }}
                    disabled={cart.cartItems === 0}
                    onClick={placeOrderHandler}
                  >
                    Konfirmasi Pesanan
                  </Button>
                </Col>
              </Row>
            </ListGroup.Item>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default PlaceOrderPage;
